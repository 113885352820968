import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import React, { useContext, useRef, useState } from "react";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import { depotURL } from "../../../static/constants/backendRoutes";
import UseAuth from "../../auth/useAuth";
import ClimateDataEditor from "./depotClimateDataEditor";
import ClimateGraph from "./depotClimateGraph";

export default function ClimateDetails({
  chartData,
  setChartData,
  setChartDialog,
  chartDialog,
  depotData,
  setDepotData,
}) {
  const [chartUpdating, setChartUpdating] = useState(false);
  const [closingDialogShow, setClosingDialogShow] = useState(false);
  const { snackBarElement } = useContext(SnackBarContext);
  const chartModified = useRef(false);

  /**
   *
   * @param {SubmitEvent} event
   */
  function handleUpdateChartData(event) {
    event.preventDefault();
    if (!chartModified.current) {
      snackBarElement.current.displayToast(
        "No temperatures changed",
        "warning",
        5000
      );
      return;
    }

    const {
      climate: { monthly_temperature },
    } = chartData;

    const maxElement = Math.max(
      ...monthly_temperature.map((temp) => temp.maximum_temperature)
    );
    const minElement = Math.min(
      ...monthly_temperature.map((temp) => temp.minimum_temperature)
    );

    const depot_body = {
      id: chartData.id,
      climate: {
        annual_temperature: {
          maximum_temperature: Math.round(maxElement),
          minimum_temperature: Math.round(minElement),
        },
        monthly_temperature,
      },
    };

    setChartUpdating(true);
    fetch(depotURL, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${UseAuth("get")}`,
      },
      body: JSON.stringify(depot_body),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            let depotDataArr = [...depotData];
            let index = depotDataArr.findIndex((el) => el.id === chartData.id);
            depotDataArr[index] = data.data;
            setDepotData(depotDataArr);
            setChartData({ ...data.data, unit: chartData.unit });
            setChartUpdating(false);
            snackBarElement.current.displayToast("Climate Data Updated");
            chartModified.current = false;
            setChartDialog(false);
          });
        } else {
          setChartUpdating(false);
          errorHandler(response, snackBarElement);
        }
      })
      .catch((err) => {
        setChartUpdating(false);
        snackBarElement.current.displayToast(
          "Something is not right, try again",
          "error"
        );
        console.log(err);
      });
  }

  function handleCloseDialog() {
    if (chartModified.current) setClosingDialogShow(true);
    else setChartDialog(false);
  }

  return (
    <Dialog
      maxWidth="xl"
      sx={{
        height: "790px",
        "& .MuiDialog-paper": {
          marginTop: "-5px",
        },
      }}
      open={chartDialog}
      component="form"
      onSubmit={handleUpdateChartData}
      onClose={handleCloseDialog}
    >
      <Dialog open={closingDialogShow}>
        <DialogContent>
          You have unsaved changes. Are you sure you want to close?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setClosingDialogShow(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setClosingDialogShow(false);
              setChartDialog(false);
              chartModified.current = false;
            }}
          >
            Yes, close
          </Button>
        </DialogActions>
      </Dialog>
      <AppBar color="inherit" sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            Historical Climate Data
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ pb: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <ClimateGraph chartData={chartData} chartUpdating={chartUpdating} />
          </Grid>
          <Grid item xs={3}>
            <ClimateDataEditor
              chartData={chartData}
              setChartData={setChartData}
              chartModified={chartModified}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogContentText pr={"720px"}>
          <a href="https://doi.org/10.3334/ORNLDAAC/416 ">Climate Data</a> is
          provided by ORNL DAAC. 30-Yr historical{" "}
        </DialogContentText>
        <Button onClick={handleCloseDialog}>Close</Button>
        <Button type="submit">Save updates</Button>
      </DialogActions>
    </Dialog>
  );
}
