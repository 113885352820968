import Battery0BarIcon from "@mui/icons-material/Battery0Bar";
import Battery20Icon from "@mui/icons-material/Battery20";
import Battery30Icon from "@mui/icons-material/Battery30";
import Battery50Icon from "@mui/icons-material/Battery50";
import Battery60Icon from "@mui/icons-material/Battery60";
import Battery80Icon from "@mui/icons-material/Battery80";
import Battery90Icon from "@mui/icons-material/Battery90";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";

const iconStyle = {
  color: "blue",
  rotate: "90deg",
  fontSize: "100px",
};

const lowBatteryStyle = {
  color: "red",
  rotate: "90deg",
  fontSize: "100px",
};

const BatteryIcon = ({ percentage }) => {
  const getBatteryIcon = (percentage) => {
    if (percentage >= 90) return <BatteryFullIcon style={iconStyle} />;
    if (percentage >= 80) return <Battery90Icon style={iconStyle} />;
    if (percentage >= 60) return <Battery80Icon style={iconStyle} />;
    if (percentage >= 50) return <Battery60Icon style={iconStyle} />;
    if (percentage >= 30) return <Battery50Icon style={iconStyle} />;
    if (percentage >= 20) return <Battery30Icon style={iconStyle} />;
    if (percentage >= 10) return <Battery20Icon style={lowBatteryStyle} />;
    return <Battery0BarIcon style={lowBatteryStyle} />;
  };

  // Ensure the percentage is within 0-100%
  const validPercentage = Math.max(0, Math.min(percentage, 100));

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {getBatteryIcon(validPercentage)}
      <span style={{ marginLeft: "8px", fontSize: "1em", color: "#333" }}>
        {validPercentage}%
      </span>
    </div>
  );
};

export default BatteryIcon;
