import { Container, Paper, Typography } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import { unitSmallMap } from "../../../static/constants/systems_of_measurement";
import { getUnits, unitFeet } from "../../secondary/unitConversions";
import { getLocalData, unitWrapper } from "../../utils";

function Chargers() {
  const [isDataFetched, setIsDataFetched] = useState(false); //note: this page doesn't really need the datafetch error, d
  /** @type {[{infeasible: Array, feasible: {combos: Array}} || {infeasible_blocks: {model_breakdown: Array}, feasible_blocks: Array}]} */
  const [fleetData, setFleetData] = useState({
    infeasible: [],
    feasible: { combos: [] },
    infeasible_blocks: { model_breakdown: [] },
    feasible_blocks: [],
  }); //for the pages materialTable contents
  const [rowSelection, setRowSelection] = useState({ 0: true });
  const selectedRow = Object.keys(rowSelection)[0];

  const units = getUnits();

  /**
   * retrieves tableData from the frontend's indexdB
   */
  useEffect(() => {
    async function fetchData() {
      const indexedFleet = await getLocalData("fleetSizing");
      const selectedRow = indexedFleet?.input?.row;
      const localFleet = indexedFleet?.data;

      if (selectedRow) setRowSelection({ [selectedRow]: true }); //if this page has been previously visited, then re-use the previously selected row
      //if localFleet was stored on frontend, display it
      if (localFleet) setFleetData(localFleet);

      setIsDataFetched(true);
    }

    fetchData();
  }, []);

  //parse out the separate data tables
  const bevData =
    fleetData?.feasible?.combos || fleetData?.feasible_blocks || [];
  const selectedBevData = useMemo(
    () => (bevData.length ? [bevData[selectedRow]] : []),
    [bevData, selectedRow]
  );
  const iceData =
    fleetData?.infeasible || //analysis vers < 1.2.0
    fleetData?.infeasible_blocks?.model_breakdown || //analysis vers > 1.3.0
    [];

  const bevColumns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */ () => [
      {
        header: "Scenario",
        id: "scenario",
        Cell: ({ row }) => `Scenario ${+row.id + 1}`,
      },
      {
        header: "Rating",
        accessorKey: "charger_model.rating",
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()} {unitWrapper("kW")}
          </>
        ),
        sorting: false,
      },
      {
        header: "Charger Count",
        Header: (
          <>
            Charger
            <br />
            Count
          </>
        ),
        accessorKey: "num_chargers",
        sorting: false,
      },
      {
        header: "Vehicle Type",
        id: "veh_type",
        Cell: ({ row }) =>
          (
            row.original.fleet?.breakdown || //analysis vers <= 1.2.0
            row.original.fleet?.model_breakdown || // analysis vers > 1.2.0
            []
          )?.map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-type`}>
              {TYPE_STRINGS.VEHICLE_TYPE[sub_row.veh_type]}
            </div>
          )),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
      },
      {
        header: "Vehicle Size",
        id: "size",
        Cell: ({ row }) =>
          (
            row.original.fleet?.breakdown || //analysis vers <= 1.2.0
            row.original.fleet?.model_breakdown || // analysis vers > 1.2.0
            []
          ).map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-size`}>
              {sub_row.veh_type == 1 ? (
                <>
                  {unitFeet(sub_row.size)} {unitWrapper(unitSmallMap[units])}
                </>
              ) : sub_row.veh_type == 4 ? (
                `Type ${sub_row.size}`
              ) : (
                `Class ${sub_row.size}`
              )}
            </div>
          )),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
      },
      {
        header: "Battery Capacity",
        Header: (
          <>
            Battery
            <br />
            Capacity
          </>
        ),
        id: "battery_capacity",
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        Cell: ({ row }) =>
          (
            row.original.fleet?.breakdown || //analysis vers <= 1.2.0
            row.original.fleet?.model_breakdown || // analysis vers > 1.2.0
            []
          ).map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-batteryCapacity`}>
              {sub_row.battery_capacity} {unitWrapper("kWh")}
            </div>
          )),
        sorting: false,
      },
      {
        header: "Total Blocks",
        Header: (
          <>
            Total
            <br />
            Blocks
          </>
        ),
        id: "block_count",
        Cell: ({ row }) =>
          (
            row.original.fleet?.breakdown || //analysis vers <= 1.2.0
            row.original.fleet?.model_breakdown || // analysis vers > 1.2.0
            []
          )?.map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-total_blocks`}>
              {sub_row.num_blocks}
            </div>
          )),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
      },
      {
        header: "Feasible Blocks",
        Header: (
          <>
            Feasible
            <br />
            Blocks
          </>
        ),
        id: "num_blocks",
        Cell: ({ row }) =>
          (
            row.original.fleet?.breakdown || //analysis vers <= 1.2.0
            row.original.fleet?.model_breakdown || // analysis vers > 1.2.0
            []
          )?.map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-feasible_blocks`}>
              {sub_row.num_feasible_blocks}
            </div>
          )),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
      },
      {
        header: "ICE Needed",
        Header: (
          <>
            ICE
            <br />
            Needed
          </>
        ),
        id: "ice_fleet_size",
        Cell: ({ row }) =>
          (
            row.original.fleet?.breakdown || //analysis vers <= 1.2.0
            row.original.fleet?.model_breakdown || // analysis vers > 1.2.0
            []
          )?.map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-ice_fleet_size`}>
              {sub_row.ice_fleet_size}
            </div>
          )),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
      },
      {
        header: "BEV Needed",
        Header: (
          <>
            Equivalent
            <br />
            BEV
          </>
        ),
        id: "ev_fleet_size",
        Cell: ({ row }) =>
          (
            row.original.fleet?.breakdown || //analysis vers <= 1.2.0
            row.original.fleet?.model_breakdown || // analysis vers > 1.2.0
            []
          )?.map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-ev_fleet_size`}>
              {sub_row.ev_fleet_size}
            </div>
          )),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
      },
      {
        header: "Replacement Ratio",
        Header: (
          <>
            Replacement
            <br />
            Ratio
          </>
        ),
        id: "replacement_ratio",
        Cell: ({ row }) =>
          (
            row.original.fleet?.breakdown || //analysis vers <= 1.2.0
            row.original.fleet?.model_breakdown || // analysis vers > 1.2.0
            []
          )?.map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-replacement_ratio`}>
              {sub_row.replacement_ratio}
            </div>
          )),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
      },
    ],
    []
  );

  const iceColumns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */ () => [
      {
        header: "Vehicle Type",
        accessorKey: "veh_type",
        Cell: ({ cell }) => TYPE_STRINGS.VEHICLE_TYPE[cell.getValue()],
      },
      {
        header: "Vehicle Size",
        id: "vehicle_size",
        accessorFn: (row) => row.size || row.vehicle_size || 0,
        Cell: ({ row, cell }) => {
          const veh_type = row.getValue("veh_type");
          return veh_type == 1 ? (
            <>
              {unitFeet(cell.getValue())} {unitWrapper(unitSmallMap[units])}
            </>
          ) : veh_type == 4 ? (
            `Type ${cell.getValue()}`
          ) : (
            `Class ${cell.getValue()}`
          );
        },
      },
      { header: "Total Blocks", accessorKey: "num_blocks" },
      { header: "Infeasible Blocks", accessorKey: "num_infeasible_blocks" },
      {
        header: "ICE Vehicles",
        id: "num_ice_vehicles",
        accessorFn: (row) => row.num_ice_vehicles || row.ice_fleet_size || 0,
      },
    ],
    []
  );

  /** @returns {import("material-react-table").MRT_TableOptions<never} */
  const sharedTableProps = () => ({
    ...materialReactTableOptions(),
    state: { ...materialReactTableOptions().state, isLoading: !isDataFetched },
    enableToolbarInternalActions: false, //hides the toolbar actions without hiding the custom toolbar chips
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableFilters: false,
    enableSorting: false,
    enablePagination: false, //disabling pagination seems to remove the limit on number of rows displayed at once (tested for up to 14 rows)
  });

  const BevTable = useMaterialReactTable({
    ...sharedTableProps(),
    data: selectedBevData,
    columns: bevColumns,
    state: {
      ...sharedTableProps().state,
      columnVisibility: { scenario: false },
    },
  });

  const IceTable = useMaterialReactTable({
    ...sharedTableProps(),
    data: iceData,
    columns: iceColumns,
    //miscellaneous options
    renderEmptyRowsFallback: () => (
      <Typography textAlign="center">No scenarios that require ICE</Typography>
    ),
  });

  return (
    <Container fixed maxWidth="xl">
      <Paper
        sx={{ width: "100%", overflow: "hidden", marginBottom: 2 }}
        elevation={3}
      >
        <Typography variant="h6" sx={{ textAlign: "left", marginLeft: "5px" }}>
          Electric Vehicles (BEV)
        </Typography>
        <MaterialReactTable table={BevTable} />
      </Paper>
      {iceData.length > 0 && (
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <Typography
            variant="h6"
            sx={{ textAlign: "left", marginLeft: "5px" }}
          >
            Conventional Vehicles (ICE)
          </Typography>
          <MaterialReactTable table={IceTable} />
        </Paper>
      )}
    </Container>
  );
}

export default Chargers;
