import MaterialTable from "@material-table/core";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import { currencyDisplay } from "../../secondary/unitConversions";
import { Icons } from "../../utils";

export const TogglePerVehicleComponent = ({ isPerVehicle }) => (
  <>
    {isPerVehicle ? (
      <Typography className="no-print" sx={{ fontSize: "h6.fontSize" }}>
        All Vehicles
      </Typography>
    ) : (
      <Typography sx={{ fontSize: "h6.fontSize", fontWeight: "bold" }}>
        All Vehicles
      </Typography>
    )}

    <Switch className="no-print" checked={isPerVehicle} color={"primary"} />

    {isPerVehicle ? (
      <Typography sx={{ fontSize: "h6.fontSize", fontWeight: "bold" }}>
        Per Vehicle
      </Typography>
    ) : (
      <Typography className="no-print" sx={{ fontSize: "h6.fontSize" }}>
        Per Vehicle
      </Typography>
    )}
  </>
);

/**
 *
 * @param {Object} props
 * @param {Object} props.tcoData
 * @param {Object} props.fleetSizes For the managed/unmanaged transformer Capacity
 * @param {String} props.className additional classes to attach to the Paper compoennt
 * @param {"ICE"|"GAS"|"PROP"|"CNG"|undefined} props.selectedFuel state shared with tcoGraph Component
 * @param {Boolean} props.isPerVehicle state shared with tcoGraph Component
 * @param {import("react").Dispatch<import("react").SetStateAction<Boolean>>} props.setIsPerVehicle
 * @param {1|2|3} props.tableView the different financial view's indices (1 for Summary, 2 for CAPEX, 3 for OPEX)
 */
export default function TCOTable({
  tcoData: tableData,
  fleetSizes,
  className,
  isPerVehicle,
  setIsPerVehicle,
  selectedFuel,
  tableView,
}) {
  const denominatorBEV = isPerVehicle ? fleetSizes.total_ev_fleet_size : 1;
  const denominatorICE = isPerVehicle ? fleetSizes.total_ice_fleet_size : 1;
  const tcoLife = tableData.BEV.tco_list.length - 1;

  const fuel_types = Object.keys(tableData).filter(
    (i) => i in TYPE_STRINGS.FUEL_TYPE
  );

  const columns = [
    { title: "Category", field: "category" },
    { title: "Subcategory", field: "subCategory" },
    { title: `Electric Vehicle${isPerVehicle ? "" : "s"}`, field: "ev" },
    {
      title: `${TYPE_STRINGS.FUEL_TYPE[selectedFuel]} Vehicle${
        isPerVehicle ? "" : "s"
      }`,
      field: selectedFuel,
    },
  ];

  const spanWrapper = (children) => {
    return <span style={{ fontWeight: 600 }}> {children}</span>;
  };

  const spanWrapperBig = (children) => {
    return (
      <span style={{ fontSize: "18px", fontWeight: 600 }}> {children}</span>
    );
  };

  const fuelDataHelper = (displayFunction) =>
    fuel_types.reduce(
      (obj, fuel) => ({ ...obj, [fuel]: displayFunction(fuel) }),
      {}
    );

  const data = (denominatorBEV, denominatorICE) => {
    let rowData = [];

    if (tableView == 1) {
      rowData.push({
        category: (
          <>
            Cost of Ownership
            <br />
            Over {tcoLife} Year Life
          </>
        ),
        rowSpan: 1,
        subcategory: [
          {
            name: spanWrapperBig("Total Cost of Ownership"),
            ev: spanWrapperBig(
              !isNaN(tableData.BEV.totalTCO)
                ? currencyDisplay(tableData.BEV.totalTCO / denominatorBEV)
                : "N/A"
            ),
            ...fuelDataHelper((fuel) =>
              spanWrapperBig(
                !isNaN(tableData[fuel]?.totalTCO)
                  ? currencyDisplay(tableData[fuel]?.totalTCO / denominatorICE)
                  : "N/A"
              )
            ),
          },
          {
            name: "Net Present Value",
            ev: !isNaN(tableData.BEV.npv)
              ? currencyDisplay(tableData.BEV.npv / denominatorBEV)
              : "N/A",
            ...fuelDataHelper((fuel) =>
              !isNaN(tableData[fuel]?.npv)
                ? currencyDisplay(tableData[fuel]?.npv / denominatorICE)
                : "N/A"
            ),
          },
        ],
      });
    } else if (tableView == 2) {
      rowData.push({
        category: (
          <>
            Capital Expenses
            <br />
            (CAPEX)
          </>
        ),
        rowSpan: 4,
        subcategory: [
          {
            name: spanWrapperBig(
              <>
                Initial Investment
                <br />
                (with Incentives)
              </>
            ),
            ev: spanWrapperBig(
              !isNaN(tableData.BEV.initial_capital)
                ? currencyDisplay(
                    tableData.BEV.initial_capital / denominatorBEV
                  )
                : "-"
            ),
            ...fuelDataHelper((fuel) =>
              spanWrapperBig(
                !isNaN(tableData[fuel]?.initial_capital)
                  ? currencyDisplay(
                      tableData[fuel]?.initial_capital / denominatorICE
                    )
                  : "-"
              )
            ),
          },
          {
            name: (
              <>
                Initial Investment
                <br />
                (without Incentive)
              </>
            ),
            ev: !isNaN(tableData.BEV.initial_capital_minus_incentives)
              ? currencyDisplay(
                  tableData.BEV.initial_capital_minus_incentives /
                    denominatorBEV
                )
              : "-",
            ...fuelDataHelper((fuel) =>
              !isNaN(tableData[fuel]?.initial_capital)
                ? currencyDisplay(
                    tableData[fuel]?.initial_capital / denominatorICE
                  )
                : "-"
            ),
          },
          {
            name: spanWrapper("Total Incentive"),
            ev: spanWrapper(
              !isNaN(tableData.BEV.incentives)
                ? `(${currencyDisplay(
                    Math.abs(tableData.BEV.incentives / denominatorBEV)
                  )})`
                : "-"
            ),
            ...fuelDataHelper(() => spanWrapper("-")),
          },
          {
            name: "Vehicle Incentive",
            ev: !isNaN(tableData.BEV.vehicle_incentives)
              ? `(${currencyDisplay(
                  Math.abs(tableData.BEV.vehicle_incentives / denominatorBEV)
                )})`
              : "-",
            ...fuelDataHelper(() => "-"),
          },
          {
            name: "Charger Incentive",
            ev: !isNaN(tableData.BEV.charger_incentives)
              ? `(${currencyDisplay(
                  Math.abs(tableData.BEV.charger_incentives / denominatorBEV)
                )})`
              : "-",
            ...fuelDataHelper(() => "-"),
          },
          {
            name: spanWrapper("Infrastructure Upgrade Cost"),
            ev: spanWrapper(
              !isNaN(tableData.BEV.infrastructure_cost)
                ? currencyDisplay(
                    tableData.BEV.infrastructure_cost / denominatorBEV
                  )
                : "N/A"
            ),
            ...fuelDataHelper(() => spanWrapper("-")),
          },
          {
            name: spanWrapper("Battery Replacement Cost"),
            ev: spanWrapper(
              !isNaN(tableData.BEV.battery_cost)
                ? currencyDisplay(tableData.BEV.battery_cost / denominatorBEV)
                : "N/A"
            ),
            ...fuelDataHelper(() => spanWrapper("-")),
          },
        ],
      });
    } else if (tableView == 3) {
      rowData.push({
        category: (
          <>
            Operating Expenses
            <br />
            (OPEX)
          </>
        ),
        rowSpan: 6,
        subcategory: [
          {
            name: spanWrapperBig("Total Operating Expenses"),
            ev: spanWrapperBig(
              !isNaN(tableData.BEV.total_opex)
                ? currencyDisplay(tableData.BEV.total_opex / denominatorBEV)
                : "N/A"
            ),
            ...fuelDataHelper((fuel) =>
              spanWrapperBig(
                !isNaN(tableData[fuel]?.total_opex)
                  ? currencyDisplay(
                      tableData[fuel]?.total_opex / denominatorICE
                    )
                  : "N/A"
              )
            ),
          },
          {
            name: spanWrapperBig(
              <>
                Total Operating Expenses
                <br />
                (without Interest Payments)
              </>
            ),

            ev: spanWrapperBig(
              !isNaN(tableData.BEV.OPEXminusInterest)
                ? currencyDisplay(
                    tableData.BEV.OPEXminusInterest / denominatorBEV
                  )
                : "N/A"
            ),
            ...fuelDataHelper((fuel) =>
              spanWrapperBig(
                !isNaN(tableData[fuel]?.OPEXminusInterest)
                  ? currencyDisplay(
                      tableData[fuel]?.OPEXminusInterest / denominatorICE
                    )
                  : "N/A"
              )
            ),
          },
          {
            name: spanWrapper(
              <>
                Total Energy Cost
                <br />
                (Electricity or Fuel)
              </>
            ),

            ev: spanWrapper(
              !isNaN(tableData.BEV.total_energy_cost)
                ? currencyDisplay(
                    tableData.BEV.total_energy_cost / denominatorBEV
                  )
                : "N/A"
            ),
            ...fuelDataHelper((fuel) =>
              spanWrapper(
                !isNaN(tableData[fuel]?.total_energy_cost)
                  ? currencyDisplay(
                      tableData[fuel]?.total_energy_cost / denominatorICE
                    )
                  : "N/A"
              )
            ),
          },
          {
            name: "Fuel Cost",
            ev: "-",
            ...fuelDataHelper((fuel) =>
              !isNaN(tableData[fuel]?.electricity_cost)
                ? currencyDisplay(
                    tableData[fuel]?.electricity_cost / denominatorICE
                  )
                : "N/A"
            ),
          },
          {
            name: "Electricity Cost",
            ev: !isNaN(tableData.BEV.electricity_cost)
              ? currencyDisplay(tableData.BEV.electricity_cost / denominatorBEV)
              : "N/A",
            ...fuelDataHelper(() => "-"),
          },
          {
            name: (
              <>
                Demand Charges
                <br />
                (Electricity)
              </>
            ),
            ev: !isNaN(tableData.BEV.demand_charge)
              ? currencyDisplay(tableData.BEV.demand_charge / denominatorBEV)
              : currencyDisplay(0),
            ...fuelDataHelper(() => "-"),
          },
          {
            name: spanWrapper("Maintenance Cost"),
            ev: spanWrapper(
              !isNaN(tableData.BEV.maintenance_cost)
                ? currencyDisplay(
                    tableData.BEV.maintenance_cost / denominatorBEV
                  )
                : "N/A"
            ),
            ...fuelDataHelper((fuel) =>
              spanWrapper(
                !isNaN(tableData[fuel]?.maintenance_cost)
                  ? currencyDisplay(
                      tableData[fuel]?.maintenance_cost / denominatorICE
                    )
                  : "N/A"
              )
            ),
          },
          {
            name: spanWrapper("Insurance Cost"),
            ev: spanWrapper(
              !isNaN(tableData.BEV.insurance_cost)
                ? currencyDisplay(tableData.BEV.insurance_cost / denominatorBEV)
                : "N/A"
            ),
            ...fuelDataHelper((fuel) =>
              spanWrapper(
                !isNaN(tableData[fuel]?.insurance_cost)
                  ? currencyDisplay(
                      tableData[fuel]?.insurance_cost / denominatorICE
                    )
                  : "N/A"
              )
            ),
          },
        ],
      });
    }

    return rowData;
  };

  const getTableTitle = () => {
    if (tableView == 1) return "Financial Analysis Summary";
    else if (tableView == 2) return "Capital Expenditure";
    else if (tableView == 3) return "Operating Expenditure";
    else return "Financial Analysis Summary";
  };

  return (
    <Container fixed maxWidth="xl">
      <MaterialTable
        title={getTableTitle()}
        columns={columns}
        icons={Icons()}
        data={data(denominatorBEV, denominatorICE)}
        actions={[
          {
            icon: () => (
              <TogglePerVehicleComponent isPerVehicle={isPerVehicle} />
            ),
            tooltip: "Toggle Per Vehicle View",
            isFreeAction: true,
            onClick: () => setIsPerVehicle(!isPerVehicle),
          },
        ]}
        components={{
          Row: ({ data, index: rowIndex }) => (
            <>
              {/* break line between groups of tableRows */}
              <TableRow className="no-print">
                <TableCell />
              </TableRow>
              {data.subcategory.map((val, index) => (
                <TableRow
                  className={
                    index === 0 && (rowIndex === 2 || rowIndex === 3)
                      ? "print-break"
                      : ""
                  }
                  key={index}
                  sx={{
                    backgroundColor:
                      data.tableData.id % 2 == 0 ? "#b6c8f0" : "#dce6fc",
                    height: "100%",
                  }}
                >
                  {index == 0 && (
                    <TableCell
                      rowSpan={data.subcategory.length}
                      style={{ fontSize: "20px", fontWeight: "600" }}
                    >
                      {data.category}
                    </TableCell>
                  )}
                  {[val.name, val.ev, val[selectedFuel]].map((item, index) => (
                    <TableCell
                      key={index}
                      style={{ fontSize: "15px", fontWeight: "400" }}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          ),
          Container: (containerProps) => (
            <Paper
              {...containerProps}
              className={className}
              sx={{
                width: "100%",
                overflow: "hidden",
                backgroundColor: "#FCFCFC",
              }}
              elevation={3}
            />
          ),
        }}
        options={{
          headerStyle: {
            backgroundColor: "#FCFCFC",
            fontWeight: "600",
            fontSize: "20px",
          },
          //set upper limit of rows to 10 (only needs 6 rows, but material table must be 5, 10, or 20 per page)
          pageSize: 10,
          //hide pagination footer
          paging: false,
          //hides the search bar
          search: false,
          //disables the sorting by columns functionality
          maxColumnSort: 0,
          //disables the ability to drag columns around
          draggable: false,
          //prevents the table from changing layout when switching between All/Per vehicles
          tableLayout: "fixed",
        }}
      />
    </Container>
  );
}
