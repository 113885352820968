import { Box, Typography } from "@mui/material";
import { useRef } from "react";
import { Line } from "react-chartjs-2";
import { MFM_to_AMPM } from "../../utils";

export default function ChargingPortGraph({ data }) {
  const chartRef = useRef();

  /** @type {import("chart.js").ChartOptions} */
  const chartOptions = {
    scales: {
      x: {
        ticks: {
          callback: (val) => (val % 180 === 0 ? MFM_to_AMPM(val) : null),
        },
      },
    },
    interaction: { intersect: false, mode: "index" },
  };

  /** @type {import("chart.js").ChartData} */
  const chartData = {
    labels: Array.from(Array(1441).keys()).map(MFM_to_AMPM),
    datasets: [
      {
        data: data.using_port,
        label: "Simultaneous Charging Events",
        borderColor: "orange",
        backgroundColor: "orange",
        pointRadius: 0,
      },
      {
        data: data.plugged_in,
        label: "Simultaneously Plugged in Vehicles",
        borderColor: "green",
        backgroundColor: "green",
        pointRadius: 0,
      },
      {
        data: data.on_road,
        label: "Vehicles on Road",
        borderColor: "blue",
        backgroundColor: "blue",
        pointRadius: 0,
      },
    ],
  };

  return (
    <>
      <Box display="flex" justifyContent="left" m="1%">
        <Typography variant="h6">Usage Analysis</Typography>
      </Box>
      <Line ref={chartRef} data={chartData} options={chartOptions} />
    </>
  );
}
