import Bolt from "@mui/icons-material/Bolt";
import { ButtonBase } from "@mui/material";

/**
 *
 * @param {Object} props modifications to <button> component
 * @param {boolean} props.hidden is the beacon visible
 * @param {Function} props.onClick the function that is called when the button is clicked, typically to start the tour
 */
export default function TourBeacon(props) {
  return (
    <ButtonBase
      style={{
        display: props?.hidden ? "none" : "inline-block",
        backgroundColor: "transparent",
        // border: 0,
        // borderRadius: 0,
        // color: "#555",
        cursor: "pointer",
        // fontSize: 16,
        // lineHeight: 1,
        // padding: 8,
        WebkitAppearance: "none",
        height: 36,
        width: 36,
        position: "relative",
        ...props?.sx,
      }}
      {...props}
    >
      <Bolt
        sx={{
          animation: "joyride-beacon-inner-custom 1.2s infinite ease-in-out",
          // backgroundColor: "darkblue",
          border: `2px solid grey`,
          borderRadius: "5px",
          // borderRadius: "50%",
          // display: "block",
          height: "50%",
          left: "50%",
          opacity: 0.7,
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
        }}
      />
      <Bolt
        color="secondary"
        sx={{
          animation: "joyride-beacon-outer-custom 1.2s infinite ease-in-out",
          // backgroundColor: `rgba(144, 202, 249)`, //use these if you want a pulsing circle/shape around the tutorial button
          // border: `2px solid grey`,
          // borderRadius: "50%",
          // boxSizing: "border-box",
          // display: "block",
          height: "100%",
          left: 0,
          opacity: 0.9,
          position: "absolute",
          top: 0,
          transformOrigin: "center",
          width: "100%",
        }}
      />
    </ButtonBase>
  );
}
