import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import DataContextProvider from "./contexts/dataContext";

createRoot(document.getElementById("root")).render(
  <React.StrictMode style={{ backgroundColor: "#F8F8F8" }}>
    <DataContextProvider>
      <App />
    </DataContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
