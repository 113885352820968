import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Collapse,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import {
  gtfsCalendarURL,
  gtfsURL,
  resourceURL,
  simulationURL,
  vehicleURL,
} from "../../../static/constants/backendRoutes";
import stepInfo from "../../../static/constants/stepInfo";
import UseAuth from "../../auth/useAuth";
import { AssessmentAnalysisStepper } from "../../secondary/steppers";
import TimedCircularProgress from "../../secondary/timedCircularProgress";
import {
  errorHandler,
  getLocalData,
  partialClearLocalData,
  storeLocalData,
} from "../../utils";
import { NextPageButton } from "../commonComponents";
import SimulationSubtitle from "../dialogs/simulationSubtitle";

const STEP_NUMBER = 0;

function GTFSImport() {
  const [inputFile, setInputFile] = useState({
    calendar: undefined,
    calendar_dates: undefined,
    shapes: undefined,
    routes: undefined,
    stop_times: undefined,
    stops: undefined,
    trips: undefined,
  });
  const requiredFiles = Object.keys(inputFile).slice(3);
  const calendarFiles = Object.keys(inputFile).slice(0, 2);

  const [calendarData, setCalendarData] = useState();
  const [currentProject, setCurrentProject] = useState({});
  const [defaultVehicleDetails, setDefaultVehicleDetails] = useState(undefined); //used to run gtfs as placeholder vehicle details
  const [selectedServiceIdGroup, setSelectedServiceIdGroup] = useState(0); //index of the selected service id group (can be null if none are selected)
  const [optionalDropdowns, setOptionalDropdowns] = useState({
    isCalendarOpen: false,
    isShapesOpen: false,
  });
  const [buttonLoading, setButtonLoading] = useState(undefined); //"gtfs" for running gtfs, "calendar" for analyze operating schedule/calendar loading

  const navigate = useNavigate();

  const { snackBarElement } = useContext(SnackBarContext);

  /**
   * the selected service Ids array, will be an empty list if the selectedServiceIdGroup is null (i.e. none selected), or if the calendar data hasn't finished loading yet
   * @type {string[]}
   */
  const selectedServiceIds =
    (selectedServiceIdGroup !== null &&
      calendarData?.[selectedServiceIdGroup]?.service_ids) ||
    [];

  function Item(props) {
    const { sx, ...other } = props;
    return (
      <Box
        sx={{
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          p: 1,
          borderRadius: 2,
          fontSize: "0.875rem",
          fontWeight: "600",
          ...sx,
        }}
        {...other}
      />
    );
  }

  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  Item.propTypes = {
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
      ),
      PropTypes.func,
      PropTypes.object,
    ]),
  };

  useEffect(() => {
    /** UPDATE: retrieves project data from indexDB, and then
     * fetches depot and vehicle data related to that project
     */
    async function fetchData() {
      let headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      //fetches project data from the localDb, and stores it in currentProjectState
      const { data: currentProject } = await getLocalData("project", "data");
      if (!currentProject) {
        //if no current project stored in frontend
        snackBarElement.current.displayToast(
          "Unable to Find Current Simulation's Project, Please Return to Fleet Operation Input and Try Again",
          "error"
        );
        return;
      }
      setCurrentProject(currentProject);

      //todo: look into if this is being used
      //todo: advanced error handling
      /**check if the user has vehicles assigned to the project
      and stores those vehicles for later use in block filtering */
      fetch(`${resourceURL}?type=2&project_id=${currentProject.id}`, {
        method: "GET",
        headers: headers,
      })
        .then((res) => {
          if (res.ok) {
            return res.json().then(({ data: vehicleResources }) => {
              //todo: look into if this is even being used
              //todo: do we need a check for if resource inventory is an empty array?
              fetch(`${vehicleURL}?model=${vehicleResources[0].resource_id}`, {
                method: "GET",
                headers: headers,
              })
                .then((response) => {
                  if (response.ok) {
                    return response.json().then(({ data: vehicles }) => {
                      setDefaultVehicleDetails(vehicles[0]);
                    });
                  } else errorHandler(response, snackBarElement);
                })
                .catch((err) => {
                  console.log(err);
                  snackBarElement.current.displayToast(
                    "Network error occurred while getting vehicle data",
                    "error"
                  );
                });
            });
          } else
            errorHandler(
              res,
              snackBarElement,
              "Unable to get resources from depots!"
            );
        })
        .catch((err) => {
          console.log(err);
          snackBarElement.current.displayToast(
            "Network error occurred while getting project resources",
            "error"
          );
        });
    }
    fetchData();
  }, []);

  const Input = styled("input")({
    display: "none",
  });

  const isButtonsDisabled = !requiredFiles.every((key) =>
    Boolean(inputFile[key])
  );

  /** deletes an uploaded file */
  const removeFile = (key) => {
    //if calendar files are changed, delete the calendarData
    setCalendarData(undefined);
    setInputFile({ ...inputFile, [key]: undefined });
  };

  /**
   * Stores the uploaded files in a useState
   * @param {*} event contains the file uploaded by the user
   * @param {String} key stops/stop_times/trips/routes (specifies the type input file)
   */
  const InputFileChangeHandler = (event, key) => {
    if (calendarFiles.includes(key) || key == "trips")
      setCalendarData(undefined);
    setInputFile({ ...inputFile, [key]: event.target.files[0] }); //use ... to return a COPY of the upload, instead of the (modified) original upload itself; so that REACT sees it as a different object, and causes a rerender
  };
  /**
   * packs everything up and sends it sends it to the GTFS api
   * then parses the block data out of the response based on vehicle efficiency
   * and model, then stores everything into indexDB (localDb)
   * @param {*} event
   * @param {string[]} selectedServiceIds (this is probably unnecessary, but included just in case a re-render causes the value in the function to be lost)
   */
  const handleSubmit = async (event, selectedServiceIds) => {
    const formData = new FormData();
    formData.append("stops_file", inputFile.stops);
    formData.append("times_file", inputFile.stop_times);
    formData.append("trips_file", inputFile.trips);
    formData.append("routes_file", inputFile.routes);
    formData.append("project_id", currentProject.id);
    formData.append("shapes_file", inputFile.shapes);
    formData.append("service_points", JSON.stringify(selectedServiceIds));

    const { data: sim } = await getLocalData("simulation", "data");

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      Accept: `application/json; version=${sim.analysis_type_steps.create_simulation.import_gtfs_fleet_data}`,
    };

    setButtonLoading("gtfs");
    fetch(gtfsURL, { method: "POST", headers: headers, body: formData })
      .then((response) => {
        delete headers["Accept"];
        if (response.ok) {
          return response.json().then(async ({ blocks }) => {
            storeLocalData("blocks", { data: blocks }); //save the results locally on frontend

            //Save the data on the backend Body
            const backendBody = {
              id: sim.id, //The simulation ID
              current_page: stepInfo[STEP_NUMBER + 1].route,
              steps: {
                blocks: blocks, //the blocks to save on the backend
                //clear out the future pages' backend data
                routeEnergy: {},
                battery: {},
                fleetSizing: {},
                evAssessment: {},
                financial: {},
                tco: {},
              },
              completed: false,
            };

            //clear out the future pages' frontend data
            partialClearLocalData([
              "routeEnergy",
              "battery",
              "fleetSizing",
              "evAssessment",
              "financial",
              "tco",
            ]);

            const simHeaders = {
              Authorization: `Token ${UseAuth("get")}`,
              "Content-Type": "application/json",
            };

            //todo: advanced error handling
            fetch(simulationURL, {
              method: "PATCH",
              headers: simHeaders,
              body: JSON.stringify(backendBody),
            })
              .then((response) => {
                if (response.ok) {
                  snackBarElement.current.displayToast(
                    "GTFS Calculation Complete",
                    "success"
                  );
                  navigate(stepInfo[STEP_NUMBER + 1].route);
                } else {
                  errorHandler(
                    response,
                    snackBarElement,
                    "Error sending data to Simulation backend."
                  );
                  setButtonLoading(undefined);
                }
              })
              .catch((e) => {
                console.log("error", e);
                snackBarElement.current.displayToast(
                  "Error Sending Data to Simulation Backend",
                  "error"
                );
                setButtonLoading(undefined);
              });
          });
        } else {
          errorHandler(
            response,
            snackBarElement,
            "Error sending data to gtfs backend"
          );
          setButtonLoading(undefined);
        }
      })
      .catch((err) => {
        console.log(err);
        snackBarElement.current.displayToast(String(err), "error");
        setButtonLoading(undefined);
      });
  };

  const handleCalendarRun = async (event) => {
    const formData = new FormData();
    formData.append("trips_file", inputFile.trips);
    formData.append("calendar_file", inputFile.calendar);
    formData.append("calendar_dates", inputFile.calendar_dates);

    const { data: sim } = await getLocalData("simulation", "data");

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      Accept: `application/json; version=${sim.analysis_type_steps.create_simulation.import_gtfs_fleet_data}`,
    };

    setButtonLoading("calendar");
    fetch(gtfsCalendarURL, {
      method: "POST",
      headers: headers,
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          response.json().then(({ schedule }) => {
            setCalendarData(schedule);
          });
        } else {
          console.log(response);
          snackBarElement?.current?.displayToast(
            "Failed to analyze operating schedule",
            "error",
            5000
          );
          setCalendarData(undefined);
        }
        setButtonLoading(undefined);
      })
      .catch((e) => {
        console.log(e);
        snackBarElement?.current?.displayToast(
          "Network error occurred while analyzing operating schedule",
          "error",
          5000
        );
        setCalendarData(undefined);
        setButtonLoading(undefined);
      });
  };

  const fileUploadComponents = (key, index) => (
    <Item sx={{ gridRow: 1 }} key={index}>
      <label htmlFor={key + "Button"}>
        <Input
          id={key + "Button"}
          type="file"
          accept=".csv, text/plain"
          name={key}
          onChange={(event) => InputFileChangeHandler(event, key)}
          required
        />
        <Button variant="outlined" className="btn" component="span">
          Upload {key.replaceAll("_", " ")} File
          {key === "calendar_dates" ? (
            <span className="button-secondary-text">&nbsp;(optional)</span>
          ) : (
            ""
          )}
        </Button>
      </label>
      {inputFile[key] ? (
        <p>
          <b>Filename:</b> {inputFile[key].name}
          <span style={{ minHeight: "200px" }} onClick={() => removeFile(key)}>
            <DeleteSharpIcon
              style={{
                verticalAlign: "middle",
                marginLeft: "2.5px",
                marginBottom: "3px",
                cursor: "pointer",
              }}
              fontSize="small"
            />
          </span>
          <br />
        </p>
      ) : (
        <p>Select file to show details</p>
      )}
    </Item>
  );

  return (
    <div>
      <br />
      <br />
      <AssessmentAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      {Object.keys(currentProject).length > 0 ? (
        <div>
          <br />
          <Container fixed sx={{ alignItems: "center", display: "flex" }}>
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              align="left"
              className="page-title"
            >
              GTFS&nbsp;Fleet&nbsp;Operation&nbsp;Import
            </Typography>
            <SimulationSubtitle />
          </Container>
          <br />
          {defaultVehicleDetails ? (
            <Container>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                align="left"
                className="page-title"
              >
                Step 1 - Required
              </Typography>
              <hr />
              <Box sx={{ display: "grid", gridAutoColumns: "1fr", gap: 1 }}>
                {requiredFiles.map(fileUploadComponents)}
              </Box>
              <br />
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                align="left"
                className="page-title"
                sx={{ color: isButtonsDisabled ? "grey" : "black" }}
              >
                Step 2 - Optional
              </Typography>
              <hr />
              <Accordion
                expanded={optionalDropdowns.isShapesOpen}
                disabled={isButtonsDisabled}
                onChange={() =>
                  setOptionalDropdowns({
                    ...optionalDropdowns,
                    isShapesOpen: !optionalDropdowns.isShapesOpen,
                  })
                }
                sx={{ px: 2, m: 2 }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    Upload Shapes File
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    (Recommended)
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: "grid",
                      gridAutoColumns: "1fr",
                      gap: 1,
                      m: 2,
                    }}
                  >
                    {fileUploadComponents("shapes", 2)}
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={optionalDropdowns.isCalendarOpen}
                disabled={isButtonsDisabled}
                onChange={() =>
                  setOptionalDropdowns({
                    ...optionalDropdowns,
                    isCalendarOpen: !optionalDropdowns.isCalendarOpen,
                  })
                }
                sx={{ px: 2, m: 2 }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    Analyze Operating Schedule
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    (Recommended)
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: "grid",
                      gridAutoColumns: "1fr",
                      gap: 1,
                      m: 2,
                    }}
                  >
                    {calendarFiles.map(fileUploadComponents)}
                  </Box>
                  <Collapse in={Boolean(calendarData)}>
                    <ToggleButtonGroup
                      fullWidth
                      color="secondary"
                      value={selectedServiceIdGroup}
                      exclusive={true}
                      onChange={(e, index) => {
                        if (index === null) {
                          snackBarElement?.current?.displayToast(
                            "No operating schedule selected",
                            "info"
                          );
                          setSelectedServiceIdGroup(index); // none selected
                        } else if (calendarData?.[index]?.service_ids?.length) {
                          //checks for service Ids, despite displaying warning about unique routes
                          snackBarElement?.current?.displayToast(
                            "",
                            "info",
                            0,
                            true
                          ); //clears the snackbar alerts, if there were any being displayed (done to clear any "no operating schedule selected" that were displayed as a result of prior selection changes)
                          setSelectedServiceIdGroup(index); // one selected
                        } else
                          snackBarElement?.current?.displayToast(
                            "Selection must have at least 1 unique route.",
                            "warning"
                          ); // invalid selection
                      }}
                    >
                      {calendarData?.map((data, index) => (
                        <ToggleButton
                          sx={{ display: "inline-block" }}
                          value={index}
                          key={data.schedule}
                        >
                          <Typography variant="h5" color="primary">
                            {data.schedule}
                          </Typography>
                          Unique Routes: {data.unique_route_count}
                          <br />
                          Unique Trips: {data.unique_trips_count}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                    <br />
                    <br />
                    Select Operating Schedule to include with assessment
                  </Collapse>
                </AccordionDetails>
                <AccordionActions sx={{ justifyContent: "center" }}>
                  <LoadingButton
                    sx={{ width: "95%", mb: 3 }}
                    variant="outlined"
                    className="btn"
                    disabled={isButtonsDisabled || !inputFile?.calendar}
                    onClick={handleCalendarRun}
                    loading={buttonLoading === "calendar"}
                  >
                    Analyze Operating Schedule
                  </LoadingButton>
                </AccordionActions>
              </Accordion>
              <br />
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                align="left"
                className="page-title"
                sx={{ color: isButtonsDisabled ? "grey" : "black" }}
              >
                Step 3 - Process GTFS Data
              </Typography>
              <hr />
              <br />
              <NextPageButton
                sx={{ width: "95%" }}
                disabled={isButtonsDisabled}
                onClick={(e) => handleSubmit(e, selectedServiceIds)}
                loading={buttonLoading === "gtfs"}
              >
                Process GTFS Data
              </NextPageButton>
            </Container>
          ) : (
            <>
              <CircularProgress />
              <br />
              Loading...
            </>
          )}
        </div>
      ) : (
        <TimedCircularProgress
          text={
            <Typography variant="h3" gutterBottom component="div">
              No Project Selected. <br />
              <Link to={stepInfo[STEP_NUMBER].route}>Select</Link> or{" "}
              <Link to="/project">Create</Link> one and come back.
            </Typography>
          }
        />
      )}

      {/* todo: this backdrop component is on a bunch of pages, so make it its own util or component (will need to pass in stepNumber and buttonLoading) */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={Boolean(buttonLoading)}
        transitionDuration={{ exit: 0 }}
      >
        <Container alignitems="center" justify="center" aligncontent="center">
          <Container align="center">
            <CircularProgress color="inherit" />
          </Container>
          <br />
          <Container align="center">
            <Typography variant="h5">
              {buttonLoading === "calendar" ? (
                <b>Analyzing Operating Schedule...</b>
              ) : (
                <b>Sending data over to {stepInfo[STEP_NUMBER + 1].label}</b>
              )}
            </Typography>
          </Container>
        </Container>
      </Backdrop>
    </div>
  );
}

export default GTFSImport;
