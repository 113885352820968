import MaterialTable from "@material-table/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ReactSpreadsheetImport, StepType } from "react-spreadsheet-import";
import { ReactSpreadSheetImportTheme } from "../../../static/constants/theme";
import isOnLand from "../../../static/utils/is_on_land";
import { Icons, readExcelFileAsync } from "../../utils";

/**
 * @callback handleCoordSubmit
 * @param {Object[]} coords a list of the [lat,long] coordinates
 * @param {Number} coords[].0 the latitude
 * @param {Number} coords[].1 the the longitude
 */

/**
 *
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setIsOpen
 * @param {Object[]} props.initialCoords a list of the [lat,long] coordinates
 * @param {Number} props.initialCoords[].0 the latitude
 * @param {Number} props.initialCoords[].1 the the longitude
 * @param {handleCoordSubmit} props.handleCoordSubmit the callback function used to send the new coordinates to the parent function
 * @param {React.MouseEventHandler<HTMLButtonElement>} props.handleCoordClose the callback function used to close the dialog box
 */
export default function CoordInput({
  isOpen,
  initialCoords,
  handleCoordSubmit,
  handleCoordClose,
}) {
  /** @type {[{Number: {0: latitude, 1: longitude}}, React.Dispatch<React.SetStateAction<{0: Number, 1: Number}[]>>]} coords */
  const [data, setData] = useState([]);
  const coordCounter = useRef(0);

  //react spreadsheet import values
  const [initialSpreadsheetState, setInitialSpreadsheetState] =
    useState(undefined);
  const [isSpreadSheetImportOpen, setIsSpreadSheetImportOpen] = useState(false);

  /** fires every time the dialog is opened/closed */
  useEffect(() => {
    function fetchData() {
      //make a deep copy of the original data
      let coords = JSON.parse(JSON.stringify(initialCoords));
      //if user has pre-existing coord data
      if (coords.length)
        // during initialization, it's fine for the id to be the same as the index; what matters is that all ids are UNIQUE, ALWAYS
        coords.forEach((coord, index) => (coord.id = index));
      coordCounter.current = coords?.length || 0;

      setData(coords);
    }

    //note: fetchData should only fire if the user selects "manual input" or if the initialCoords exists
    if (isOpen) fetchData();
  }, [isOpen, initialCoords]);

  /** column definitions for the Route Stop Coordinates Pop-up's Table */
  /** @type {import("@material-table/core").Column<never>[]} */
  const coordColumns = [
    { title: "Id", field: "id", hidden: true },
    {
      title: "Latitude",
      field: "0",
      type: "numeric",
      validate: (rowData) =>
        rowData?.[0] <= 90 && rowData?.[0] >= -90 ? true : "-90 to 90",
      editComponent: (
        props //takes care of when we create a new project or edit an existing table
      ) => (
        <TextField
          fullWidth
          name="latitude"
          label="latitude"
          variant="standard"
          type="number"
          required
          error={!props.value || props.error}
          helperText={!props.value ? "Empty Value" : props.helperText}
          value={props.value ? props.value : ""}
          InputProps={{ inputProps: { min: -90, max: 90, step: 0.1 } }}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
    },
    {
      title: "Longitude",
      field: "1",
      type: "numeric",
      validate: (rowData) =>
        rowData?.[1] <= 180 && rowData?.[1] >= -180 ? true : "-180 to 180",
      editComponent: (
        props //takes care of when we create a new project or edit an existing table
      ) => (
        <TextField
          fullWidth
          name="longitude"
          label="longitude"
          variant="standard"
          type="number"
          required
          error={!props.value || props.error}
          helperText={!props.value ? "Empty Value" : props.helperText}
          value={props.value ? props.value : ""}
          InputProps={{ inputProps: { min: -180, max: 180, step: 0.1 } }}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
    },
  ];

  /**
   * reads the imported excel file, and opens the react spreadsheet import
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  async function handleImportExcel(e) {
    const data = await readExcelFileAsync(e);
    const initialState = { type: StepType.selectHeader, data: data };
    setInitialSpreadsheetState(initialState);
    setIsSpreadSheetImportOpen(true);
  }

  /** closes the excel import dialog */
  function onSpreadSheetImportClose() {
    document.getElementById("coord_file_input").value = null; //allows user to upload another file, on cancel
    setIsSpreadSheetImportOpen(false);
  }

  /** closes the dialog box and clears the inputs */
  function handleClose() {
    handleCoordClose();
    setTimeout(() => setData([]), 400);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    // remove the id values from each row with a map function
    handleCoordSubmit(data.map((i) => [i[0], i[1]]));
    handleClose();
  }

  return (
    <Dialog
      open={isOpen}
      component="form"
      onSubmit={handleSubmit}
      //because the user may be inputting a large amount of data here, I've disabled closing the dialog via pressing the "ESC" button, or clicking off of the dialog
      //to re-enable, add an "onClose"
    >
      <DialogTitle>Coordinate Inputs</DialogTitle>
      <DialogContent>
        <MaterialTable
          title=""
          columns={coordColumns}
          data={data}
          icons={Icons()}
          variant="standard"
          localization={{
            toolbar: {
              searchPlaceholder: "Filter",
              searchTooltip: "Filter",
            },
            body: {
              emptyDataSourceMessage: (
                <div>
                  <Button variant="outlined" className="btn" component="label">
                    <input
                      id="coord_file_input"
                      type="file"
                      onChange={handleImportExcel}
                      className="excel-import-input"
                      accept=".xls,.xlsx"
                    />
                    Import Excel File
                  </Button>
                </div>
              ),
            },
          }}
          options={{ search: false }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                // todo: add in a check for valid/invalid coords here; base it off of Jonathan's JSON outline of land masses
                setTimeout(() => {
                  const newDataList = [+newData[0], +newData[1]];
                  newDataList.id = coordCounter.current;
                  coordCounter.current += 1;
                  setData([...data, newDataList]);
                  resolve();
                }, 100);
              }),
            onRowUpdate: async (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const index = data.findIndex((row) => row.id == oldData.id);
                  data[index][0] = +newData[0];
                  data[index][1] = +newData[1];
                  setData([...data]);
                  resolve();
                }, 100);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const index = data.findIndex((row) => row.id == oldData.id);
                  data.splice(index, 1);
                  setData([...data]);
                  resolve();
                }, 100);
              }),
          }}
        />

        {/* excel file import */}
        <CoordInputImportCsv
          isOpen={isSpreadSheetImportOpen}
          onClose={onSpreadSheetImportClose}
          setData={setData}
          coordCounter={coordCounter}
          initialStepState={initialSpreadsheetState}
        />
      </DialogContent>
      <DialogActions>
        <Button className="btn" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="btn" variant="contained" type="submit">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/** excel/csv spreadsheet import component
 * @param {Boolean} isOpen if the spreadsheetimport file is open
 * @param {*} onClose the "close spreadsheetimport dialog" function
 * @param {React.Dispatch<React.SetStateAction<{0: Number, 1: Number}[]>>} setData
 */
const CoordInputImportCsv = ({
  isOpen,
  onClose,
  setData,
  coordCounter,
  initialStepState,
}) => {
  const fields = [
    {
      label: "Latitude",
      key: "0",
      alternateMatches: ["latitude", "lat"],
      fieldType: { type: "input" },
      example: "44.58",
      validations: [
        {
          rule: "required",
          errorMessage: "Latitude is required",
        },
        {
          rule: "regex",
          value: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
          errorMessage: "Latitude must be between -90 and 90",
        },
      ],
    },
    {
      label: "Longitude",
      key: "1",
      alternateMatches: ["longitude", "long", "lon"],
      fieldType: { type: "input" },
      example: "-140.512",
      validations: [
        {
          rule: "required",
          errorMessage: "Longitude is required",
        },
        {
          rule: "regex",
          value: /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
          errorMessage: "Longitude must be between -180 and 180",
        },
      ],
    },
  ];

  /** ((data: Result<string>, file: File) => void)
   * @param {Result<string>} data
   * @param {File} _file unused
   */
  function handleSpreadsheetImportSubmit(data, _file) {
    //add a unique "id" to each lat/long pair
    const coords = data.validData.map((row, index) => {
      let coord = [+row[0], +row[1]];
      coord.id = index;
      return coord;
    });

    coordCounter.current += 1;
    setData(coords);
    onClose();
  }

  /**
   *
   * @param {Data<string>} rowData
   * @param {(fieldKey: string, error: Info) => void} addError
   */
  function handleRowChange(rowData, addError) {
    if (!isOnLand([+rowData["0"], +rowData["1"]])) {
      addError("0", {
        message: "Point must be on land",
        level: "error",
      });
      addError("1", {
        message: "Point must be on land",
        level: "error",
      });
    }

    return rowData;
  }

  return (
    <ReactSpreadsheetImport
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSpreadsheetImportSubmit}
      fields={fields}
      initialStepState={initialStepState}
      rowHook={handleRowChange}
      customTheme={ReactSpreadSheetImportTheme}
    />
  );
};
