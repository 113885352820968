import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import introJs from "intro.js/intro";
import Cookie from "js-cookie";
import {
  MRT_ActionMenuItem,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import TYPE_STRINGS from "../../static/constants/TYPE_STRINGS";
import {
  onboardingFlagURL,
  projectURL,
} from "../../static/constants/backendRoutes";
import currency from "../../static/constants/currency.json";
import materialReactTableOptions from "../../static/constants/defaultMaterialReactTableOptions";
import systems_of_measurement from "../../static/constants/systems_of_measurement";
import {
  firstVisitTourOptions,
  projectTourOptions,
} from "../../static/constants/tourOptions";
import UseAuth from "../auth/useAuth";
import { ProjectStepper } from "../secondary/steppers";
import TourBeacon from "../secondary/tourBeacon";
import { errorHandler, fetchCountries, parseFromValuesOrFunc } from "../utils";
import ProjectDialog from "./dialogs/projectDialog";

/**
 * creates some common material react table columndefs for select-type columns
 * @param {Object} lookup the lookup table of values to labels
 * @returns {import("material-react-table").MRT_ColumnDef<never>}
 */
function defaultMrtSelectColumnDefs(lookup) {
  const options = Object.entries(lookup).map(([value, label]) => ({
    value,
    label,
  }));
  return {
    Cell: ({ cell }) => lookup[cell.getValue()] ?? "Loading...",
    filterVariant: "autocomplete",
    filterSelectOptions: options,
    editVariant: "select",
    editSelectOptions: options,
  };
}

function Project() {
  const [countries, setCountries] = useState([]);
  const [projectData, setProjectData] = useState([]);
  //displays error message in table if data failed to fetch/loading message if response not yet recieved
  const [dataFetchError, setDataFetchError] = useState(false);
  const [validationErrors, setValidationErrors] = useState({}); //used to display table errors for cells on attempted edit/add of row

  const originalColumnVisibility = useRef(null); //used to restore column visibility after edit, if an editable column was hidden
  const [columnVisibility, setColumnVisibility] = useState({
    //initially hidden columns
    currency_code: false,
    unit: false,
  });
  /** @type {[MRT.MRT_Row<MRT.TData> | null]} */
  const [editingRow, setEditingRow] = useState(null);

  const { pageAccessMemo, organizationMemo, accessRights } =
    useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);

  const projectDataFiltered = useMemo(
    () =>
      projectData.filter(
        (project) =>
          !organizationMemo?.id ||
          project.organization_id == organizationMemo.id
      ),
    [projectData, organizationMemo]
  );

  const project_types = TYPE_STRINGS.PROJECT_TYPE;

  const currencyLookup = Object.entries(currency).reduce(
    (obj, [key, value]) => Object.assign(obj, { [key]: value.name }),
    {}
  );
  const countryLookup = countries.reduce(
    (obj, country) => Object.assign(obj, { [country.iso2]: country.name }),
    {}
  );

  useEffect(() => {
    //need isMounted flag on the projects page, because otherwise it's "onboarding tour"
    // promise could fulfill and launch on a separate page. Not an issue for other page's tours,
    //  as they need to be manually launched by the user
    let isMounted = true;
    /**
     * gets existing projects from backend
     */
    function fetchData() {
      if (!UseAuth("get")) {
        window.location.assign("/login");
      } else if (accessRights.projects.read_own_org_projects == false) {
        //scenario where the backend Access rights check has responded with "you don't have access"
        // if backend Acceess rights has yet to respond, then read_own_org_projects is undefined
        setDataFetchError(true); //replaces Loading message with a "No records to display" if the list is empty
        snackBarElement.current.displayToast(
          "You have insufficient permissions to list projects",
          "info",
          5000
        );
      } else if (accessRights.projects.read_own_org_projects) {
        let headers = {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
        };

        const countryPromise = fetchCountries(snackBarElement).then(
          (countries) => setCountries(countries)
        );

        // if project promise resolves with true, check if the site needs to fire the onboarding tour
        const projectPromise = fetch(
          `${projectURL}?organization_id_list=-${
            Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
          }`,
          {
            method: "GET",
            headers: headers,
          }
        )
          .then((response) => {
            if (response.ok) {
              return response.json().then(({ data: projects }) => {
                //reversed, so that the most recent project is at top of table
                setProjectData(projects.reverse());
                if (table)
                  table.setState((prev) => ({ ...prev, isLoading: false }));
                if (!projects.length) {
                  setTimeout(() => {
                    introJs().setOptions(firstVisitTourOptions()).start();
                  }, [100]);

                  pageAccessMemo.setPageAccess(0); //deactivates all the buttons on the page until user creates at least 1 project
                  snackBarElement.current.displayToast(
                    "No Projects found, please create a new project before continuing",
                    "info",
                    5000
                  );
                  return false;
                } else {
                  // fires when the reponse contains at least 1 project
                  // this shouldn't cause any re-renders, but will re-activate all buttons on page, once the page contains at least 1 project
                  pageAccessMemo.setPageAccess(1);
                  return true;
                }
              });
            } else {
              errorHandler(
                response,
                snackBarElement,
                "Something went wrong getting existing projects"
              );
              return false;
            }
          })
          .catch((e) => {
            console.log(e);
            snackBarElement.current.displayToast(
              "Network Error occurred while fetching project data, please try again later",
              "error",
              10000
            );
            return false;
          })
          .finally(() => setDataFetchError(true)); //replaces "loading" message with a "no data found" message, for when the projectData is an empty list

        Promise.all([projectPromise, countryPromise]).then(
          ([checkIfOnboardingTourFlag, _countryResponse]) => {
            //the below fetch is just to determine whether or not to launch the onboarding tour
            //it is important that  it fires AFTER everything else is done rendering (since it needs
            // to locate all the css targets on the final rendered page.)
            if (
              checkIfOnboardingTourFlag &&
              !Cookie.get("introjs-projectTourDontShowAgain") &&
              isMounted
            )
              fetch(onboardingFlagURL, { method: "GET", headers }).then(
                (res) => {
                  if (res.ok)
                    res.json().then(({ onboarding_tour }) => {
                      if (onboarding_tour && isMounted)
                        introJs()
                          .setOptions(projectTourOptions())
                          .onexit(() => {
                            //if user checked the "don't show this again" flag, disable on backend too.
                            if (Cookie.get("introjs-projectTourDontShowAgain"))
                              fetch(onboardingFlagURL, {
                                method: "PATCH",
                                headers,
                                body: JSON.stringify({
                                  onboarding_tour: false,
                                }),
                              });
                          })
                          .start();
                      else if (!onboarding_tour)
                        Cookie.set("introjs-projectTourDontShowAgain", true);
                    });
                }
              );
          }
        );
      }
    }
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [accessRights.projects.read_own_org_projects, organizationMemo.lookup]);

  /**
   * should fire when closing EITHER row edit or bulk edit,
   * resets the column visibility to state that it was prior to edit
   */
  function generalEditClose() {
    if (originalColumnVisibility.current != null) {
      //if the column visisbility was automatically altered to include hidden editable columns, restore it to original form
      setColumnVisibility(originalColumnVisibility.current);
      originalColumnVisibility.current = null;
    }
  }

  /**
   * should fire when opening either edit or add row
   * ensures all editable columns are visible, and if they're not,
   * it also saves the original visibility status for when edit is closed
   * @param {MRT.MRT_Row<MRT.TData> | null | undefined} [row] optional param used in row edit change, to determine that the row wasn't just closed
   */
  function generalEditOpen(row = undefined) {
    const editableCols = columns.filter((col) => col.enableEditing != false);
    if (
      editableCols.some(
        (col) => columnVisibility?.[col.accessorKey] == false
      ) &&
      row !== null
    ) {
      originalColumnVisibility.current = { ...columnVisibility };
      setColumnVisibility((prev) => ({
        ...prev,
        ...editableCols.reduce(
          (colVis, col) => ({ ...colVis, [col.accessorKey]: true }),
          {}
        ),
      }));
    }
  }

  function onEditingRowClose() {
    generalEditClose();
    setEditingRow(null);
    setValidationErrors({});
  }

  /**
   *
   * @param {import('@tanstack/react-table').OnChangeFn<import("material-react-table").MRT_Row<never> | null} row
   */
  function onEditingRowChange(row) {
    generalEditOpen(row, false);
    setEditingRow(row ? { ...row } : null); // allow for editingRow to be null
  }

  /**
   * check that all fields are valid and accounted for.
   * @param {*} row
   * @returns {Boolean} true=valid, false=invalid
   */
  function validateRow(row) {
    let validationErrors = {};

    Object.entries(row).forEach(([key, value]) => {
      if (!value) validationErrors[key] = "Field is required";
    });
    console.log(validationErrors);
    setValidationErrors(validationErrors);
    return !Object.values(validationErrors).some(Boolean);
  }

  function handleRowAdd({ values, table }) {
    //removes unnecessary fields
    const project = {
      organization_id: values.organization_id,
      name: values.name,
      type: values.type,
      currency_code: values.currency_code,
      country_code: values.country_code,
      unit: values.unit,
    };

    if (!validateRow(project)) return;

    //description is an optional field
    project.description = values.description;

    table.setState((prev) => ({ ...prev, isLoading: true, isSaving: true }));

    fetch(projectURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${UseAuth("get")}`,
      },
      body: JSON.stringify(project),
    })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast(
            "Project Added!",
            "success",
            1500
          );
          response.json().then(({ data: newProject }) => {
            setProjectData([newProject, ...projectData]);
            pageAccessMemo.setPageAccess(1);
            setValidationErrors({});
            table.setCreatingRow(null);
          });
        } else errorHandler(response, snackBarElement);
      })
      .catch((err) => {
        console.log(err);
        snackBarElement.current.displayToast(String(err), "error");
      })
      .finally(() =>
        table.setState((prev) => ({
          ...prev,
          isLoading: false,
          isSaving: false,
        }))
      );
  }

  function handleRowDelete(table, projectId, closeMenu) {
    table.setState((prev) => ({ ...prev, isLoading: true }));

    fetch(projectURL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${UseAuth("get")}`,
      },
      body: JSON.stringify({ project_id_list: [projectId] }),
    })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast(
            "Project Deleted!",
            "success",
            1500
          );
          const updatedProjects = projectData.filter(
            (project) => project.id != projectId
          );
          setProjectData(updatedProjects);
          if (!updatedProjects.length) setDataFetchError(true); //so that, if the final visible resource was deleted, the table doesn't display the loading icon endlessly
          closeMenu();
        } else {
          errorHandler(
            response,
            snackBarElement,
            "Something is not right, try again"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        snackBarElement.current.displayToast(
          "Something is not right, try again",
          "error"
        );
      })
      .finally(() => table.setState((prev) => ({ ...prev, isLoading: false })));
  }

  //DELETE modal
  const openDeleteConfirmModal = (table, row, closeMenu) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      handleRowDelete(table, row.original.id, closeMenu);
    }
  };

  function handleRowEdit({ table, values, row, exitEditingMode }) {
    //make some copys of the data for equality check that excludes things like tableData
    const new_project_copy = {
      id: row.original.id,
      name: values.name,
      description: values.description,
      type: values.type,
      currency_code: values.currency_code,
      country_code: values.country_code,
      unit: values.unit,
    };

    const old_project_copy = {
      id: row.original.id,
      name: row.original.name,
      description: row.original.description,
      type: row.original.type,
      currency_code: row.original.currency_code,
      country_code: row.original.country_code,
      unit: row.original.unit,
    };

    if (!validateRow(new_project_copy)) return;

    if (JSON.stringify(old_project_copy) === JSON.stringify(new_project_copy)) {
      //if data is unaltered, then display an info card, and return
      snackBarElement.current.displayToast(
        "Project data was not altered from Original",
        "info"
      );
      return;
    }

    table.setState((prev) => ({ ...prev, isLoading: true, isSaving: true }));

    //if the data was altered, send a PATCH request to backend
    fetch(projectURL, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${UseAuth("get")}`,
      },
      body: JSON.stringify(new_project_copy),
    })
      .then((response) => {
        if (response.ok) {
          const index = projectData.findIndex(
            (proj) => proj.id === row.original.id
          );
          projectData[index] = { ...row.original, ...new_project_copy };
          snackBarElement.current.displayToast(
            "Project Updated!",
            "success",
            1500
          );
          setProjectData([...projectData]);
          exitEditingMode();
        } else errorHandler(response, snackBarElement);
      })
      .catch((err) => {
        snackBarElement.current.displayToast(
          "Something is not right, try again",
          "error"
        );
        console.log(err);
      })
      .finally(() =>
        table.setState((prev) => ({
          ...prev,
          isLoading: false,
          isSaving: false,
        }))
      );
  }

  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */
    () => [
      //format for the table
      {
        header: "Organization",
        accessorKey: "organization_id",
        Cell: ({ cell }) =>
          organizationMemo.lookup?.[cell.getValue()] ?? "Loading...",
        filterVariant: "autocomplete",
        filterSelectOptions: Object.entries(organizationMemo?.lookup || {}).map(
          ([value, label]) => ({ value, label })
        ),
        // enableEditing: false,
      },
      {
        header: "Fleet Name",
        accessorKey: "name",
        validate: (rowData) =>
          rowData.name === "" ||
          !rowData.name ||
          !rowData.name.replace(/\s/g, "").length
            ? "Required"
            : true,
      },
      {
        header: "Description",
        accessorKey: "description",
      },
      {
        header: "Fleet Type",
        accessorKey: "type",
        ...defaultMrtSelectColumnDefs(project_types),
      },
      {
        header: "Country",
        accessorKey: "country_code",
        ...defaultMrtSelectColumnDefs(countryLookup),
      },
      {
        header: "Currency",
        accessorKey: "currency_code",
        ...defaultMrtSelectColumnDefs(currencyLookup),
        validate: (rowData) =>
          rowData.currency_code === "" || !rowData.currency_code
            ? "No Selection"
            : true,
      },
      {
        header: "System of Measurement",
        accessorKey: "unit",
        ...defaultMrtSelectColumnDefs(systems_of_measurement),
      },
      // {
      //   header: "Created On",
      //   accessorKey: "created_at",
      //   type: "date",
      //   enableEditing: false,
      //   hidden: true,
      //   // defaultSort: "desc",
      // },
      {
        header: "Depots",
        id: "view_depots",
        Cell: ({ row }) => (
          <Button
            variant="outlined"
            className="btn"
            size="small"
            component={Link}
            to={`/depot?projectId=${row.original.id}`}
          >
            Edit/Add
          </Button>
        ),
        enableEditing: false,
        Edit: () => undefined,
      },
      {
        header: "Analyses",
        id: "view_analyses",
        Cell: ({ row }) => (
          <Button
            variant="outlined"
            className="btn"
            size="small"
            component={Link}
            to={`/simulations?projectId=${row.original.id}`}
          >
            View
          </Button>
        ),
        enableEditing: false,
        Edit: () => undefined,
      },
    ],
    [organizationMemo?.lookup, countries, validationErrors]
  );

  const handleTourStart = ({ isFirst = false }) => {
    let tour = introJs();
    if (projectDataFiltered?.length && !isFirst) {
      setTimeout(
        () =>
          tour
            .setOptions(projectTourOptions())
            .setDontShowAgain(false)
            .onexit(() => {
              //if user checked the "don't show this again" flag, disable on backend too.
              const headers = {
                Authorization: `Token ${UseAuth("get")}`,
                "Content-Type": "application/json",
              };
              if (Cookie.get("introjs-projectTourDontShowAgain"))
                fetch(onboardingFlagURL, {
                  method: "PATCH",
                  headers,
                  body: JSON.stringify({ onboarding_tour: false }),
                });
            })
            .start(),
        [100]
      );
    } else {
      //for first-time visitors, or just users without any projects, or users that click the "repeat intro tour" beacon
      tour.setOptions(firstVisitTourOptions()).setDontShowAgain(false).start();
    }
  };

  const table = useMaterialReactTable({
    // ...materialReactTableOptions(),
    ...materialReactTableOptions(),
    data: projectDataFiltered,
    columns: columns,
    initialState: {
      ...materialReactTableOptions().initialState,
      showColumnFilters: true,
      isLoading: true,
    },
    state: {
      ...materialReactTableOptions().state,
      columnVisibility,
      editingRow,
    },
    //miscellaneous options
    onColumnVisibilityChange: setColumnVisibility,
    //create row setttings
    muiEditTextFieldProps: ({ column, ...rest }) => ({
      ...parseFromValuesOrFunc(
        materialReactTableOptions().muiEditTextFieldProps,
        { column, ...rest }
      ),
      required: true,
      onFocus: () =>
        setValidationErrors({ ...validationErrors, [column.id]: false }),
      error: !!validationErrors[column.id],
      helperText: validationErrors[column.id],
    }),
    createDisplayMode: "modal",
    renderCreateRowDialogContent: ({ table, row, _internalEditComponents }) => {
      if (organizationMemo.id)
        row._valuesCache.organization_id = organizationMemo.id; //note: might be better as a project dialog useEffect
      //overwrites the default dialog's body
      return (
        <ProjectDialog
          table={table}
          row={row}
          countries={countries}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          isAddView={true}
        />
      );
    },
    onCreatingRowCancel: onEditingRowClose,
    onCreatingRowSave: handleRowAdd,
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <span style={{ width: "100%" }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            className="btn create-new-btn"
            variant="outlined"
            sx={{ px: 3 }}
            onClick={() => {
              generalEditOpen();
              table.setCreatingRow(true);
            }}
            startIcon={<Add />}
            // disabled={!accessRights.projects.create_project}
          >
            New Project
          </Button>
          <TourBeacon
            id="projectTourBeacon"
            onClick={handleTourStart}
            hidden={
              (!dataFetchError && !projectDataFiltered?.length) ||
              !accessRights.projects.create_project
            }
          />
        </Box>
      </>
    ),

    //data edit settings
    onEditingRowChange: onEditingRowChange,
    onEditingRowCancel: onEditingRowClose,
    onEditingRowSave: handleRowEdit,
    enableEditing: true, //accessRights.projects.update_project,
    renderRowActions: undefined, // overwrites the common renderRowActions value, so that the renderRowActions dropdown becomes available
    renderEditRowDialogContent: ({ table, row, _internalEditComponents }) => (
      <ProjectDialog //overwrites the default dialog's body
        table={table}
        row={row}
        countries={countries}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
        isAddView={false}
      />
    ),

    //row delete settings
    renderRowActionMenuItems: ({ table, row, closeMenu, ...props }) => [
      <MRT_ActionMenuItem
        icon={<Delete />}
        key="delete"
        label="Delete"
        table={table}
        onClick={() => openDeleteConfirmModal(table, row, closeMenu)}
        // disabled={!accessRights.projects.delete_project}
      />,
    ],
  });

  return (
    <div>
      <br />
      <br />
      <ProjectStepper stepNum={1} />
      <br />
      <br />
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          Project&nbsp;Summary
          <TourBeacon
            title="Repeat Introductory Tour"
            onClick={() => handleTourStart({ isFirst: true })}
          />
        </Typography>
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <MaterialReactTable table={table} />
        </Paper>
      </Container>
    </div>
  );
}

export default Project;
