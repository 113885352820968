import { lighten } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo } from "react";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import { parseFromValuesOrFunc, unitWrapper } from "../../utils";

export default function BatteryChild({ rowData }) {
  const data = rowData.data || [];

  /** defines the column structure of batteryChild tables */
  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */ () => [
      {
        header: "Years of Operation",
        accessorKey: "year",
        accessorFn: (row) => `Year ${row.year}`,
      },
      {
        header: "25% of Blocks",
        accessorKey: "25th Percentile",
        accessorFn: (row) => row["25th Percentile"].toFixed(0),
        Cell: ({ cell }) => (
          <>
            {cell.getValue()} {unitWrapper("kWh")}
          </>
        ),
      },
      {
        header: "50% of Blocks",
        accessorKey: "50th Percentile",
        accessorFn: (row) => row["50th Percentile"].toFixed(0),
        Cell: ({ cell }) => (
          <>
            {cell.getValue()} {unitWrapper("kWh")}
          </>
        ),
      },
      {
        header: "75% of Blocks",
        accessorKey: "75th Percentile",
        accessorFn: (row) => row["75th Percentile"].toFixed(0),
        Cell: ({ cell }) => (
          <>
            {cell.getValue()} {unitWrapper("kWh")}
          </>
        ),
      },
      {
        header: "100% of Blocks",
        accessorKey: "100th Percentile",
        accessorFn: (row) => row["100th Percentile"].toFixed(0),
        Cell: ({ cell }) => (
          <>
            {cell.getValue()} {unitWrapper("kWh")}
          </>
        ),
      },
    ]
  );

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    data,
    columns,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableFilters: false,
    enableSorting: false,
    mrtTheme: (theme) => ({
      ...parseFromValuesOrFunc(materialReactTableOptions().mrtTheme, theme),
      baseBackgroundColor: lighten(theme.palette.secondary.light, 0.85),
    }),
    muiTableHeadRowProps: (rest) => {
      const props = parseFromValuesOrFunc(
        materialReactTableOptions().muiTableHeadRowProps,
        rest
      );
      return {
        ...props,
        sx: (theme) => ({
          ...parseFromValuesOrFunc(props?.sx, theme),
          backgroundColor: theme.palette.secondary.light,
          color: "#FFF",
        }),
      };
    },
  });

  return <MaterialReactTable table={table} />;
}
