import {
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { unitWrapper } from "../../utils";

// modified code based off: https://mui.com/material-ui/react-transfer-list/
/// (primarily modified to use forms instead of left/right checked useStates)

/**
 * returns array A with all items that were in array B removed
 * @param {*[]} a the array of items to be removed from
 * @param {*[]} b the array of items to remove
 * @returns {*[]}
 */
function not(a, b) {
  return a.filter((value) => !b.includes(value));
}

/**
 * returns the concatenation of 2 arrays, with any duplicates removed
 * @param {*[]} a
 * @param {*[]} b
 * @returns {*[]}
 */
function union(a, b) {
  return [...a, ...not(b, a)];
}

/**
 *
 * @param {object} props
 * @param {object[]} props.allChargers - the list of all charger objects (retrieved from masterdata)
 * @param {string[]} props.selectedChargers - the list of all default/previously selected chargers
 */
function ChargerOptions({ allChargers, selectedChargers }) {
  const [newSelectedState, setNewSelectedState] = useState(selectedChargers);

  /**
   * a helper function for sorting charger objects in-place by rating
   * @param {Object} charger1
   * @param {Number} charger1.rating
   * @param {Object} charger2
   * @param {Number} charger2.rating
   * @returns {Boolean}
   */
  const sortChargers = (charger1, charger2) =>
    charger1.rating - charger2.rating;

  //break up chargers into separate arrays by electricity type AC/DC, and selection status; and sort by rating
  const AC_Chargers = allChargers
    .filter(
      (charger) =>
        charger.electricity_type == 1 &&
        !newSelectedState.includes(charger.model)
    )
    .sort(sortChargers);
  const DC_Chargers = allChargers
    .filter(
      (charger) =>
        charger.electricity_type == 2 &&
        !newSelectedState.includes(charger.model)
    )
    .sort(sortChargers);
  const newSelectedChargers = allChargers
    .filter((charger) => newSelectedState.includes(charger.model))
    .sort(sortChargers);

  /**
   * adds the selected chargers from the left lists to the right list's charges
   * @param {HTMLFormElement} event
   */
  const handleCheckedRight = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    //NOTE: data keys are the selected AC/DC charger models
    const addedSelectedChargers = Array.from(data.keys());
    setNewSelectedState(union(addedSelectedChargers, newSelectedState));
  };

  /**
   * removes selected chargers from the right list, and puts them back in the left lists
   * @param {HTMLFormElement} event
   */
  const handleCheckedLeft = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    //NOTE: data keys are the selected "checked charger" models
    const unselectedChargers = Array.from(data.keys());
    setNewSelectedState(not(newSelectedState, unselectedChargers));
  };

  /**
   * renders the checkbox lists
   * @param {string} title the title/header of the dialog box
   * @param {*} items the items to be listed
   * @param {string} formID - the ID of the form to submit the checked items for (basically whether the items go to handleCheckedLeft or Right). Also used to determine whether to include the items in the form to be submitted when pressing "save and continue"
   * @returns
   */
  const customList = (title, items, formID) => (
    <Card>
      <CardHeader title={`${title} Chargers`} />
      <Divider />
      <List
        dense
        sx={{ maxHeight: "50vh", position: "relative", overflow: "auto" }}
      >
        {items.map((chargerItem) => (
          <ListItem key={chargerItem.model}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  name={chargerItem.model}
                  inputProps={{ form: formID }} // determines which form to submit to
                />
              }
              label={
                <>
                  {chargerItem.rating}&nbsp;
                  {unitWrapper("kW")}
                </>
              }
            />
          </ListItem>
        ))}
      </List>

      {formID === "DeSelectChargersForm" &&
        items.map((charger) => (
          // if the items are part of the SelectedChargers list, then include a hidden input so that all items in that list are in the form that encompasses the entire dialog box's submit
          <input
            key={`${charger.model}_selected`}
            name={charger.model}
            form="ChargerOptionsForm" // this form ID also appears in the batterySizing.js Make sure it matches if it is changed
            hidden
          />
        ))}
    </Card>
  );

  return (
    <Grid container spacing={3} justifyContent="center">
      <form id="SelectChargersForm" onSubmit={handleCheckedRight} />
      <form id="DeSelectChargersForm" onSubmit={handleCheckedLeft} />
      <Grid item id="unselected-charger-options">
        <Grid container spacing={3}>
          <Grid item>
            {customList("AC", AC_Chargers, "SelectChargersForm")}
          </Grid>
          <Grid item>
            {customList("DC", DC_Chargers, "SelectChargersForm")}
          </Grid>
        </Grid>
      </Grid>
      <Grid item mt={"65px"}>
        <Grid container direction="column" id="swap-charger-options">
          <Grid item pb={"10px"}>
            <Button
              variant="outlined"
              type="submit"
              form="SelectChargersForm"
              aria-label="move selected right"
            >
              &gt;
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="submit"
              form="DeSelectChargersForm"
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item id="selected-charger-options">
        {customList("Selected", newSelectedChargers, "DeSelectChargersForm")}
      </Grid>
    </Grid>
  );
}

export default ChargerOptions;
