const defaultBlocksInput = {
  hvac: { heating: true, cooling: true },
  weekend: { operate: 1, sat: 100, sun: 100 },
  start_date: "2023-01-01",
  end_date: "2023-12-31",
  resolution: 15,
};

const defaultRouteEnergyInput = {
  operating_year: 10,
  veh_count: 1,
};

const defaultFinancialParamInput = {
  interest: 0,
  discount: 0,
  depreciation: 0,
};

export {
  defaultBlocksInput,
  defaultFinancialParamInput,
  defaultRouteEnergyInput,
};

