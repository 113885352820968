import { CircularProgress, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Container from "@mui/material/Container";
import React from "react";
import { Line } from "react-chartjs-2";
import { unitTemperature } from "../../secondary/unitConversions";
import { stringCapitalize } from "../../utils";

export default function ClimateGraph({ chartData, chartUpdating }) {
  const {
    climate: { monthly_temperature },
    unit,
  } = chartData;

  const minimumTemperatureArr = monthly_temperature.map((el) =>
    unitTemperature(el.minimum_temperature, unit)
  );

  const maximumTemperatureArr = monthly_temperature.map((el) =>
    unitTemperature(el.maximum_temperature, unit)
  );

  const lowestTemperature = Math.min(...minimumTemperatureArr);

  const maximumTemperature = Math.max(...maximumTemperatureArr);

  /** @type {import("chart.js").ChartOptions} */
  const options = {
    responsive: true,
    // dragData: false,
    elements: { line: { tension: 0.175 } },
    plugins: {
      legend: { position: "top" },
      title: {
        display: true,
        text: `${chartData.name.toUpperCase()} DEPOT`,
        font: { size: 14 },
      },
      // dragData: {
      // TODO: This part shall be used to build the functionality of
      // drag and drop
      //   onDragEnd: (e, datasetIndex, index, value) => {
      //     // let rowValue =
      //     //   datasetIndex === 0 ? "maximum_temperature" : "minimum_temperature";
      //     // chartData.climate.monthly_temperature[index][rowValue] =
      //     //   parseFloat(value.toFixed(2));

      //     chartRef.current.valueUpdated = true;

      //     // console.log(chartData);

      //     // setChartData(chartData);
      //     // // forceUpdate();
      //   },
      // },
    },
    scales: {
      x: {
        title: { display: true, text: "Months", font: { size: 16 } },
      },
      //label the y-axis
      y: {
        min: lowestTemperature - 10,
        max: maximumTemperature + 10,
        title: {
          display: true,
          text: `Temperature (°${unit == 1 ? "C" : "F"})`,
          font: { size: 16 },
        },
      },
    },
  };

  const labels = monthly_temperature.map((el) => stringCapitalize(el.month));

  /** @type {import("chart.js").ChartData} */
  const data = {
    labels,
    datasets: [
      {
        label: "Average Maximum",
        data: maximumTemperatureArr,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgb(220,220,220, 0.5)",
        fill: "1",
        // cubicInterpolationMode: "monotone",
      },
      {
        label: " Average Minimum",
        data: minimumTemperatureArr,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        fill: false,
        // cubicInterpolationMode: "monotone",
      },
    ],
  };

  return (
    <>
      <div className="chartdiv">
        <Line options={options} data={data} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={chartUpdating}
        >
          <Container alignitems="center" justify="center" aligncontent="center">
            <Container align="center">
              <CircularProgress color="inherit" />
            </Container>
            <br />
            <Container align="center">
              <Typography variant="h5">
                <b>Editing the climate data</b>
              </Typography>
            </Container>
          </Container>
        </Backdrop>
      </div>
    </>
  );
}
