import Sort from "@mui/icons-material/Sort";
import Chip from "@mui/material/Chip";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useContext, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";

import { DataContext } from "../../../contexts/dataContext";
import { unitLargeAbbr } from "../../../static/constants/systems_of_measurement";
import { getUnits } from "../../secondary/unitConversions";
import { MFM_to_AMPM, roundNumber } from "../../utils";
import { VehicleModelDropdown } from "../commonComponents";

const stepSize = 180; //the time, in minutes, between each step

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function FleetSizingTimeTable({
  data,
  block_fleet_growth_diff = [],
}) {
  const units = getUnits();
  const [sort, setSort] = useState(0); //flag to determine unsorted (0), ascending sort (odd number), or descending sort (even number)
  const [sortAnchorEl, setSortAnchorEl] = useState(null); //used to position the dropdown for selecting sorts
  const [xAxisMax, setXAxisMax] = useState(1440);
  const [invalidBlockLookup, setInvalidBlockLookup] = useState({});
  const [selectedModel, setSelectedModel] = useState("all");
  const { logo } = useContext(DataContext);

  const chartRef = useRef(null);

  useEffect(() => {
    function fetchData() {
      let xAxisMax = 1440;

      if (block_fleet_growth_diff.length)
        xAxisMax =
          Math.ceil(
            block_fleet_growth_diff[block_fleet_growth_diff.length - 1].time /
              1440
          ) * 1440;

      const invalidBlockLookup = {};
      block_fleet_growth_diff.forEach((invalidBlock) => {
        if (invalidBlock.block in invalidBlockLookup)
          invalidBlockLookup[invalidBlock.block].push(invalidBlock.time);
        else invalidBlockLookup[invalidBlock.block] = [invalidBlock.time];
      });

      setXAxisMax(xAxisMax);
      setInvalidBlockLookup(invalidBlockLookup);
    }

    fetchData();
  }, [data, block_fleet_growth_diff]);

  const isOneDay = xAxisMax == 1440;

  const legend = {
    "Normal Operation": "blue",
    "Additional Vehicle Required": "red",
  };

  const sortOptions = [
    { label: "Start Time", order: "asc" },
    { label: "Start Time", order: "desc" },
    { label: "End Time", order: "asc" },
    { label: "End Time", order: "desc" },
    { label: "Total Time", order: "asc" },
    { label: "Total Time", order: "desc" },
  ];

  /** opens the sort selection dropdown */
  const handleOpenSortMenu = (e) => setSortAnchorEl(e.currentTarget);
  /** closes the sort selection dropdown */
  const handleCloseSortMenu = (e) => setSortAnchorEl(null);
  /** selects a sort and closes the the dropdown */
  const handleSortSelect = (e) => {
    setSort(e.currentTarget.value);
    setSortAnchorEl(null);
  };

  //NOTE: if there is ever more than 3 different sorting Algorithms (6 cases) below, consider moving sort functions into sortOptions object list
  switch (sort) {
    case 0: // start time, ascending
      data = data.sort(
        (entry1, entry2) => entry1.dh_st_time - entry2.dh_st_time
      );
      break;
    case 1: //start time, descending
      data = data.sort(
        (entry1, entry2) => entry2.dh_st_time - entry1.dh_st_time
      );
      break;
    case 2: //end time, ascending
      data = data.sort(
        (entry1, entry2) => entry1.dh_end_time - entry2.dh_end_time
      );
      break;
    case 3: //end time, descending
      data = data.sort(
        (entry1, entry2) => entry2.dh_end_time - entry1.dh_end_time
      );
      break;
    case 4: //total time, ascending
      data = data.sort(
        (entry1, entry2) =>
          entry1.dh_end_time -
          entry1.dh_st_time -
          (entry2.dh_end_time - entry2.dh_st_time)
      );
      break;
    case 5: //total time, descending
      data = data.sort(
        (entry1, entry2) =>
          entry2.dh_end_time -
          entry2.dh_st_time -
          (entry1.dh_end_time - entry1.dh_st_time)
      );
      break;
    default: //unsorted (sort by ID) UNUSED
      data = data.sort((entry1, entry2) => entry1.id - entry2.id);
      break;
  }

  const timeRange = data.map((block) => block.dh_end_time - block.dh_st_time);

  //computes the min/max timeRanges, for use in determining range of color of bars
  let max = Math.max(...timeRange);
  let min = Math.min(...timeRange);

  max = max - min ? max - min : 1;
  //max variable is replaced with the total range of distances; if range is 0 (max==min),
  //then set range to 1 (to avoid dividing by 0)
  const colors = {};
  data
    .map(({ dh_end_time, dh_st_time, blockId }) => ({
      blockId,
      colorValue:
        200 - Math.floor(200 * ((dh_end_time - dh_st_time - min) / max)),
    }))
    .forEach(({ blockId, colorValue }) => {
      colors[blockId] = `rgba(${colorValue},${colorValue},255)`;
    });

  /** @type {import("chart.js").ChartOptions} */
  const chartOptions = {
    indexAxis: "y",
    interaction: { intersect: false, mode: "nearest" },
    scales: {
      x: {
        ticks: {
          font: { size: 16 },
          callback: (val) => {
            if (isOneDay)
              //has x-axis tick labels only appear once every 3 hours (180 minutes)
              return val % 180 === 0 ? MFM_to_AMPM(val) : null;
            else return val % 1440 === 0 ? daysOfWeek[val / 1440] : null; //display days of week if limit is greater than 24 hours
          },
          stepSize: isOneDay ? stepSize : 1440,
        },
        title: {
          display: true,
          text: isOneDay ? "Time of Day" : "Day of Week",
          font: { size: 16 },
        }, //label for x-axis
        max: xAxisMax,
        min: 0,
        stacked: true,
      },
      y: {
        ticks: { display: false },
        title: { display: true, text: "Blocks", font: { size: 16 } },
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: { filter: (label) => label.text in legend },
        onClick: () => null,
      },
      tooltip: {
        position: "nearest",
        callbacks: {
          title: (value) => `Block: ${value[0].label}`,
          label: (value) => [
            `${MFM_to_AMPM(value.raw.x[0])} - ${MFM_to_AMPM(value.raw.x[1])}`,
            `${roundNumber(value.raw.x[2])} ${unitLargeAbbr[units]}`,
          ],
        },
      },
      title: {
        display: true,
        text: "Additional Vehicle Requirements for Battery Electric vs. Conventional Operation",
        font: { size: 19 },
      },
      zoom: {
        pan: { enabled: true, mode: "y", modifierKey: "ctrl" },
        zoom: { drag: { enabled: true }, mode: "y" },
      },
    },
  };

  //data to be displayed on chart
  const slicedData = data.filter(
    (row) => selectedModel == "all" || selectedModel == row.vehicleModel
  );

  const data_segments = [];

  //construct dataset
  for (const index in slicedData) {
    const row_data = slicedData[index];
    const block_id = row_data.blockId;
    for (const profileIndex in row_data.profile) {
      const { start_time, end_time, distance } = row_data.profile[profileIndex];
      data_segments.push({
        x: [start_time, end_time, distance],
        y: block_id,
      });
    }
  }

  /** @type {import("chart.js").ChartData} */
  const chartData = {
    labels: slicedData.map((block) => block.blockId),
    datasets: [
      ...Object.entries(legend).map(([label, color]) => ({
        label,
        data: [],
        backgroundColor: color,
      })),
      {
        label: "Time on Route",
        data: data_segments,
        borderRadius: 5, //curves the bars
        borderSkipped: false, //curve affects both sides
        // borderWidth: 5,
        // inflateAmount: 5,
        // backgroundColor: "rgba(53, 162, 235)", //for a single, blueish color
        backgroundColor: (context) => {
          const blockId = context.raw.y;
          if (
            blockId in invalidBlockLookup &&
            invalidBlockLookup[blockId].some(
              (invalidTime) => context.raw.x[0] == invalidTime
            )
          )
            return "red";
          return colors[blockId];
        }, //for a range of colors based around a given value
        // backgroundColor: colors, // for a direct mapping of colors
      },
    ],
  };

  return (
    <div className="chartdiv">
      <Stack
        sx={{ justifyContent: "space-between", margin: "1%" }}
        direction="row"
        spacing={1}
      >
        <VehicleModelDropdown
          data={data}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
        />
        <span>
          <Chip
            sx={{ mx: 1 }}
            label={sortOptions[sort].label}
            icon={
              <Sort
                className={
                  sortOptions[sort].order === "asc" ? "rotate-icon-3" : ""
                }
              />
            }
            onClick={handleOpenSortMenu}
          />
          <Menu
            open={Boolean(sortAnchorEl)}
            anchorEl={sortAnchorEl}
            onClose={handleCloseSortMenu}
            PaperProps={{
              style: { minWidth: sortAnchorEl?.clientWidth }, // makes the dropdown the same size as the chip
              className: "btn",
            }}
          >
            {sortOptions.map((value, index) => (
              <MenuItem
                key={`${value.label}_${value.order}`}
                value={index}
                onClick={handleSortSelect}
              >
                <ListItemIcon>
                  <Sort
                    className={value.order === "asc" ? "rotate-icon-3" : ""}
                  />
                </ListItemIcon>
                {value.label}
              </MenuItem>
            ))}
          </Menu>

          <Chip
            label="Reset Zoom"
            onClick={() => chartRef?.current?.resetZoom()}
          />
        </span>
      </Stack>
      <Bar
        style={{ padding: "1%" }}
        ref={chartRef}
        options={chartOptions}
        data={chartData}
      />
      <div
        style={{ right: "calc(35px + 1%)", bottom: "50px" }}
        className="watermark-container"
      >
        <img src={logo} width="auto" height="50px" className="watermark-img" />
      </div>
    </div>
  );
}
