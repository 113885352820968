import MaterialTable from "@material-table/core";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import { unitMassMap } from "../../../static/constants/systems_of_measurement";
import { getUnits, unitTon } from "../../secondary/unitConversions";
import { Icons, numberUS, roundNumber } from "../../utils";
import { TogglePerVehicleComponent } from "./tcoTable";

/**
 *
 * @param {Object} props
 * @param {Object} props.tcoData
 * @param {Object} props.fleetSizes For the managed/unmanaged transformer Capacity
 * @param {String} props.className additional classes to attach to the Paper compoennt
 * @param {"ICE"|"GAS"|"PROP"|"CNG"|undefined} props.selectedFuel state shared with tcoGraph Component
 * @param {Boolean} props.isPerVehicle state shared with tcoGraph Component
 * @param {import("react").Dispatch<import("react").SetStateAction<Boolean>>} props.setIsPerVehicle
 */
export default function TCOTableEmissions({
  tcoData: tableData,
  fleetSizes,
  className,
  isPerVehicle,
  setIsPerVehicle,
  selectedFuel,
}) {
  const units = getUnits();
  const denominatorBEV = isPerVehicle ? fleetSizes.total_ev_fleet_size : 1;
  const denominatorICE = isPerVehicle ? fleetSizes.total_ice_fleet_size : 1;

  //list of all non-BEV fuel types
  const fuel_types = Object.keys(tableData).filter(
    (i) => i in TYPE_STRINGS.FUEL_TYPE
  );

  /** @type {import("@material-table/core").Column<never>[]} */
  const columns = [
    { title: "Category", field: "category" },
    { title: "Subcategory", field: "subCategory" },
    { title: `Electric Vehicle${isPerVehicle ? "" : "s"}`, field: "ev" },
    {
      title: `${TYPE_STRINGS.FUEL_TYPE[selectedFuel]} Vehicle${
        isPerVehicle ? "" : "s"
      }`,
      field: selectedFuel,
    },
  ];

  const fuelDataHelper = (displayFunction) =>
    fuel_types.reduce(
      (obj, fuel) => ({ ...obj, [fuel]: displayFunction(fuel) }),
      {}
    );

  /**
   * computes the data for the material table, allowing for users to switch between per vehicle and all vehicle view
   * @param {Number} denominatorBEV
   * @param {Number} denominatorICE
   */
  const data = (denominatorBEV, denominatorICE) => [
    {
      category: "Grid Emissions",
      rowSpan: 1,
      subcategory: [
        {
          name: (
            <>
              CO<sub>2</sub>e ({unitMassMap[units]}/yr)
            </>
          ),
          ev: !isNaN(tableData.BEV.grid_emissions.CO2)
            ? numberUS.format(
                roundNumber(
                  unitTon(tableData.BEV.grid_emissions.CO2 / denominatorBEV),
                  1
                )
              )
            : "N/A",
          ...fuelDataHelper(() => "0.0"),
        },
        {
          name: (
            <>
              NO<sub>X</sub> ({unitMassMap[units]}/yr)
            </>
          ),
          ev: !isNaN(tableData.BEV.grid_emissions.NOX)
            ? numberUS.format(
                roundNumber(
                  unitTon(tableData.BEV.grid_emissions.NOX / denominatorBEV),
                  1
                )
              )
            : "N/A",
          ...fuelDataHelper(() => "0.0"),
        },
        {
          name: (
            <>
              SO<sub>2</sub> ({unitMassMap[units]}/yr)
            </>
          ),
          ev: !isNaN(tableData.BEV.grid_emissions.SO2)
            ? numberUS.format(
                roundNumber(
                  unitTon(tableData.BEV.grid_emissions.SO2 / denominatorBEV),
                  1
                )
              )
            : "N/A",
          ...fuelDataHelper(() => "0.0"),
        },
      ],
    },
    {
      category: "Tailpipe Emissions",
      rowSpan: 1,
      subcategory: [
        {
          name: (
            <>
              CO<sub>2</sub>e ({unitMassMap[units]}/yr)
            </>
          ),
          ev: "0.0",
          ...fuelDataHelper((fuel) =>
            !isNaN(tableData[fuel]?.grid_emissions?.CO2)
              ? numberUS.format(
                  roundNumber(
                    unitTon(
                      tableData[fuel]?.grid_emissions?.CO2 / denominatorICE
                    ),
                    1
                  )
                )
              : "N/A"
          ),
        },
        {
          name: (
            <>
              NO<sub>X</sub> ({unitMassMap[units]}/yr)
            </>
          ),
          ev: "0.0",
          ...fuelDataHelper((fuel) =>
            !isNaN(tableData[fuel]?.grid_emissions?.NOX)
              ? numberUS.format(
                  roundNumber(
                    unitTon(
                      tableData[fuel]?.grid_emissions?.NOX / denominatorICE
                    ),
                    1
                  )
                )
              : "N/A"
          ),
        },
        {
          name: (
            <>
              SO<sub>2</sub> ({unitMassMap[units]}/yr)
            </>
          ),
          ev: "0.0",
          ...fuelDataHelper((fuel) =>
            !isNaN(tableData[fuel]?.grid_emissions?.SO2)
              ? numberUS.format(
                  roundNumber(
                    unitTon(
                      tableData[fuel]?.grid_emissions?.SO2 / denominatorICE
                    ),
                    1
                  )
                )
              : "N/A"
          ),
        },
      ],
    },
  ];

  return (
    <Container fixed maxWidth="xl">
      <MaterialTable
        title={"Emissions Summary"}
        columns={columns}
        icons={Icons()}
        data={data(denominatorBEV, denominatorICE)}
        actions={[
          {
            icon: () => (
              <TogglePerVehicleComponent isPerVehicle={isPerVehicle} />
            ),
            tooltip: "Toggle Per Vehicle View",
            isFreeAction: true,
            onClick: () => setIsPerVehicle(!isPerVehicle),
          },
        ]}
        components={{
          Row: ({ data, index: rowIndex }) => (
            <>
              {/* break line between groups of tableRows */}
              <TableRow className="no-print">
                <TableCell />
              </TableRow>
              {data.subcategory.map((val, index) => (
                <TableRow
                  className={
                    index === 0 && (rowIndex === 2 || rowIndex === 3)
                      ? "print-break"
                      : ""
                  }
                  key={index}
                  sx={{
                    backgroundColor:
                      data.tableData.id % 2 == 0 ? "#b6c8f0" : "#dce6fc",
                    height: "100%",
                  }}
                >
                  {index == 0 && (
                    <TableCell
                      rowSpan={data.subcategory.length}
                      style={{ fontSize: "20px", fontWeight: "600" }}
                    >
                      {data.category}
                    </TableCell>
                  )}
                  {[val.name, val.ev, val[selectedFuel]].map((item, index) => (
                    <TableCell
                      key={index}
                      style={{ fontSize: "15px", fontWeight: "400" }}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          ),
          Container: (containerProps) => (
            <Paper
              {...containerProps}
              className={className}
              sx={{
                width: "100%",
                overflow: "hidden",
                backgroundColor: "#FCFCFC",
              }}
              elevation={3}
            />
          ),
        }}
        options={{
          headerStyle: {
            backgroundColor: "#FCFCFC",
            fontWeight: "600",
            fontSize: "20px",
          },
          //set upper limit of rows to 10 (only needs 6 rows, but material table must be 5, 10, or 20 per page)
          pageSize: 10,
          //hide pagination footer
          paging: false,
          //hides the search bar
          search: false,
          //disables the sorting by columns functionality
          maxColumnSort: 0,
          //disables the ability to drag columns around
          draggable: false,
          //prevents the table from changing layout when switching between All/Per vehicles
          tableLayout: "fixed",
        }}
      />
    </Container>
  );
}
