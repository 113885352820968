import { Chip, Stack, Typography } from "@mui/material";
import { useContext, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

import { DataContext } from "../../../contexts/dataContext";
import { MFM_to_AMPM } from "../../utils";

/**
 *
 * @param {Object} props
 * @param {Object} props.data
 * @param {Number[]} props.data.full_power array of unmanaged charging values, one value for every minute in the day
 * @param {Number[]} props.data.low_power array of unmanaged charging values, one value for every minute in the day
 * @returns {JSX} Energy Analysis Line Chart
 */
export default function EnergyAnalysisChart(props) {
  const chartRef = useRef(null);
  const { logo } = useContext(DataContext);
  const [showBlockCharging, setShowBlockCharging] = useState(true); // Track visibility of Block Charging

  const {
    data: { full_power, low_power },
  } = props;

  const fullPowerLineData = full_power.concat([full_power[0]]);
  const lowPowerLineData = low_power.concat([low_power[0]]);

  const xAxis = Array.from(Array(1441).keys()).map(MFM_to_AMPM); //the ticks on the x-axis, 1441 ticks, 1 for every minute of the day + 1 for midnight (equal in length to data Arrays)

  return (
    <div className="chartdiv">
      <Stack
        sx={{ justifyContent: "space-between", margin: "1%" }}
        direction="row"
        spacing={1}
      >
        <Typography variant="h6">Load Profile</Typography>
        <Chip
          label="Reset Zoom"
          onClick={() => chartRef?.current?.resetZoom()}
        />
      </Stack>
      <Line
        ref={chartRef}
        data={{
          labels: xAxis,
          datasets: [
            {
              label: "Unmanaged Charging Scenario", //label for the dataset (line)
              data: fullPowerLineData, //the line's data + midnight
              borderColor: "#022F40", //color of the line
              backgroundColor: (context) => {
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 500); //creates a gradient color transition for the line's background
                gradient.addColorStop(0.5, "#022F4080"); //color of the line's shadow (#80 == 50% transparent)
                gradient.addColorStop(1, "#ffffff00"); //transition color to white/clear
                return gradient;
              },
              pointRadius: 0, //the size of a data point's dot on the chart, made 0 to decrease width of line
              fill: true, //adds a shadow below the line
              cubicInterpolationMode: "monotone", //keeps the bar's corners from going too far
            },
            {
              label: "Managed Charging Scenario",
              data: lowPowerLineData,
              borderColor: "#38AECC",
              backgroundColor: (context) => {
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 500);
                gradient.addColorStop(0.5, "#ffffff00");
                gradient.addColorStop(1, "#38AECC80");
                return gradient;
              },
              pointRadius: 0,
              fill: true,
              cubicInterpolationMode: "monotone",
            },
            {
              label: "Charging Blocked",
              data: [],
              borderColor: "rgba(255, 99, 132, 0.6)",
              backgroundColor: "rgba(255, 99, 132, 0.25)",
              pointRadius: 0,
              tension: 0,
            },
          ],
        }}
        options={{
          interaction: { intersect: false, mode: "index" }, //has the tooltip appear when mouse is anywhere on map, instead of only above specific points
          animations: false,
          scales: {
            x: {
              ticks: {
                font: { size: 16 },
                callback: (val) =>
                  //has x-axis tick labels only appear once every 3 hours (180 minutes)
                  val % 180 === 0 ? MFM_to_AMPM(val) : null,
              },
              title: { display: true, text: "Time of Day", font: { size: 16 } }, //label for x-axis
            },
            y: {
              ticks: { font: { size: 16 } },
              title: {
                display: true,
                text: ["Power", "(kW)"],
                font: { size: 16 },
              },
            },
          },
          plugins: {
            tooltip: {
              position: "nearest", //has the tooltip appear at the point on a line closest to mouse
              callbacks: {
                title: (value) => `Time: ${value[0].label}`, //custom tooltip title to display time
                label: (value) =>
                  `${value.dataset.label}: ${value.formattedValue} kW`, //customizes tooltip display for y-axis value
              },
            },
            legend: {
              position: "top",
              onClick: (e, legendItem) => {
                const index = legendItem.datasetIndex;
                const ci = chartRef.current;
                const meta = ci.getDatasetMeta(index);

                if (index === 2) {
                  // Assuming Block Charging is the third dataset
                  meta.hidden =
                    meta.hidden === null
                      ? !ci.data.datasets[index].hidden
                      : null;
                  setShowBlockCharging(
                    meta.hidden === null
                      ? !ci.data.datasets[index].hidden
                      : false
                  );
                } else {
                  meta.hidden =
                    meta.hidden === null
                      ? !ci.data.datasets[index].hidden
                      : null;
                }
                ci.update();
              },
            },
            zoom: {
              pan: { enabled: true, mode: "x", modifierKey: "ctrl" },
              zoom: { drag: { enabled: true }, mode: "x" },
            },
            annotation: {
              annotations: showBlockCharging ? props.annotations : {},
            },
          },
        }}
      />
      <div
        style={{ bottom: "45px", right: "25px" }}
        className="watermark-container"
      >
        <img src={logo} width="auto" height="50px" className="watermark-img" />
      </div>
    </div>
  );
}
