import { useContext, useEffect, useMemo, useRef } from "react";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import { ReverseNominatim } from "../../utils";

/**
 * Function displaying the map
 * @param {Object} props
 * @param {Object} props.pinCoords the rowUpdateData
 * @param {Number} [props.pinCoords.latitude]
 * @param {Number} [props.pinCoords.longitude]
 * @param {String} [props.pinCoords.country] - used to tell if the pin was dropped into a different country, to fetch the new state list from the backend
 * @param {setState} props.setPinCoords the setRowUpdateData
 * @param {setState} props.setLoading
 * @return {React Component}
 */
export default function InputMap(props) {
  const { pinCoords } = props;

  return (
    //makes the map
    <MapContainer
      center={{ lat: pinCoords.latitude || 0, lng: pinCoords.longitude || 0 }}
      zoom={isNaN(pinCoords.latitude) || isNaN(pinCoords.longitude) ? 1 : 18}
      style={{ borderRadius: "2%", maxHeight: "560px" }}
      maxBounds={[
        [-90, -180],
        [90, 180],
      ]}
      minZoom={1}
    >
      <TileLayer
        // attribution text
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <DraggablePin props={props} />
    </MapContainer>
  );
}

/**
 * The Pin that is used to select lat/lon coordinates (also zooms map to pin when going from address to map)
 * @param {Object} mapProps
 * @param {Object} mapProps.props
 * @param {Object} mapProps.props.pinCoords the rowUpdateData
 * @param {Number} [mapProps.props.pinCoords.latitude]
 * @param {Number} [mapProps.props.pinCoords.longitude]
 * @param {setState} mapProps.props.setPinCoords the setRowUpdateData
 * @param {setState} mapProps.props.setLoading
 * @return {React Component}
 */
function DraggablePin(mapProps) {
  const {
    props: { pinCoords, setPinCoords, setLoading },
  } = mapProps;
  const pinRef = useRef(null); //used to obtain the pin's location when done dragging
  const map = useMap();

  const { snackBarElement } = useContext(SnackBarContext);

  /**
   * fires once, on map init
   */
  useEffect(() => {
    //if the lat/long are predefined, do nothing (as the map should center on the pinned location, for edits)
    if (isNaN(pinCoords.latitude) || isNaN(pinCoords.longitude)) {
      //if no pre-defined center point is found, try to move the map to the user's location
      ///if that fails too, then it'll default to the center of the world (latLong= 0,0)
      map.locate().on("locationfound", (location) => {
        map.flyTo(location.latlng, 8);
      });
    }
  }, []);

  /**
   * fires when address is entered, and the "find on Map" button is pressed
   */
  useEffect(() => {
    if (pinCoords.bounds) {
      map.fitBounds(pinCoords.bounds);
    }
  }, [pinCoords.bounds]);

  /**
   * converts a latitude/longitude value into an address, and stores it in rowUpdateData
   * latitude and longitude are (generally) contained in the rowUpdateData (passed in as a variable, to allow the function to be moved to other components more easily)
   * @param {Number} lat latitude
   * @param {Number} lon longitude
   */
  const mapToAddress = async (lat, lon) => {
    try {
      setLoading(true);
      setPinCoords((vals) => ({ ...vals, latitude: lat, longitude: lon }));
      const address = await ReverseNominatim(lat, lon);
      if (address.error) {
        snackBarElement.current.displayToast(
          "Unable to Find Address For Pinned Location",
          "error",
          5000
        );
      } else {
        setPinCoords((val) => ({
          ...val,
          address1: `${address.house_number || ""} ${
            address.road || ""
          }`.trim(),
          address2: "",
          city: address.city || address.town || address.county, //sometimes returns an address, sometimes returns a city
          state_code: address.state_code,
          country_code: address.country_code,
          zipcode: address.postcode,
        }));
        if (
          !(
            //if any field is missing
            (
              (`${address.house_number || ""} ${address.road || ""}`.trim() &&
                address.city) ||
              address.town ||
              (address.county && //sometimes returns an address&& sometimes returns a city
                address.state &&
                address.country &&
                address.postcode)
            )
          )
        ) {
          //if any of the fields are missing/erroneous
          console.log("address", address);
          snackBarElement.current.displayToast(
            "Unable to Find Exact Address For Pinned Location, Please Fill in Missing Details",
            "warning",
            5000
          );
        }
      }
    } catch (e) {
      console.log(e);
      snackBarElement.current.displayToast(
        "Unable to Find Address For Pinned Location",
        "error",
        5000
      );
    }
  };

  /**
   * fires when pin is manually moved
   */
  const eventHandlers = useMemo(() => {
    return {
      async dragend() {
        const droppedPin = pinRef.current;
        if (droppedPin) {
          const latlng = droppedPin.getLatLng().wrap();
          await mapToAddress(latlng.lat, latlng.lng);
          setLoading(false);
        }
      },
    };
  }, []);

  return isNaN(pinCoords.latitude) || isNaN(pinCoords.longitude) ? (
    <></>
  ) : (
    <Marker
      draggable
      ref={pinRef}
      position={{ lat: pinCoords.latitude, lng: pinCoords.longitude }}
      eventHandlers={eventHandlers}
    />
  );
}
