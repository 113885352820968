import { Chip, Container, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";

import { DataContext } from "../../../contexts/dataContext";
import {
  currencyFormat,
  currencySymbol,
  unitMoney,
} from "../../secondary/unitConversions";
import { TogglePerVehicleComponent } from "../tables/tcoTable";

/**
 *
 * @param {Object} props
 * @param {Array} props.tcoData
 * @param {Object} props.fleetSizes For the managed/unmanaged transformer Capacity
 * @param {"ICE"|"GAS"|"PROP"|"CNG"|undefined} props.selectedFuel state shared with tcoTable Component
 * @param {Boolean} props.isPerVehicle state shared with tcoTable Component
 * @param {import("react").Dispatch<import("react").SetStateAction<Boolean>>} props.setIsPerVehicle
 */
export function TCOGraph({
  tcoData: graphData,
  fleetSizes,
  isPerVehicle,
  setIsPerVehicle,
  selectedFuel,
}) {
  const { logo } = useContext(DataContext);

  const chartRef = useRef(null);

  const denominatorBEV = isPerVehicle ? fleetSizes.total_ev_fleet_size : 1;
  const denominatorICE = isPerVehicle ? fleetSizes.total_ice_fleet_size : 1;

  useEffect(() => {
    //forcibly resets the zoom when generating pdf
    function resetZoomOnPrint() {
      chartRef?.current?.resetZoom();
    }

    window.addEventListener("beforeprint", resetZoomOnPrint);

    return function cleanupListener() {
      window.removeEventListener("beforeprint", resetZoomOnPrint);
    };
  }, [chartRef]);

  /** @type {import("chart.js").ChartOptions} */
  const options = {
    responsive: true,
    interaction: { intersect: false, mode: "index" },
    plugins: {
      legend: { position: "bottom" },
      title: {
        font: { size: 16 },
        display: true,
        text: `BEV vs ${selectedFuel}`,
      },
      tooltip: {
        callbacks: {
          title: (column) => `Year ${column[0]?.label}`,
          //label callback code modified from: https://www.chartjs.org/docs/latest/configuration/tooltip.html#label-callback
          label: (context) => {
            let label = context.dataset.label || "";
            if (label) label += ": ";
            if (context.parsed.y !== null)
              label += currencyFormat(context.parsed.y);
            return label;
          },
        },
      },
      zoom: {
        pan: { enabled: true, mode: "x", modifierKey: "ctrl" },
        zoom: { drag: { enabled: true }, mode: "x" },
      },
    },
    scales: {
      x: {
        title: { font: { size: 16 }, display: true, text: "YEAR - (Lifetime)" },
      },
      y: {
        ticks: { font: { size: 16 } },
        title: {
          font: { size: 16 },
          display: true,
          text: `COST - (${currencySymbol()})`,
        },
      },
    },
    animation: false, // disabled, as the pdf generation didn't wait for the animation to finish
  };
  const labels = [...Array(graphData.BEV.tco_list.length).keys()];

  /** @type {import("chart.js").ChartData} */
  const data = {
    labels,
    datasets: [
      {
        type: "line",
        label: "BEV TCO",
        data: graphData.BEV.tco_list.map((i) => unitMoney(i / denominatorBEV)),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        type: "line",
        label: `${selectedFuel} TCO`,
        data: graphData[selectedFuel]?.tco_list?.map((i) =>
          unitMoney(i / denominatorICE)
        ),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        type: "bar",
        label: "BEV OPEX",
        data: graphData.BEV.opex_list.map((i) => unitMoney(i / denominatorBEV)),
        borderColor: "rgb(102, 187, 106)",
        backgroundColor: "rgba(129, 199, 132, 0.5)",
      },
      {
        type: "bar",
        label: `${selectedFuel} OPEX`,
        data: graphData[selectedFuel]?.opex_list?.map((i) =>
          unitMoney(i / denominatorICE)
        ),
        borderColor: "rgb(171, 71, 188)",
        backgroundColor: "rgba(171, 71, 188, 0.5)",
      },
    ],
  };

  return (
    <Paper
      className="chartdiv"
      sx={{ width: "100%", overflow: "hidden", backgroundColor: "#FCFCFC" }}
    >
      <Box m={3}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          fontWeight={500}
          mr="1%"
        >
          Financial Summary
          <span style={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => setIsPerVehicle(!isPerVehicle)}>
              <TogglePerVehicleComponent isPerVehicle={isPerVehicle} />
            </IconButton>
            <Chip
              label="Reset Zoom"
              onClick={() => chartRef?.current?.resetZoom()}
            />
          </span>
        </Typography>
        <Container className="no-print" sx={{ textAlign: "left" }}></Container>
        <Line ref={chartRef} options={options} data={data} />
      </Box>
      <div
        style={{ bottom: "100px", right: "26px" }}
        className="watermark-container"
      >
        <img src={logo} width="auto" height="50px" className="watermark-img" />
      </div>
    </Paper>
  );
}
