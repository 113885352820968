import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Skeleton,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../contexts/dataContext";
import {
  defaultBlocksInput,
  defaultFinancialParamInput,
  defaultRouteEnergyInput,
} from "../../../static/constants/defaultInputs";
import {
  MFM_to_AMPM,
  getLocalData,
  roundNumber,
  storeLocalData,
} from "../../utils";

//note: uses numbers as key instead of label, since the original format is an object of booleans
// and objects are not valid values for a dropdown.
//  By interpreting the object's booleans as a base-2 value, we can use a number for lookups
export const hvacOptions = {
  3: {
    label: "Heating and Cooling",
    hvac: { heating: true, cooling: true },
  },
  2: {
    label: "Heating Only",
    hvac: { heating: true, cooling: false },
  },
  1: {
    label: "Cooling Only",
    hvac: { heating: false, cooling: true },
  },
  0: {
    label: "No HVAC",
    hvac: { heating: false, cooling: false },
  },
};

/**
 * formats the input data, saves it to indexDb, and returns the formatted
 * data (to be used in setState). Currently, only works for blocks, routeEnergy, and financial.
 * @param {("blocks"|"routeEnergy"|"financial")} key the indexDb table key
 * @param {React.FormEvent<HTMLDivElement>} event the form event containing the inputs to be saved
 */
export function updateIndexDb(key, event) {
  const newDataForm = new FormData(event.target);
  const input = Object.fromEntries(newDataForm.entries()); // in the event that this function is used for another key, it should still work, as long as the input is correctly formatted
  let formattedInput = input;

  switch (key) {
    case "blocks":
      // note: blocks key can ONLY be used with this function in the EditAnalysisInputsForm
      //  this is due to the hvac not existing in inputs at the same time as the other pages
      formattedInput = {
        weekend: {
          operate: input.weekendOperation === "on" ? 1 : 0,
          sat: input?.sat ? +input.sat : 0,
          sun: input?.sun ? +input.sun : 0,
        },
        start_date: input.start_date,
        end_date: input.end_date,
        resolution: +input.resolution,
        hvac: hvacOptions[input.hvacKey].hvac,
      };
      break;
    case "routeEnergy":
      formattedInput = {
        operating_year: +input.operating_year,
        veh_count: +input.veh_count,
      };
      break;
    case "battery":
      formattedInput = {};

      if (input.block_charging) {
        //the block charger sliders handling (multiple values assigned to a single name)
        const block_charging = newDataForm.getAll("block_charging");
        block_charging.sort((a, b) => a - b); //note: block_charging values must not overlap

        //combine block_charging values into a list of groups of 2 values
        formattedInput.block_charging = new Array(block_charging.length / 2)
          .fill(0)
          .map((_v, i) => ({
            start: block_charging[i * 2] * 60, //multiply by 60 to get MFM equivalent value
            end: block_charging[i * 2 + 1] * 60,
          }));
      }

      if (input.battery_precon_limits)
        formattedInput.battery_precon_limits = [
          { start: 0, end: 30, duration: 5, power: 30 },
          { start: 30, end: 120, duration: 10, power: 30 },
          { start: 120, end: 240, duration: 20, power: 30 },
          { start: 240, end: 1440, duration: 30, power: 30 },
        ];
      if (input.hvac_precon_limits)
        formattedInput.hvac_precon_limits = [
          { start: 0, end: 30, duration: 0, power: 0 },
          { start: 30, end: 120, duration: 5, power: 10 },
          { start: 120, end: 240, duration: 60, power: 5 },
          { start: 240, end: 1440, duration: 60, power: 10 },
        ];

      if (input.pullin_limits !== "")
        formattedInput.pullin_limits = [
          { start: 0, end: 1440, duration: input.pullin_limits },
        ];

      if (input.pullout_limits !== "")
        formattedInput.pullout_limits = [
          { start: 0, end: 1440, duration: input.pullout_limits },
        ];

      if (input.preproc_duration)
        formattedInput.preproc_limits = [
          { start: 0, end: input.preproc_min, duration: 0 },
          {
            start: input.preproc_min,
            end: 1440,
            duration: input.preproc_duration,
          },
        ];

      if (input.postproc_duration)
        formattedInput.postproc_limits = [
          { start: 0, end: input.postproc_min, duration: 0 },
          {
            start: input.postproc_min,
            end: 1440,
            duration: input.postproc_duration,
          },
        ];
      if (input.charging_style)
        formattedInput.charging_style = input.charging_style;

      formattedInput = {
        charger_models: JSON.parse(input.charger_models), //keeps the non-limit related inputs, if there are any, in play
        settings: { bev_settings: { ...formattedInput } },
      };

      break;
    // temporarily removed this functionality
    // case "financial":
    //   formattedInput = {
    //     interest: +input.interestRate / 100,
    //     discount: +input.discountRate / 100,
    //     depreciation: 0, //currently set as constant
    //   };
    //   break;
  }
  // overwrites the old input with the new one, for use in backend computations
  storeLocalData(key, { input: formattedInput });
  return formattedInput;
}

export default function EditAnalysisInputsForm({ handleClose }) {
  //todo: combining input states like this is terrible practice, as any individual object's values might overwrite another's, if there are duplicate keys; consider making them all like project's
  const [data, setData] = useState({
    ...defaultRouteEnergyInput,
    ...defaultBlocksInput,
    ...defaultFinancialParamInput,
    project: { type: undefined }, //currently, the only project field that matters is the type
    isLoading: true,
  });
  const [sim, setSim] = useState();
  const [initialAdvanced, setInitialAdvanced] = useState({});
  const { accessRights } = useContext(DataContext);

  useEffect(() => {
    /**
     * if the data exists in indexDb, populate the data with it
     */
    async function fetchData() {
      //note: since all the default update functions save changes to indexDb,
      // we can just fetch them from there (if they exist; if not, then just use the defaults)
      Promise.all(
        ["routeEnergy", "battery", "blocks", "financial"]
          .map((key) => getLocalData(key, "input").then(({ input }) => input))
          .concat(
            ["project"].map((key) =>
              getLocalData(key, "data").then(({ data }) => data)
            )
          )
      ).then(
        ([
          batterySizingInput,
          advancedOptionsInput,
          loadProfileInput,
          financialParamInput,
          projectData,
        ]) => {
          setInitialAdvanced({ ...advancedOptionsInput });
          setData((data) => ({
            ...data,
            ...batterySizingInput,
            ...loadProfileInput,
            ...financialParamInput,
            project: projectData,
            isLoading: false,
          }));
        }
      );
      const { data: sim } = await getLocalData("simulation", "data");
      setSim(sim);
    }

    fetchData();
  }, []);

  return (
    <>
      <DialogContent>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Route Energy Input
          </AccordionSummary>
          <AccordionDetails>
            {data?.isLoading ? (
              <Skeleton animation="wave" width="100%">
                <BlockInputsForm inputs={data} />
              </Skeleton>
            ) : (
              <BlockInputsForm inputs={data} />
            )}
          </AccordionDetails>
        </Accordion>
        <hr />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Battery Sizing Inputs
          </AccordionSummary>
          <AccordionDetails>
            {data?.isLoading ? (
              <Skeleton animation="wave" width="100%">
                <Grid container spacing={2}>
                  <RouteEnergyInputsForm
                    inputs={data}
                    accessRights={accessRights}
                  />
                </Grid>
              </Skeleton>
            ) : (
              <Grid container spacing={2}>
                <RouteEnergyInputsForm
                  inputs={data}
                  accessRights={accessRights}
                />
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
        {sim?.analysis_type_steps?.battery_sizing
          ?.fleet_and_charger_sizing_analysis?.[0] &&
          sim?.analysis_type_steps?.battery_sizing
            ?.fleet_and_charger_sizing_analysis?.[0] != "1" && (
            <>
              <hr />
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Advanced Options
                </AccordionSummary>
                <AccordionDetails>
                  {data?.isLoading ? (
                    <Skeleton animation="wave" width="100%">
                      <BatteryAdvancedOptions
                        inputs={initialAdvanced}
                        project={data.project}
                      />
                    </Skeleton>
                  ) : (
                    <BatteryAdvancedOptions
                      inputs={initialAdvanced}
                      project={data.project}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </>
          )}
        <hr />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Annual Load Profile Inputs
          </AccordionSummary>
          <AccordionDetails>
            {data?.isLoading ? (
              <Skeleton animation="wave" width="100%">
                <FormControlLabel
                  control={<Checkbox />}
                  label="Include Weekends"
                />
                <Grid container spacing={2}>
                  <AnnualLoadProfileInputs inputs={data} />
                </Grid>
              </Skeleton>
            ) : (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="weekendOperation"
                      defaultChecked={Boolean(data?.weekend?.operate)}
                    />
                  }
                  label="Include Weekends"
                />
                <Grid container spacing={2}>
                  <AnnualLoadProfileInputs inputs={data} />
                </Grid>
              </>
            )}
          </AccordionDetails>
        </Accordion>
        {/* <hr />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Financial and Emissions Inputs
          </AccordionSummary>
          <AccordionDetails>
            {data?.isLoading ? (
              <Skeleton animation="wave" width="100%">
                <Grid container spacing={2}>
                  <FinancialParamInputs
                    inputs={data}
                    accessRights={accessRights}
                  />
                </Grid>
              </Skeleton>
            ) : (
              <Grid container spacing={2}>
                <FinancialParamInputs
                  inputs={data}
                  accessRights={accessRights}
                />
              </Grid>
            )}
          </AccordionDetails>
        </Accordion> */}
      </DialogContent>
      <DialogActions>
        <DialogContentText>
          Changing settings will not update analyses until they are rerun.
        </DialogContentText>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" autoFocus>
          Save Changes
        </Button>
      </DialogActions>
    </>
  );
}

export const BlockInputsForm = ({ inputs }) => {
  let defaultValue = inputs?.hvac?.heating * 2 + inputs?.hvac?.cooling;
  if (isNaN(defaultValue)) defaultValue = 3;
  return (
    <TextField
      autoFocus
      variant="outlined"
      label="HVAC"
      name="hvacKey"
      required
      fullWidth
      select
      // convert the object of booleans into an equivalent numerical value (tt=3, tf=2, ft=1, ff=0)
      defaultValue={defaultValue}
    >
      {Object.entries(hvacOptions)
        .reverse()
        .map(([value, { label }]) => (
          <MenuItem key={label} value={value}>
            {label}
          </MenuItem>
        ))}
      <MenuItem disabled value={4}>
        Supplementary Heating
      </MenuItem>
    </TextField>
  );
};

export const RouteEnergyInputsForm = ({ inputs, accessRights }) => (
  <>
    <Grid item xs={12} sm={6} md={6}>
      <Tooltip title="Typical Vehicle Lifetime">
        <TextField
          autoFocus
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
          label="Vehicle Replacement Year"
          name="operating_year"
          required
          fullWidth
          defaultValue={inputs.operating_year}
          disabled={!accessRights.analysis.create_route_energy_analysis}
        />
      </Tooltip>
    </Grid>
    <Grid item xs={12} sm={6} md={6}>
      <Tooltip
        title={
          <>
            Battery Count Allowed
            <br />
            per Vehicle Type
          </>
        }
      >
        <TextField
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
          label="Vehicle Battery Count"
          name="veh_count"
          required
          fullWidth
          defaultValue={inputs.veh_count}
          disabled={!accessRights.analysis.create_route_energy_analysis}
        />
      </Tooltip>
    </Grid>
  </>
);

/**
 * The battery advanced options component
 * @param {Object} param0 object containing all pertinent initial data
 * @param {Object} param0.inputs the various default inputs
 * @param {Number|String} param0.project.type the project type, used to determine initial values if project is not provided
 */
export const BatteryAdvancedOptions = ({ inputs, project: { type } }) => {
  const [data, setData] = useState({}); ///data is only really used for validation here
  const [sliderValues, setSliderValues] = useState(
    inputs?.settings?.bev_settings?.block_charging?.map((val) => [
      Math.floor(val.start / 60),
      Math.floor(val.end / 60),
    ]) || []
  );
  const initialAdvancedOptions = inputs?.settings?.bev_settings || {};
  const charger_models = inputs?.charger_models;

  /** props applied to all textfield inputs in dialog boxes */
  const defaultTextFieldProps = {
    onChange: (e) => {
      if (e.target.value !== "")
        setData({ ...data, [e.target.name]: e.target.value });
      else setData({ ...data, [e.target.name]: undefined });
    },
    size: "small",
    type: "number",
    sx: { width: "5rem" },
    InputProps: {
      inputProps: { min: 1, max: 1399, sx: { py: 0, textAlign: "center" } },
    },
  };

  //todo: consider putting the below into a useMemo or useEffect that fires only on slider values changes
  //show mark (tick) every hour, display label every 6 hours
  const marks = Array(25)
    .fill(0)
    .map((_e, i) => ({
      value: i,
      label: i % 4 == 0 ? MFM_to_AMPM(i * 60) : null,
    }));

  //sorted values used for validating the slider
  const sortedSliderValues = JSON.parse(JSON.stringify(sliderValues)).sort(
    ([startA], [startB]) => startA - startB
  );

  let totalHours = 0;
  sortedSliderValues.forEach(([start, end]) => (totalHours += end - start));
  const isWarning = totalHours > 3;
  const errorIsTooLong = totalHours > 8; //prevents time ranges from being too long

  const errorIsOverlap =
    sliderValues.length &&
    (sortedSliderValues.some(
      ([start, end], i) =>
        (sortedSliderValues.length - 1 != i &&
          sortedSliderValues[i + 1][0] <= end) ||
        start == end //prevents overlapping rows or same start/endtime
    ) ||
      (sortedSliderValues[0][0] == 0 &&
        sortedSliderValues[sortedSliderValues.length - 1][1] == 24)); //prevents double-blocking end time

  return (
    <>
      {/* keep the non-limit battery inputs unmodified via the below hidden input */}
      <input
        name="charger_models"
        value={JSON.stringify(charger_models)}
        readOnly
        hidden
      />
      <Box display="flex">
        <Card sx={{ width: "50%", m: "5px" }}>
          <CardContent>
            <Typography
              display="flex"
              justifyContent="space-between"
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Battery Pre-Conditioning
              <Tooltip
                title={
                  <Typography>
                    <strong>Battery Pre-Conditioning</strong>: for fast-charging
                    events, batteries should be brought to the ideal temperature
                    range prior to charging. This may utilize existing battery
                    capacity prior to arrival when available or require time
                    while plugged in.
                  </Typography>
                }
              >
                <InfoIcon fontSize="small" />
              </Tooltip>
            </Typography>
            <FormControlLabel
              name="battery_precon_limits"
              control={
                <Checkbox
                  defaultChecked={Boolean(
                    initialAdvancedOptions.battery_precon_limits
                  )}
                />
              }
              label="Include"
              labelPlacement="start"
            />
          </CardContent>
        </Card>
        <Card sx={{ width: "50%", m: "5px" }}>
          <CardContent>
            <Typography
              display="flex"
              justifyContent="space-between"
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              HVAC Pre-Conditioning
              <Tooltip
                title={
                  <Typography>
                    <strong>Vehicle Pre-Conditioning</strong>: Prior to
                    scheduled departures a vehicle internal temperature may be
                    heated or cooled to improve passenger comfort along with
                    batteries to improve overall efficiency
                  </Typography>
                }
              >
                <InfoIcon fontSize="small" />
              </Tooltip>
            </Typography>
            <FormControlLabel
              name="hvac_precon_limits"
              control={
                <Checkbox
                  defaultChecked={Boolean(
                    initialAdvancedOptions.hvac_precon_limits
                  )}
                />
              }
              label="Include"
              labelPlacement="start"
            />
          </CardContent>
        </Card>
      </Box>
      {/* Note: maxWidth set to small to prevent glitching during on change when in subtitle pop-up */}
      <Card sx={{ maxWidth: "md", my: "5px" }}>
        <CardContent>
          <Typography
            display="flex"
            justifyContent="space-between"
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
          >
            Block Charging
            <Tooltip
              title={
                <Typography>
                  <strong>Block Charging</strong>: This setting allows you to
                  block specific time windows during which vehicles are not
                  permitted to charge. By setting these restrictions, you can
                  manage charging schedules more effectively and avoid conflicts
                  with other activities or high utility tariff rates.{" "}
                  <i>Warning: Blocking charging may increase fleet size.</i>
                </Typography>
              }
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Typography>
          {sliderValues.map((sliderValue, i) => (
            <Box
              key={`slider_${i}}`}
              display="flex"
              alignItems={i == sliderValues.length - 1 ? "start" : "center"}
            >
              <Slider
                name="block_charging"
                sx={{ mx: 2 }}
                value={sliderValue}
                min={0}
                max={24}
                marks={i == sliderValues.length - 1 ? marks : true}
                valueLabelDisplay="auto"
                valueLabelFormat={(x) => MFM_to_AMPM(x * 60)}
                onChange={(e) => {
                  setSliderValues((curr) => {
                    curr[i] = e.target.value;
                    return [...curr];
                  });
                }}
              />
              <Tooltip title="Remove Window">
                <IconButton
                  size="small"
                  onClick={() =>
                    setSliderValues((curr) => {
                      curr = curr
                        .slice(0, i)
                        .concat(curr.slice(i + 1, curr.length));
                      return [...curr];
                    })
                  }
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ))}
          <Box display="flex" justifyContent="space-between">
            <Button
              endIcon={<AddCircleOutlineIcon />}
              disabled={sliderValues.length >= 8} // if over 24 sliders, prevent user from adding more
              onClick={() =>
                setSliderValues((curr) => {
                  curr.push([10, 13]);
                  return [...curr];
                })
              }
            >
              Add Window
            </Button>
            {isWarning && !(errorIsOverlap || errorIsTooLong) && (
              <Alert severity="warning">
                Over 3 hours blocked, will likely increase fleet size
              </Alert>
            )}
            {(errorIsOverlap || errorIsTooLong) && (
              <>
                {errorIsOverlap && !errorIsTooLong && (
                  <Alert severity="error">Cannot have overlapping ranges</Alert>
                )}
                {errorIsTooLong && (
                  <Alert severity="error">
                    Cannot have over 8 hours blocked
                  </Alert>
                )}
                <input hidden={true} required={true} />
              </>
            )}
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ width: "100%", my: "5px" }}>
        <CardContent>
          <Typography
            display="flex"
            justifyContent="space-between"
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
          >
            Pre-Processing{" "}
            <Tooltip
              title={
                <Typography>
                  <strong>Pre-Processing</strong>: Consists of time (in minutes)
                  used for vehicle support activities that may not occur every
                  time a vehicle returns to the depot but will occur if a
                  vehicle is expected to be in the depot longer than a minimum
                  time (minutes), such as cleaning or maintenance. This will
                  reduce available charging time.
                </Typography>
              }
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Typography>
          <Box>
            Time required to complete activity:&nbsp;&nbsp;
            <Tooltip title="in minutes">
              <TextField
                {...defaultTextFieldProps}
                required={data.preproc_min !== undefined}
                defaultValue={
                  initialAdvancedOptions?.preproc_limits?.[1]?.duration || ""
                }
                InputProps={{ inputProps: { max: data.preproc_min } }}
                name="preproc_duration"
              />
            </Tooltip>
            <br />
            if in depot longer than&nbsp;&nbsp;
            <Tooltip title="in minutes">
              <TextField
                {...defaultTextFieldProps}
                required={data.preproc_duration !== undefined}
                defaultValue={
                  initialAdvancedOptions?.preproc_limits?.[0]?.end || ""
                }
                InputProps={{ inputProps: { min: data.preproc_duration } }}
                name="preproc_min"
              />
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
      <Box display="flex">
        <Card sx={{ width: "50%", m: "5px" }}>
          <CardContent>
            <Typography
              display="flex"
              justifyContent="space-between"
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Pull In
              <Tooltip
                title={
                  <Typography>
                    <strong>Pull In</strong>: The time required for vehicles to
                    reach and connect to a charger upon arrival at a depot,
                    including drive time to the charger within a depot and any
                    handoffs between drivers and other individuals before
                    charging. This would occur each time a vehicle arrives at
                    the depot and reduce available charging time.
                  </Typography>
                }
              >
                <InfoIcon fontSize="small" />
              </Tooltip>
            </Typography>
            Time Required:{" "}
            <TextField
              {...defaultTextFieldProps}
              defaultValue={
                initialAdvancedOptions?.pullin_limits?.[0]?.duration ||
                (type == "1" ? 5 : 2) // if Project_type is transit, default to 5, else 2
              }
              name="pullin_limits"
            />
          </CardContent>
        </Card>
        <Card sx={{ width: "50%", m: "5px" }}>
          <CardContent>
            <Typography
              display="flex"
              justifyContent="space-between"
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Pull Out
              <Tooltip
                title={
                  <Typography>
                    <strong>Pull Out</strong>: The time required for vehicles to
                    disconnect from a charger and leave a depot on assignment,
                    including drive time from the charger to the exit and any
                    handoffs between individuals and drivers before leaving.
                    This would occur each time a vehicle leaves the depot and
                    reduce available charging time.
                  </Typography>
                }
              >
                <InfoIcon fontSize="small" />
              </Tooltip>
            </Typography>
            Time Required:{" "}
            <TextField
              {...defaultTextFieldProps}
              defaultValue={
                initialAdvancedOptions?.pullout_limits?.[0]?.duration ||
                (type == "1" ? 5 : 2) // if Project_type is transit, default to 5, else 2
              }
              name="pullout_limits"
            />
          </CardContent>
        </Card>
      </Box>
      <Card sx={{ width: "100%", my: "5px" }}>
        <CardContent>
          <Typography
            display="flex"
            justifyContent="space-between"
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
          >
            Post-Processing
            <Tooltip
              title={
                <Typography>
                  <strong>Post-Processing</strong>: Consists of time (in
                  minutes) used for vehicle support activities that may not
                  occur every time a vehicle departs from the depot, but will
                  occur if a vehicle has been in the depot longer than a minimum
                  time (minutes), such as cleaning or maintenance. This will
                  reduce available charging time and would be in addition to
                  pre-processing time.
                </Typography>
              }
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Typography>
          <Box>
            Time required to complete activity:&nbsp;&nbsp;
            <Tooltip title="in minutes">
              <TextField
                {...defaultTextFieldProps}
                required={data.postproc_min !== undefined}
                defaultValue={
                  initialAdvancedOptions?.postproc_limits?.[1]?.duration || ""
                }
                InputProps={{ inputProps: { max: data.postproc_min } }}
                name="postproc_duration"
              />
            </Tooltip>
            <br />
            if in depot longer than&nbsp;&nbsp;
            <Tooltip title="in minutes">
              <TextField
                {...defaultTextFieldProps}
                required={data.postproc_duration !== undefined}
                defaultValue={
                  initialAdvancedOptions?.postproc_limits?.[0]?.end || ""
                }
                InputProps={{ inputProps: { min: data.postproc_duration } }}
                name="postproc_min"
              />
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ my: "5px" }}>
        <CardContent>
          <Typography
            display="flex"
            justifyContent="space-between"
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
          >
            Charging Style
          </Typography>
          <RadioGroup
            row
            name="charging_style"
            defaultValue={initialAdvancedOptions?.charging_style || "full_time"}
            sx={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Tooltip
              title={
                <Typography>
                  Each vehicle will be charged the entire time while in the
                  depot (up to a full battery) before going out on the next
                  block
                </Typography>
              }
            >
              <FormControlLabel
                value="full_time"
                control={<Radio />}
                label="Maximize State of Charge"
                labelPlacement="start"
              />
            </Tooltip>
            <Tooltip
              title={
                <Typography>
                  Each vehicle is charged just enough (with a buffer) to
                  complete the next block of operation
                </Typography>
              }
            >
              <FormControlLabel
                value="just_enough"
                control={<Radio />}
                label="Maintain State of Charge"
                labelPlacement="start"
              />
            </Tooltip>
          </RadioGroup>
        </CardContent>
      </Card>
    </>
  );
};

export const AnnualLoadProfileInputs = ({ inputs }) => (
  <>
    <Grid item xs={6}>
      <TextField
        autoFocus
        name="sat"
        fullWidth
        id="sat"
        label="Saturday Load Percentage"
        defaultValue={inputs?.weekend?.sat}
        type="number"
        InputProps={{
          inputProps: { max: 150, min: 0.0, step: "1" },
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        name="sun"
        fullWidth
        id="sun"
        label="Sunday Load Percentage"
        defaultValue={inputs?.weekend?.sun}
        type="number"
        InputProps={{
          inputProps: { max: 150, min: 0.0, step: "1" },
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        name="start_date"
        required
        fullWidth
        id="start_date"
        label="Start Date"
        defaultValue={inputs?.start_date}
        type="date"
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        name="end_date"
        required
        fullWidth
        id="end_date"
        label="End Date"
        defaultValue={inputs?.end_date}
        type="date"
      />
    </Grid>
    <Grid item xs={3} />
    <Grid item xs={6}>
      <TextField
        name="resolution"
        required
        fullWidth
        id="resolution"
        label="Resolution"
        defaultValue={inputs?.resolution}
        select
      >
        {/* <MenuItem value={1}>Every Minute</MenuItem> */}
        <MenuItem value={15}>Every 15 Minutes</MenuItem>
        <MenuItem value={60}>Every Hour</MenuItem>
      </TextField>
    </Grid>
  </>
);

export const FinancialParamInputs = ({ inputs, accessRights }) => (
  <>
    <Grid item xs={12} sm={6} md={6}>
      <Tooltip
        title={
          <Typography style={{ textAlign: "center" }}>
            Interest Rate from Capital Financing
            <br />
            (if Applicable)
          </Typography>
        }
      >
        <TextField
          required
          fullWidth
          name="interestRate"
          label="Interest Rate"
          defaultValue={roundNumber(inputs.interest * 100)}
          type="number"
          InputProps={{
            inputProps: { min: 0.0, step: "1" },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          autoFocus
          disabled={
            !accessRights.analysis.create_financial_and_emissions_analysis
          }
        />
      </Tooltip>
    </Grid>
    <Grid item xs={12} sm={6} md={6}>
      <TextField
        name="discountRate"
        required
        fullWidth
        label="Discount Rate"
        defaultValue={roundNumber(inputs.discount * 100)}
        type="number"
        InputProps={{
          inputProps: { min: 0.0, step: "1" },
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        disabled={
          !accessRights.analysis.create_financial_and_emissions_analysis
        }
      />
    </Grid>
  </>
);
