import Fullscreen from "@mui/icons-material/Fullscreen";
import {
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

import { DataContext } from "../../contexts/dataContext";
import { MFM_to_AMPM } from "../utils";

/**
 *
 * @param {Object} props
 * @param {Boolean} props.type used to determine the viewmode of the chart; true= full_power/unmanaged, false= low_power/managed
 * @param {[React.Dispatch<React.SetStateAction<boolean>>]} props.setType used to toggle the viewmode of the chart
 * @param {Object} props.loadProfile full_power and Low Power for the chart
 * @param {Object} props.tcoData For the managed/unmanaged transformer Capacity
 * @param {String} props.className additional props for the component
 * @param {[Boolean]} props.printOnly if true, only display component during print
 */
export function LoadProfileGraph(props) {
  const itemEls = useRef({});
  const fullscreenRef = useRef();
  const type = props.type; //true= full_power/unmanaged, false= low_power/managed
  const [printView, setPrintView] = useState(false);
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);

  const { logo } = useContext(DataContext);

  const loadArray = props.loadProfile.map((el) =>
    type ? el.full_power : el.low_power
  );

  const simulationNames = props.allProjectsData?.map((el) => el.name);

  const handleChange = (event) => {
    props?.setType(event.target.value);
  };

  useEffect(() => {
    // fires both when clicking "generate PDF" and ctrl+p, but only affects page with ctrl+p
    function onBeforePrint() {
      //forcibly resets the zoom when generating pdf
      datasets.forEach((_el, index) => {
        if (itemEls.current[index].resetZoom) {
          itemEls.current[index].resetZoom();
        }
      });
      // ensures that the chart is rendered before generating pdf using ctrl + p
      setPrintView(true);
    }

    function onAfterPrint() {
      setPrintView(false);
    }

    window.addEventListener("beforeprint", onBeforePrint);
    window.addEventListener("afterprint", onAfterPrint);

    return function cleanupListener() {
      window.removeEventListener("beforeprint", onBeforePrint);
      window.removeEventListener("afterprint", onAfterPrint);
    };
  }, []);

  const flattenedArray = loadArray.flat();

  const maxValue = Math.floor(Math.max(...flattenedArray));

  /** @type {import("chart.js").ChartOptions} */
  const sharedOptions = {
    interaction: { intersect: false, mode: "index" }, //has the tooltip appear when mouse is anywhere on map, instead of only above specific points
    plugins: {
      legend: { position: "top" },
      title: { display: false, text: "Load Profile", font: { size: 19 } },
      tooltip: {
        position: "nearest", //has the tooltip appear at the point on a line closest to mouse
        callbacks: {
          title: (value) => `Time: ${value[0].label}`, //custom tooltip title to display time
          label: (value) =>
            `${value.dataset.label}: ${value.formattedValue} kW`, //customizes tooltip display for y-axis value
        },
      },
      zoom: {
        pan: { enabled: true, mode: "x", modifierKey: "ctrl" },
        zoom: { drag: { enabled: true }, mode: "x" },
      },
    },
    scales: {
      x: {
        ticks: {
          //has x-axis tick labels only appear once every 3 hours (180 minutes)
          callback: (val) => (val % 180 === 0 ? MFM_to_AMPM(val) : null),
        },
        title: { display: true, text: "Time of Day", font: { size: 19 } }, //label for x-axis
      },
      y: {
        suggestedMax: maxValue + 10,
        ticks: { font: { size: 16 }, stepSize: 20 },
        title: { display: true, text: ["Power", "(kW)"], font: { size: 19 } },
      },
    },
  };

  /** @type {import("chart.js").ChartOptions} */
  const gridOptions = { ...sharedOptions };

  /** @type {import("chart.js").ChartOptions} */
  const fullscreenOptions = { ...sharedOptions };

  const xAxis = Array.from(Array(1441).keys()).map(MFM_to_AMPM);

  /** @type {import("chart.js").ChartDataset[]} */
  let datasets = loadArray.map(
    /** @returns {import("chart.js").ChartDataset} */
    (el, index) => ({
      fill: true,
      label: `${simulationNames[index]}`,
      data: el,
      borderColor: "#38AECC",
      backgroundColor: (context) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, 125);
        gradient.addColorStop(0.5, "#ffffff00");
        gradient.addColorStop(1, "#38AECC80");
        return gradient;
      },
      pointRadius: 0, //the size of a data point's dot on the chart, made 0 to decrease width of line
      tension: 0.2,
    })
  );

  function TypeDropdown() {
    return (
      <FormControl style={{ minWidth: "0%" }} size="small">
        <Select value={type} onChange={handleChange}>
          <MenuItem value={false}>Managed</MenuItem>
          <MenuItem value={true}>Unmanaged</MenuItem>
        </Select>
      </FormControl>
    );
  }

  return (
    <Box
      className={props.className}
      sx={{
        //used to hide printOnly charts unless the user is downloading a PDF
        display: props?.printOnly && !printView ? "none" : "",
        displayPrint: "block",
      }}
    >
      <Paper
        className="chartdiv"
        sx={{
          width: "100%",
          overflow: "hidden",
          displayPrint: "block",
          backgroundColor: "#FCFCFC",
        }}
      >
        <Box m={3}>
          <Container sx={{ display: "flex", justifyContent: "space-between" }}>
            <Stack
              direction="row"
              spacing={1}
              display="flex"
              alignItems="center"
            >
              <Typography variant="body1" noWrap sx={{ fontWeight: 500 }}>
                Load Profile
              </Typography>
              <TypeDropdown />
            </Stack>
            <IconButton
              title="FullScreen"
              onClick={() => setIsFullscreenOpen(true)}
            >
              <Fullscreen />
            </IconButton>
          </Container>
          <Container className="no-print" sx={{ textAlign: "left" }}>
            <br />
            <Chip
              label="Reset Zoom"
              onClick={() => {
                datasets.forEach((_el, index) => {
                  if (itemEls.current[index].resetZoom) {
                    itemEls.current[index].resetZoom();
                  }
                });
              }}
            />
          </Container>
          <Grid container columns={16}>
            {datasets.map((dataset, index) => (
              <Grid key={`${simulationNames[index]}_${index}`} item xs={8}>
                <Line
                  ref={(element) => (itemEls.current[index] = element)}
                  options={gridOptions}
                  data={{ labels: xAxis, datasets: [dataset] }}
                />
                <div
                  style={{
                    bottom: "110px",
                    marginLeft: "auto",
                    width: "0px",
                    marginRight: "1.5em",
                    position: "relative",
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  className="watermark-container"
                >
                  <img
                    src={logo}
                    alt="logo"
                    style={{
                      position: "absolute",
                      right: "9px",
                      maxWidth: "100px",
                      maxHeight: "50px",
                    }}
                    className="watermark-img"
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isFullscreenOpen}
        onClose={() => setIsFullscreenOpen(false)}
      >
        <DialogTitle display="flex" justifyContent="space-between">
          <Stack direction="row" spacing={1} display="flex" alignItems="center">
            <p>Load Profile Overlay</p>
            <TypeDropdown />
          </Stack>
          <Chip
            label="Reset Zoom"
            onClick={() => fullscreenRef.current.resetZoom()}
          />
        </DialogTitle>
        <DialogContent>
          <Line
            ref={fullscreenRef}
            data={{
              labels: xAxis,
              datasets: datasets.map((dataset) => ({
                ...dataset,
                borderColor: undefined,
                backgroundColor: undefined,
              })),
            }}
            options={fullscreenOptions}
          />
          <div
            style={{ bottom: "7.5rem", right: "50px" }}
            className="watermark-container"
          >
            <img
              src={logo}
              width="auto"
              height="50px"
              className="watermark-img"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsFullscreenOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
