import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Cookie from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SnackBarContext } from "../../contexts/snackBarContext";
import { passwordResetURL } from "../../static/constants/backendRoutes";
import { errorHandler, getSubdomain } from "../utils";

export default function ForgotPassword() {
  const [email, setEmail] = useState(); //only used to display email in notification of successful password reset
  const open = Boolean(email);
  const { snackBarElement } = useContext(SnackBarContext);

  useEffect(() => {
    if (Cookie.get("token")) Cookie.remove("token");
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const body = { email: data.get("email"), subdomain: getSubdomain() };

    fetch(passwordResetURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) setEmail(body?.email);
        else errorHandler(res, snackBarElement);
      })
      .catch((error) => console.log(error));
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Paper
        variant="outlined"
        sx={{ mb: { xs: 1, md: 3 }, mt: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Find Your Account
          </Typography>

          <Typography component="p" variant="body2" sx={{ mt: "1%" }}>
            Please enter your email to search for your account
          </Typography>
          <Box
            sx={{
              mt: 2,
            }}
          >
            <Container
              fixed
              component="form"
              // noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              size="lg"
              sx={{
                justifyContent: "center",
                minWidth: "100%",
              }}
            >
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                type="email"
                autoComplete="email"
                sx={{ minWidth: "100%", mb: 2 }}
              />
              <Button
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                sx={{ minWidth: "100%", mb: 2 }}
              >
                Send Email
              </Button>{" "}
              <Collapse in={open}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => setOpen(undefined)}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  Verification email sent to {email}!
                </Alert>
              </Collapse>{" "}
            </Container>
            <Container
              fixed
              size="lg"
              sx={{
                justifyContent: "center",
                minWidth: "100%",
              }}
            >
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                component={Link}
                to={"/login"}
              >
                Login
              </Button>
            </Container>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
