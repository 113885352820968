import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  unitAreaMap,
  unitMassMap,
} from "../../static/constants/systems_of_measurement";
import CO2 from "../../static/images/co2.png";
import Trees from "../../static/images/trees.png";
import { getUnits, unitAcres, unitTon } from "../secondary/unitConversions";
import { numberUS, stringCapitalize, unitWrapper } from "../utils";

function CompareAnalysisOverview({ data }) {
  const units = getUnits();

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        backgroundColor: "#FCFCFC",
      }}
      elevation={3}
    >
      {data.map((data) => (
        <div key={data.name}>
          <Typography
            paddingLeft={2}
            paddingTop={2}
            variant="h5"
            align="left"
            style={{ fontWeight: 500, marginRight: "1%", color: "gray" }}
          >
            {data?.name ?? "NA"}
          </Typography>
          <Grid
            paddingLeft={2.5}
            paddingRight={2.5}
            paddingTop={1}
            paddingBottom={2}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h6">Fleet Size</Typography>
              <Grid container spacing={3}>
                <Grid item>
                  <Typography variant="h3" noWrap sx={{ fontSize: 45 }}>
                    {(data?.analysis_type_steps?.fleet_and_charger_sizing
                      ?.depot_energy_analysis == "2.0.0"
                      ? data?.steps?.evAssessment?.fleet_size?.feasible
                          ?.total_ev_fleet_size
                      : data?.steps?.evAssessment?.fleet_size
                          ?.total_ev_fleet_size) ?? "NA"}
                  </Typography>
                  Electrified
                </Grid>
                <Grid item>
                  <Typography variant="h3" noWrap sx={{ fontSize: 45 }}>
                    {(data?.analysis_type_steps?.fleet_and_charger_sizing
                      ?.depot_energy_analysis == "2.0.0"
                      ? data.steps.evAssessment.fleet_size.feasible
                          .total_ice_fleet_size
                      : data.steps.evAssessment.fleet_size
                          .total_ice_fleet_size) || "NA"}
                  </Typography>
                  Current
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6">Chargers</Typography>
              <Grid container spacing={3}>
                <Grid item>
                  <Typography variant="h3" noWrap sx={{ fontSize: 45 }}>
                    {data?.steps?.evAssessment?.num_chargers ?? "NA"}
                  </Typography>
                  Count
                </Grid>
                <Grid item>
                  <Typography variant="h3" noWrap sx={{ fontSize: 45 }}>
                    {
                      data?.analysis_type_steps?.battery_sizing
                        ?.fleet_and_charger_sizing_analysis == "2.0.0"
                        ? data?.steps?.fleetSizing?.feasible_blocks?.length >
                          data?.steps?.input?.fleetSizing?.row
                          ? //if the index is in range
                            data.steps.fleetSizing.feasible_blocks[
                              data.steps.input.fleetSizing.row
                            ].charger_model.rating
                          : "NA" //index out of bounds
                        : data?.steps?.fleetSizing?.feasible?.combos?.length >
                          data?.steps?.input?.fleetSizing?.row
                        ? //if the index is in range
                          data.steps.fleetSizing.feasible.combos[
                            data.steps.input.fleetSizing.row
                          ].charger_model.rating
                        : "NA" //index out of bounds
                    }
                  </Typography>
                  Rating
                  {unitWrapper(" (kW)")}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6">Net Emissions Saving</Typography>
              <Grid container spacing={2}>
                <Grid sx={{ height: 100 }} item>
                  <img src={CO2} alt="CO2" style={{ maxHeight: "95%" }} />
                </Grid>
                <Grid item>
                  <Typography variant="h3" noWrap sx={{ fontSize: 45 }}>
                    {numberUS.format(
                      Math.round(
                        unitTon(data?.steps?.tco?.BEV?.emissions_savings)
                      )
                    )}{" "}
                  </Typography>
                  {stringCapitalize(unitMassMap[units])} per Year
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6">Net Emissions Saving</Typography>
              <Grid container spacing={2}>
                <Grid sx={{ height: 100 }} item>
                  <img src={Trees} alt="Trees" style={{ maxHeight: "95%" }} />
                </Grid>
                <Grid item>
                  <Typography variant="h3" noWrap sx={{ fontSize: 45 }}>
                    {numberUS.format(
                      Math.round(
                        unitAcres(data?.steps?.tco?.BEV?.acres_of_trees)
                      )
                    )}
                  </Typography>
                  {unitAreaMap[units]}s of Forest
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation="horizontal" flexItem />
        </div>
      ))}
    </Paper>
  );
}

export default CompareAnalysisOverview;
