import Sort from "@mui/icons-material/Sort";
import {
  Box,
  Chip,
  ListItemIcon,
  Menu,
  MenuItem,
  Slider,
  Stack,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";

import { DataContext } from "../../../contexts/dataContext";
import { unitLargeAbbr } from "../../../static/constants/systems_of_measurement";
import { getUnits, unitMiles } from "../../secondary/unitConversions";
import { VehicleModelDropdown } from "../commonComponents";

const colors = {
  energy: "#0b132b",
  soc_limit: "#348aa7",
  degradation: "#4978ee",
  difference: "#cdf7f6",
};

export default function BatterySizingGraph({
  allData, //a list of selected blocks (or all blocks, if none selected)
  routeEnergyInputs,
  vehicleInfoLookup,
  updateSubheader,
}) {
  const chartRef = useRef(null);
  const [degredationYear, setDegredationYear] = useState(
    routeEnergyInputs?.operating_year || 1
  );
  const [sort, setSort] = useState(0); //flag to determine unsorted (0), ascending sort (odd number), or descending sort (even number)
  const [sortAnchorEl, setSortAnchorEl] = useState(null); //used to position the dropdown for selecting sorts
  const [selectedModel, setSelectedModel] = useState("all");

  const isMileage = sort > 2;
  const units = getUnits();
  const { logo } = useContext(DataContext);

  let selectedType, selectedSize;
  if (selectedModel != "all") {
    // if a model has been selected, allow for all vehicleModels with matching type/size combos, as batteryCapactiy might vary
    const selectedInfo = vehicleInfoLookup[selectedModel];
    selectedType = selectedInfo.type;
    selectedSize = selectedInfo.size;
  }

  let data = allData
    .filter(
      (row) =>
        selectedModel == "all" ||
        (row.veh_type == selectedType && row.size == selectedSize) // if a model has been selected, allow for all vehicleModels with matching type/size combos, as batteryCapactiy might vary
    )
    .map((entry, index) => {
      //converts a unit of energy into equivalent mileage
      const convertMileage = (data) =>
        isMileage
          ? unitMiles(data / entry.detailed_energy.updated_efficiency)
          : data;

      const vehicleInfo = vehicleInfoLookup[entry.vehicleModel];
      const chartSoC =
        entry.batter_size *
        (1 - (vehicleInfo.max_soc - vehicleInfo.min_soc) / 100);
      const chartBatterySize = entry.batter_size - chartSoC;
      const chartDegredation =
        chartBatterySize - chartBatterySize * 0.97 ** degredationYear;
      return {
        id: index,
        vehicleModel: entry.vehicleModel, //used for compute subheader function
        detailed_energy: entry.detailed_energy, //also used for compute subheader function
        batter_size: entry.batter_size,
        blockId: entry.blockId,
        degradation: convertMileage(chartDegredation),
        // degradation: entry.battery_detailed.degradation,
        minimum_battery_size: convertMileage(entry.batter_size),
        soc_limit: convertMileage(chartSoC),
        // soc_limit: entry.battery_detailed.soc_limit,
        energy: convertMileage(entry.detailed_energy.total_energy),
        feasible:
          entry.batter_size -
            chartSoC -
            chartDegredation -
            entry.detailed_energy.total_energy <
          0
            ? 1
            : 0,
        // chart surplus
        difference: convertMileage(
          entry.batter_size -
            chartSoC -
            chartDegredation -
            entry.detailed_energy.total_energy
        ),
        // entry.feasible == 0
        // ? entry.batter_size - entry.battery_detailed.minimum_battery_size
        // : 0,
      };
    });

  useEffect(() => {
    if (data.length) updateSubheader(data, degredationYear);
  }, [degredationYear, selectedModel]);

  const sortOptions = [
    { label: "Default sort", order: "asc" },
    { label: "Energy", order: "asc" },
    { label: "Energy", order: "desc" },
    { label: "Block Mileage", order: "asc" },
    { label: "Block Mileage", order: "desc" },
    { label: "Surplus Mileage", order: "asc" },
    { label: "Surplus Mileage", order: "desc" },
  ];

  /** opens the sort selection dropdown */
  const handleOpenSortMenu = (e) => setSortAnchorEl(e.currentTarget);
  /** closes the sort selection dropdown */
  const handleCloseSortMenu = (e) => setSortAnchorEl(null);
  /** selects a sort and closes the the dropdown */
  const handleSortSelect = (e) => {
    setSort(e.currentTarget.value);
    setSortAnchorEl(null);
  };

  switch (sort) {
    case 1:
      //1=> sort: Energy min->max
      data = data.sort((e1, e2) => e1.energy - e2.energy);
      break;
    case 2:
      //2=> sort: Energy max->min
      data = data.sort((e1, e2) => e2.energy - e1.energy);
      break;
    case 3:
      //3=> sort: Block Mileage min->max
      data = data.sort((e1, e2) => e1.energy - e2.energy);
      break;
    case 4:
      //4=> sort: Block Mileage max->min
      data = data.sort((e1, e2) => e2.energy - e1.energy);
      break;
    case 5:
      //4=> sort: Surplus Mileage min->max
      data = data.sort((e1, e2) => e1.difference - e2.difference);
      break;
    case 6:
      //4=> sort: Surplus Mileage max->min
      data = data.sort((e1, e2) => e2.difference - e1.difference);
      break;
    case 0:
    default: //unsorted (sort by ID)
      data = data.sort((e1, e2) => e1.id - e2.id);
  }

  const allEnergy = data.map((block) => parseInt(block.energy));
  const feasibleValues = data.map((block) => block.feasible);
  const showFeasibleLegend = feasibleValues.includes(1);

  //computes the min/max energiess, for use in determining range of color of bars
  let max = Math.max(...allEnergy);
  let min = Math.min(...allEnergy);

  max = max - min ? max - min : 1;
  //max variable is replaced with the total range of energiess; if range is 0 (max==min),
  //then set range to 1 (to avoid dividing by 0)

  /** @type {import("chart.js").ChartOptions} */
  const chartOptions = {
    interaction: { intersect: false, mode: "index" }, //combines all 3 bars into 1 tooltip
    plugins: {
      //displays the title of the chart
      title: {
        display: true,
        text: ["Battery Sizing Overview", `Year ${degredationYear}`],
        font: {
          size: 16,
        },
      },
      //creates a tooltip when hovering over a bar
      tooltip: {
        callbacks: {
          title: (value) => `Block ID: ${data[value[0].dataIndex].blockId}`,
          afterTitle: (value) => {
            let output = [
              `Selected Battery ${isMileage ? "Range" : "Size"}: ${(
                data[value[0].dataIndex].minimum_battery_size -
                (isMileage //if user is viewing a mileage sort, subtract the degredation from the overall range
                  ? value.find(
                      (block) => block.dataset.label == "Battery Age Impact"
                    ).formattedValue
                  : 0)
              ).toFixed(0)} ${isMileage ? unitLargeAbbr[units] : "kWh"}`,
            ];

            if (isMileage) {
              output[0] += ` (Yr. ${degredationYear})`;
              output = [
                `Selected Battery Range: ${data[
                  value[0].dataIndex
                ].minimum_battery_size.toFixed(0)} ${
                  unitLargeAbbr[units]
                } (New)`,
                output[0],
              ];
            }

            return output;
          },
          label: (tooltipItem) => {
            switch (tooltipItem.datasetIndex) {
              case 0:
                return `Total Block ${isMileage ? "Distance" : "Energy"}: ${
                  tooltipItem.formattedValue
                } ${isMileage ? unitLargeAbbr[units] : "kWh"}`;
              case 1:
                return `${
                  isMileage
                    ? "Manufacturer's Safety Margin"
                    : "State of Charge Limit"
                }: ${tooltipItem.formattedValue} ${
                  isMileage ? unitLargeAbbr[units] : "kWh"
                }`;
              case 2:
                return `${
                  isMileage ? "Battery Age Impact" : "Battery Degradation"
                }: ${tooltipItem.formattedValue} ${
                  isMileage ? unitLargeAbbr[units] : "kWh"
                }`;
              case 3:
                return `${isMileage ? "Surplus Range" : "Battery Surplus"}: ${
                  tooltipItem.formattedValue
                } ${isMileage ? unitLargeAbbr[units] : "kWh"}`;
            }
          },
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
          modifierKey: "ctrl",
        },
        zoom: {
          drag: {
            enabled: true,
          },
          mode: "x",
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        //replace labelled ticks with a title for the x-axis
        ticks: {
          display: false,
          font: { size: 16 },
        },
        title: {
          display: true,
          text: "Blocks",
          font: { size: 16 },
        },
      },
      //label the y-axis
      y: {
        suggestedMax:
          Math.max(...data.map((row) => row.minimum_battery_size)) + 1,
        stacked: true,
        ticks: {
          font: { size: 16 },
        },
        title: {
          display: true,
          text: isMileage
            ? `Mileage (${unitLargeAbbr[units]})`
            : "Energy (kWh)",
          font: { size: 16 },
        },
      },
    },
  };

  /** @type {import("chart.js").ChartData} */
  const chartData = {
    //determines the name of each bar (x-axis)
    labels: data.map((block) => block.blockId),
    datasets: [
      {
        label: `Total Block ${isMileage ? "Distance" : "Energy"}`,
        data: data.map((block) => block.energy.toFixed(1)),
        //if the data is infeasible, then color its bar a slight red, otherwise a black-ish color
        backgroundColor: colors.energy,
        borderWidth: data.map((block) =>
          block.feasible == 0 ? 0 : { left: 3, right: 3, bottom: 3 }
        ),
        borderSkipped: false,
        borderColor: data.map((block) =>
          block.feasible == 0 ? "white" : "red"
        ),
      },
      {
        label: isMileage
          ? "Manufacturer's Safety Margin"
          : "State of Charge Limit",
        data: data.map((block) => block.soc_limit.toFixed(1)),
        //if the data is infeasible, then color its bar a slight red, otherwise a black-ish color
        backgroundColor: colors.soc_limit,
        borderWidth: data.map((block) =>
          block.feasible == 0 ? 0 : { left: 3, right: 3 }
        ),
        borderColor: data.map((block) =>
          block.feasible == 0 ? "white" : "red"
        ),
      },
      {
        label: isMileage ? "Battery Age Impact" : "Battery Degradation",
        data: data.map((block) => block.degradation.toFixed(1)),
        //if the data is infeasible, then color its bar a slight red, otherwise a black-ish color
        backgroundColor: colors.degradation,
        borderWidth: data.map((block) =>
          block.feasible == 0 ? 0 : { top: 3, left: 3, right: 3 }
        ),
        borderColor: data.map((block) =>
          block.feasible == 0 ? "white" : "red"
        ),
      },
      {
        label: isMileage ? "Surplus Range" : "Battery Surplus",
        data: data.map((block) => block.difference.toFixed(1)),
        //if the data is infeasible, then color its bar a slight red, otherwise a black-ish color
        backgroundColor: data.map((block) =>
          block.feasible == 0 ? colors.difference : "red"
        ),
        borderWidth: data.map((block) =>
          block.feasible == 0 ? 0 : { top: 3, left: 3, right: 3, bottom: 3 }
        ),
        borderColor: data.map((block) =>
          block.feasible == 0 ? "white" : "red"
        ),
      },
      {
        label: showFeasibleLegend ? "No Available Batteries" : "",
        borderWidth: 3,
        borderColor: showFeasibleLegend ? "red" : "white",
        backgroundColor: "white",
        display: showFeasibleLegend ? "none" : "auto",
      },
    ],
  };

  //todo: can data ever be false? Consider removing "data &&" below
  return (
    data && (
      <div className="chartdiv">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <VehicleModelDropdown
            data={allData}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
          />
          <Slider
            value={degredationYear}
            marks={[
              { value: 0, label: "New" },
              {
                value: routeEnergyInputs.operating_year,
                label: `${routeEnergyInputs.operating_year} year${
                  routeEnergyInputs.operating_year == 1 ? "" : "s"
                }`,
              },
            ]}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) =>
              `${value} year${value == 1 ? "" : "s"}`
            }
            min={0}
            max={routeEnergyInputs.operating_year}
            color="secondary"
            onChange={(e) => setDegredationYear(e.target.value)}
            sx={{ maxWidth: "50%" }}
          />
          <Stack
            sx={{ justifyContent: "right", margin: "1%" }}
            direction="row"
            spacing={1}
          >
            <Chip
              label={sortOptions[sort].label}
              icon={
                <Sort
                  className={`rotate-icon-${
                    sortOptions[sort].order === "desc" ? 1 : 2
                  }`}
                />
              }
              onClick={handleOpenSortMenu}
            />
            <Menu
              open={Boolean(sortAnchorEl)}
              anchorEl={sortAnchorEl}
              onClose={handleCloseSortMenu}
              PaperProps={{
                style: { minWidth: sortAnchorEl?.clientWidth }, // makes the dropdown the same size as the chip
                className: "btn",
              }}
            >
              {sortOptions.map((value, index) => (
                <MenuItem
                  key={`${value.label}_${value.order}`}
                  value={index}
                  onClick={handleSortSelect}
                >
                  <ListItemIcon>
                    <Sort
                      className={
                        value.order === "desc"
                          ? "rotate-icon-1"
                          : "rotate-icon-2"
                      }
                    />
                  </ListItemIcon>
                  {value.label}
                </MenuItem>
              ))}
            </Menu>
            <Chip
              label="Reset Zoom"
              onClick={() => chartRef?.current?.resetZoom()}
            />
          </Stack>
        </Box>
        <div className="watermark-container">
          <img
            src={logo}
            width="auto"
            height="50px"
            className="watermark-img"
          />
        </div>
        <Bar ref={chartRef} options={chartOptions} data={chartData} />
      </div>
    )
  );
}
