import ExpandMore from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useEffect, useState } from "react";

export default function TermsConditions({ setState }) {
  const [hasRead, setHasRead] = useState({ toc: false, privacy: false });

  //user must open all legal document dropdowns at least once before accepting
  useEffect(() => {
    function toggleState() {
      if (Object.values(hasRead).every((i) => i)) {
        setState((state) => ({ ...state, hasRead: true }));
      }
    }

    toggleState();
  }, [hasRead]);

  //original, "user must scroll to end before accepting" code
  // useEffect(() => {
  //   //scroll check code from: https://stackoverflow.com/a/19778574
  //   const i = document.getElementById("terms-and-conditions-body");
  //   i.onscroll = function () {
  //     const x = i.scrollHeight - i.offsetHeight - 5;
  //     if (i.scrollTop >= x && !state?.hasRead)
  //       setState((state) => ({ ...state, hasRead: true }));
  //   };
  // }, [state]);

  return (
    <>
      <Accordion onChange={() => setHasRead({ ...hasRead, toc: true })}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          User Agreement
        </AccordionSummary>
        <AccordionDetails sx={{ height: "75vh" }}>
          <iframe
            width="100%"
            height="100%"
            src="https://assets.dev.ev-opt.com/terms_of_service_file.pdf"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion onChange={() => setHasRead({ ...hasRead, privacy: true })}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          Terms and Conditions
        </AccordionSummary>
        <AccordionDetails sx={{ height: "75vh" }}>
          <iframe
            width="100%"
            height="100%"
            src="https://assets.dev.ev-opt.com/privacy_policy_file.pdf"
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
