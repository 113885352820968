import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import LandingFooter from "../../static/images/homepage-footer.png";

export default function Footer() {
  const location = useLocation();
  return (
    <footer className="footer-pin no-print">
      {(location.pathname == "/" || location.pathname == "/login") && (
        //if on homepage/login, display some charger stations on top of footer
        <img
          style={{ width: "100%", marginBottom: "-7px" }}
          src={LandingFooter}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          py: location.pathname == "/" || location.pathname == "/login" ? 5 : 3,
          pl: "300px",
          pr: 2,
          mt: "auto",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        {window.location.host?.split(".")?.length == 4 ? (
          <Box width="100%" display="flex" justifyContent="space-between">
            <Typography variant="body1">Powered by EVopt</Typography>
            <Typography variant="body2" color="text.secondary">
              <Link
                color="inherit"
                target="_blank" // opens in new tab
              >
                Privacy Policy
              </Link>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Link
                color="inherit"
                target="_blank" // opens in new tab
              >
                Terms and conditions
              </Link>
            </Typography>
            <span />
          </Box>
        ) : (
          <>
            <Typography variant="body1">
              EVOPT Powered by Microgrid Labs Inc
            </Typography>
            <Box
              maxWidth="md"
              width="100%"
              display="flex"
              justifyContent="space-between"
            >
              <Typography variant="body2" color="text.secondary">
                {"Copyright © "}
                <Link color="inherit" href="https://microgridlabs.com/">
                  Microgrid Labs Inc
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Link
                  color="inherit"
                  href="https://assets.dev.ev-opt.com/privacy_policy_file.pdf"
                  target="_blank" // opens in new tab
                >
                  Privacy Policy
                </Link>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Link
                  color="inherit"
                  href="https://assets.dev.ev-opt.com/terms_of_service_file.pdf"
                  target="_blank" // opens in new tab
                >
                  Terms and conditions
                </Link>
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </footer>
  );
}
