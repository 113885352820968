import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Fragment } from "react";

/** an optional component, used to maintain consistent theming across all subheaders */
export const SubheaderTitle = styled(Typography)(() => ({
  fontSize: 28,
  lineHeight: 1,
}));

/** an optional component, used to maintain consistent theming across all subheaders */
export const SubheaderLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontStyle: "italic",
}));

/**
 *
 * @param {object} props
 * @param {{value: JSX, label: JSX}[]} props.content
 * @param {object} props.props
 */
export default function Subheader({ content = [], props }) {
  return (
    <>
      {content?.length ? (
        <Container
          sx={{ display: "flex", justifyContent: "space-evenly" }}
          {...props}
        >
          {content.map(({ value, label }, index) => (
            <Fragment key={`subheader_${index}`}>
              <div>
                <SubheaderTitle>{value}</SubheaderTitle>
                <SubheaderLabel>{label}</SubheaderLabel>
              </div>
              {index < content.length - 1 ? (
                <Divider orientation="vertical" flexItem />
              ) : (
                ""
              )}
            </Fragment>
          ))}
        </Container>
      ) : (
        <Skeleton variant="rectangular" width="100%">
          <Container>
            <SubheaderTitle>loading</SubheaderTitle>
            <SubheaderLabel>loading</SubheaderLabel>
          </Container>
        </Skeleton>
      )}
      <Divider />
    </>
  );
}
