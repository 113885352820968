import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useContext, useEffect, useState } from "react";
import { Link, Route, Routes, useParams } from "react-router-dom";
import { SnackBarContext } from "../../contexts/snackBarContext";
import { simulationURL } from "../../static/constants/backendRoutes";
import { comparisonStepInfo } from "../../static/constants/stepInfo";
import UseAuth from "../auth/useAuth";
import NotFound from "../secondary/notFound";
import { CompareAnalysisStepper } from "../secondary/steppers";
import { errorHandler, getLocalData } from "../utils";
import CompareAnalysisOverview from "./compareAnalysisOverview";
import { LoadProfileGraph } from "./compareLoadProfileGraph";
import TCOTable from "./compareTcoTable";

const buttonObj = comparisonStepInfo.map((step, index) => {
  let obj = {};
  if (index === 0) {
    obj.prev = null;
    obj.next = comparisonStepInfo[index + 1].label;
  } else if (index + 1 === comparisonStepInfo.length) {
    obj.prev = comparisonStepInfo[index - 1].label;
    obj.next = null;
  } else {
    obj.prev = comparisonStepInfo[index - 1].label;
    obj.next = comparisonStepInfo[index + 1].label;
  }
  obj.route = step.route;
  return obj;
});

function CompareAnalyses() {
  const { "*": page } = useParams(); // gets the page view & the ids of the simulations to be compared
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadProfileView, setLoadProfileView] = useState(true);

  const { snackBarElement } = useContext(SnackBarContext);

  const pageView = comparisonStepInfo.findIndex((step) =>
    step.route.endsWith(page)
  );

  useEffect(() => {
    function fetchSimData(sim_id) {
      const headers = { Authorization: `Token ${UseAuth("get")}` };
      return fetch(`${simulationURL}?simulation_id_list=${sim_id}&all_info=T`, {
        method: "GET",
        headers: headers,
      })
        .then((res) => {
          if (res.ok) {
            return res.json().then(({ data: simulation }) => simulation[0]);
          } else {
            errorHandler(
              res,
              snackBarElement,
              `Failed to get simulation ${sim_id}'s data`
            );
            return undefined;
          }
        })
        .catch((e) => {
          snackBarElement?.current?.displayToast(
            `Failed to get simulation ${sim_id}'s data`,
            "error",
            5000
          );
          console.log(e);
          return undefined;
        });
    }

    async function fetchData() {
      setLoading(true);

      const simulation_ids = await getLocalData("simulation", "input").then(
        ({ input }) => input?.simulation_ids || []
      );

      const simData = await Promise.all(simulation_ids.map(fetchSimData));

      setData(simData);
      setLoading(false);
    }

    fetchData();
  }, []);

  if (loading)
    return (
      <div className="centered">
        <CircularProgress />
        <br />
        Loading...
      </div>
    );

  return (
    <>
      <br />
      <br />
      <CompareAnalysisStepper stepNum={pageView} />
      <br />
      <br />
      <Container maxWidth="xl">
        <Routes>
          <Route
            path={comparisonStepInfo[0].route.slice(9)}
            element={<CompareAnalysisOverview data={data} />}
          />
          <Route
            path={comparisonStepInfo[1].route.slice(9)}
            element={
              <LoadProfileGraph
                type={loadProfileView}
                setType={setLoadProfileView}
                allProjectsData={data}
                loadProfile={data.map(
                  (el) => el.steps.evAssessment.load_profile
                )}
              />
            }
          />
          <Route
            path={comparisonStepInfo[2].route.slice(9)}
            element={
              <TCOTable
                allProjectsData={data}
                tcoData={data.map((el) => el.steps.tco)}
                fleetSizes={data.map((el) =>
                  el.analysis_type_steps?.fleet_and_charger_sizing
                    ?.depot_energy_analysis == "2.0.0"
                    ? el.steps.evAssessment.fleet_size.feasible
                    : el.steps.evAssessment.fleet_size
                )}
                simulation={["ZEFTP w/Midday", "ZEFTP"]}
              />
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <br />
        <br />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              className="btn"
              sx={{ width: "95%" }}
              startIcon={<ArrowBackIosNew />}
              disabled={!buttonObj[pageView].prev}
              component={Link}
              to={pageView > 0 ? buttonObj[pageView - 1].route : ""}
            >
              Previous
              {buttonObj[pageView].prev ? ` : ${buttonObj[pageView].prev}` : ""}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              className="btn"
              disabled={!buttonObj[pageView].next}
              sx={{
                width: "95%",
              }}
              component={Link}
              to={
                pageView + 1 === comparisonStepInfo.length
                  ? ""
                  : buttonObj[pageView + 1].route
              }
            >
              Next
              {buttonObj[pageView].next ? ` : ${buttonObj[pageView].next}` : ""}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default CompareAnalyses;
