import DirectionsBus from "@mui/icons-material/DirectionsBus";
import OfflineBolt from "@mui/icons-material/OfflineBolt";
import { Badge, Stack, Tooltip } from "@mui/material";

/**
 * displays a vehicle icon, with a badge indicating number of vehicles, and a tooltip with more info
 * @param {{depot: Number, resource_id: Number, count: Number}[]} props/resourceList - a list of resources (can be for all depots)
 * @param {Number} props.depot_id the ID of the depot containing the resources
 * @returns {JSX Object}
 */
function DepotResourceIcon(props) {
  const { vehicleList, tariffList, depot_id } = props;

  // count resources associated with depot
  const vehicleCount = vehicleList.filter(
    (vehicle) => vehicle.depot_id == depot_id
  ).length;

  const tariffCount = tariffList.filter(
    (tariff) => tariff.depot_id == depot_id
  ).length;

  return (
    <Stack display="flex" justifyContent="center" spacing={2} direction="row">
      <Tooltip
        title={`${vehicleCount} Vehicle Model${vehicleCount == 1 ? "" : "s"}`}
      >
        <Badge badgeContent={vehicleCount} max={999} color="blueicon">
          <DirectionsBus className="busicon" />
        </Badge>
      </Tooltip>
      <Tooltip
        title={`${tariffCount} Tariff Model${tariffCount == 1 ? "" : "s"}`}
      >
        <Badge badgeContent={tariffCount} max={999} color="blueicon">
          <OfflineBolt className="stationicon" />
        </Badge>
      </Tooltip>
    </Stack>
  );
}

export default DepotResourceIcon;
