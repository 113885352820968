import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { unitLargeAbbr } from "../../../static/constants/systems_of_measurement";
import UseAuth from "../../auth/useAuth";
import Subheader from "../../secondary/subheader";
import { getUnits } from "../../secondary/unitConversions";
import { getLocalData, roundNumber } from "../../utils";
import { EnergyDefinitionGraph } from "../graphs/energyDefinitionGraph";
import { rowDataConversion } from "../pages/routeEnergy";

export default function VehiclesEnergy() {
  const [data, setData] = useState([]);
  const [subheaderContent, setSubheaderContent] = useState([]);
  const [sim, setSim] = useState({});

  const units = getUnits();

  /**
   *
   * @param {Array} data
   * @param {"energy" | "energy_calc"} energyKey //energy_calc is for when the monthly_HVAC slider is in play
   */
  function updateSubheader(data, energyKey = "energy") {
    let [avgEfficiency, maxEnergy, minEnergy, sumEnergy] = [
      0,
      data?.[0]?.[energyKey] || 0,
      data?.[0]?.[energyKey] || 0,
      0,
    ];

    data.forEach((row) => {
      avgEfficiency +=
        energyKey == "energy"
          ? row.detailed_energy.updated_efficiency // if not using hvac slider, get the average by summing the default
          : (row.HVAC + row.traction + row.aux) / row.distance; //otherwise, compute the efficiency by calculating value using the selected monthly hvac
      maxEnergy = Math.max(row[energyKey], maxEnergy);
      minEnergy = Math.min(row[energyKey], minEnergy);
      sumEnergy += row[energyKey];
    });

    sumEnergy = Math.round(sumEnergy);
    sumEnergy = roundNumber(sumEnergy, 4 - sumEnergy.toString().length);

    avgEfficiency = avgEfficiency / data.length;
    setSubheaderContent([
      {
        value: roundNumber(avgEfficiency).toLocaleString(),
        label: `Average Efficiency (kWh/${unitLargeAbbr[units]})`,
      },
      {
        value: Math.round(minEnergy).toLocaleString(),
        label: "Minimum Block Energy (kWh)",
      },
      {
        value: Math.round(maxEnergy).toLocaleString(),
        label: "Maximum Block Energy (kWh)",
      },
      {
        value: Math.round(sumEnergy).toLocaleString(),
        label: "Total Daily Energy (kWh)",
      },
    ]);
  }

  useEffect(() => {
    if (data.length) updateSubheader(data);
  }, [data]);

  useEffect(() => {
    /**
     * retrieves the blocks and project from the local DB and then
     * fetches and stores all the depots, vehicles, and chargers
     * that are associated with the selected project from the backend
     * and does something else?
     */
    async function fetchData() {
      try {
        if (!UseAuth("get")) {
          window.location.assign("/login"); ///TODO: should display error page
          return;
        }

        const { data: currentBlocks } = await getLocalData("routeEnergy");

        const { data: sim } = await getLocalData("simulation");
        setSim(sim);

        const blocks = currentBlocks
          .filter((row) => row?.tableData?.checked || row.checked)
          .map((row, index) => {
            row.id = index; // set the row's Id to be the index of the row, for use in material-react-table edits
            return rowDataConversion(row);
          });

        setData(blocks);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <Subheader content={subheaderContent} />
          <EnergyDefinitionGraph
            data={data}
            updateSubheader={updateSubheader}
            sim={sim}
          />
        </Paper>
      </Container>
      <br />
      <br />
    </div>
  );
}
