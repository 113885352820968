import { InputAdornment, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  unitPerTemperature,
  unitTemperature,
} from "../../secondary/unitConversions";
import { roundNumber, stringCapitalize } from "../../utils";

function ClimateDataEditor({ chartData, setChartData, chartModified }) {
  const { climate, unit } = chartData;

  const rows = climate.monthly_temperature.map((el, index) => {
    let max =
      typeof el.maximum_temperature === "number"
        ? el.maximum_temperature.toFixed(0)
        : el.maximum_temperature;
    let min =
      typeof el.minimum_temperature === "number"
        ? el.minimum_temperature.toFixed(0)
        : el.minimum_temperature;
    let rowObj = {
      id: index,
      month: stringCapitalize(el.month),
      maximum_temperature: max,
      minimum_temperature: min,
    };
    return rowObj;
  });

  /**
   *
   * @param { React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} even
   */
  const handleFieldUpdate = (e) => {
    const newValue = unitPerTemperature(Number(e.target.value), unit);
    const [rowIndex, colName] = e.target.name.split("-");

    chartData.climate.monthly_temperature[rowIndex][colName] = newValue;
    setChartData({ ...chartData });
    chartModified.current = true;
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="climate table">
        <TableHead>
          <TableRow>
            {["Months", "Average Max.", "Average Min."].map((el, index) => (
              <TableCell sx={{ maxWidth: 10 }} key={el}>
                {el}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ maxWidth: 5 }} component="th" scope="row">
                {row.month}
              </TableCell>
              <TableCell align="center" sx={{ maxWidth: 5 }}>
                <TextField
                  required
                  name={`${row.id}-maximum_temperature`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        °{unit == 1 ? "C" : "F"}
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: { height: "1.5em" },
                    inputProps: {
                      min: roundNumber(
                        unitTemperature(+row.minimum_temperature, unit) + 1,
                        0
                      ),
                      max: roundNumber(unitTemperature(140, unit), 0),
                    },
                  }}
                  size="small"
                  sx={{ width: 67 }}
                  type="number"
                  value={Math.round(
                    unitTemperature(row.maximum_temperature, unit)
                  )}
                  onChange={handleFieldUpdate}
                />
              </TableCell>
              <TableCell align="center" sx={{ maxWidth: 5 }}>
                <TextField
                  required
                  name={`${row.id}-minimum_temperature`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        °{unit == 1 ? "C" : "F"}
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: { height: "1.5em" },
                    inputProps: {
                      min: roundNumber(unitTemperature(-40, unit), 0),
                      max: roundNumber(
                        unitTemperature(+row.maximum_temperature, unit) - 1,
                        0
                      ),
                    },
                  }}
                  size="small"
                  type="number"
                  sx={{ width: 67 }}
                  value={Math.round(
                    unitTemperature(row.minimum_temperature, unit)
                  )}
                  onChange={handleFieldUpdate}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ClimateDataEditor;
