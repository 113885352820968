import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import React, { useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import { unitLargeMap } from "../../../static/constants/systems_of_measurement";
import UseAuth from "../../auth/useAuth";
import Subheader from "../../secondary/subheader";
import { getUnits } from "../../secondary/unitConversions";
import { errorHandler, getLocalData } from "../../utils";
import RouteDefinitionGraph from "../graphs/routeDefinitionGraph";
import RouteDefinitionTimeTable from "../graphs/routeDefinitionTimeTable";
import { rowDataConversion } from "../pages/routeDefinition";

export default function OperationView({ viewType }) {
  const [data, setData] = useState([]);
  const [subheaderContent, setSubheaderContent] = useState([]);
  const { snackBarElement } = useContext(SnackBarContext);
  const units = getUnits();

  function updateSubheader(currentBlocks) {
    let [avg, max, min] = [
      0,
      currentBlocks[0]?.distance,
      currentBlocks[0]?.distance,
    ];
    currentBlocks.forEach((row) => {
      avg += row.distance;
      max = Math.max(row.distance, max);
      min = Math.min(row.distance, min);
    });
    avg = avg / currentBlocks.length;
    setSubheaderContent([
      {
        value: `~ ${Math.round(avg).toLocaleString()}`,
        label: `Average Distance (${unitLargeMap[units]})`,
      },
      {
        value: `~ ${Math.round(min).toLocaleString()}`,
        label: `Minimum Distance (${unitLargeMap[units]})`,
      },
      {
        value: `~ ${Math.round(max).toLocaleString()}`,
        label: `Maximum Distance (${unitLargeMap[units]})`,
      },
      { value: currentBlocks.length, label: "Number of Blocks" },
    ]);
  }

  useEffect(() => {
    if (data.length) updateSubheader(data);
  }, [data]);

  useEffect(() => {
    async function fetchData() {
      try {
        if (!UseAuth("get")) {
          window.location.assign("/login");
          return;
        }

        const { data: currentBlocks } = await getLocalData("blocks");

        if (!currentBlocks) return;

        let blocks = currentBlocks.filter(
          (row) => row?.tableData?.checked || row.checked
        );
        if (!blocks.length) {
          // checks for the possible edge case where no rows were selected (i.e. old, potentially buggy sim, hopefully only happened on dev)
          const { data: simData } = await getLocalData("simulation");
          blocks = currentBlocks.filter(
            (row) => row.endDepot == simData?.depot_id
          );
          if (!blocks.length && currentBlocks.length)
            //if everything goes wrong, just auto select all rows with depot matching first row
            blocks = currentBlocks.filter(
              (row) => row.endDepot == currentBlocks[0].endDepot
            );
        }
        blocks = blocks.map(rowDataConversion);

        updateSubheader(blocks);

        setData(blocks);
      } catch (e) {
        if (e.response)
          errorHandler(e, snackBarElement, "Failed to get vehicle data");
        else {
          console.log(e);
          snackBarElement.current.displayToast(
            "Something went wrong, try again later",
            "error"
          );
        }
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <br />
      <Container
        fixed
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      ></Container>
      <br />
      <Container fixed>
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <Subheader content={subheaderContent} />
          {viewType === "timetable" ? (
            <RouteDefinitionTimeTable
              data={data}
              updateSubheader={updateSubheader}
            />
          ) : (
            <RouteDefinitionGraph
              data={data}
              updateSubheader={updateSubheader}
            />
          )}
        </Paper>
      </Container>
      <br />
      <br />
    </div>
  );
}
