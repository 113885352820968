import InfoIcon from "@mui/icons-material/Info";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import Cookie from "js-cookie";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import currency from "../../../static/constants/currency.json";
import systems_of_measurement from "../../../static/constants/systems_of_measurement";
import { getLocalData } from "../../utils";
import EditAnalysisInputsForm, {
  updateIndexDb,
} from "../dialogs/editAnalysisInputs";

/**
 * React component that displays the simulation Name
 * and allows users to open a dialog box containing more simulation info
 */
export default function SimulationSubtitle({
  setRouteEnergyInputs = () => {},
  setBlockInputs = () => {},
  setBatteryInputs = () => {},
  setFinancialParamInputs = () => {},
}) {
  const simName = Cookie.get("simulation_name");
  const depotName = Cookie.get("depot_name");
  const projectId = Cookie.get("project_id");
  const [sim, setSim] = useState({});
  const [open, setOpen] = useState(false);
  const [isEditAnalysisInputsDialogOpen, setIsEditAnalysisInputsDialogOpen] =
    useState(false);

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      if (simName) {
        const { data: sim } = await getLocalData("simulation", "data");
        const { data: project } = await getLocalData("project", "data");
        if (isMounted) setSim({ ...sim, project });
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [simName]);

  /**
   * opens the editAnalysisInputs dialog, and closes the default dialog
   * @param {React.MouseEvent<HTMLButtonElement>} event
   */
  function handleEditAnalysisInputsOpen(event) {
    setIsEditAnalysisInputsDialogOpen(true);
    setOpen(false);
  }

  function handleAnalysisInputsSubmit(event) {
    event.preventDefault();
    setBlockInputs(updateIndexDb("blocks", event));
    setRouteEnergyInputs(updateIndexDb("routeEnergy", event));
    setBatteryInputs(updateIndexDb("battery", event));
    // setFinancialParamInputs(updateIndexDb("financial", event));
    setIsEditAnalysisInputsDialogOpen(false);
  }

  return simName ? ( //if no simName, don't display subtitle
    <>
      <Container fixed>
        <Typography
          variant="h6"
          align="left"
          style={{ fontWeight: 500, color: "darkgrey" }}
        >
          {simName}
          <IconButton
            size="small"
            title="View General Analysis Info"
            onClick={() => setOpen(true)}
            tabIndex={-1}
          >
            <InfoIcon />
          </IconButton>
          <IconButton
            size="small"
            component={Link}
            title="Return to Project's Analyses"
            to={`/simulations?projectId=${projectId}`}
            tabIndex={-1}
          >
            <SubdirectoryArrowLeftIcon className="rotate-icon-3" />
          </IconButton>
        </Typography>
      </Container>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Analysis: {sim.name}</DialogTitle>
        <DialogContent>
          {sim.description ? (
            <div style={{ marginBottom: "2%" }}>
              <Typography component="span" className="boldText">
                Description:
              </Typography>
              <Typography component="span">{sim.description}</Typography>
            </div>
          ) : undefined}
          {sim.analysis_type ? (
            <div style={{ marginBottom: "2%" }}>
              <Typography component="span" className="boldText">
                Analysis&nbsp;Type:
              </Typography>
              <Typography component="span">
                {TYPE_STRINGS.SIMULATION_TYPE?.[sim.analysis_type]}
              </Typography>
            </div>
          ) : undefined}
          <div style={{ marginBottom: "2%" }}>
            <Typography component="span" className="boldText">
              Project:
            </Typography>
            <Typography component="span">{sim.project?.name}</Typography>
          </div>
          <div style={{ marginBottom: "2%" }}>
            <Typography component="span" className="boldText">
              Depot:
            </Typography>
            <Typography component="span">{depotName || "undefined"}</Typography>
          </div>
          <div style={{ marginBottom: "2%" }}>
            <Typography component="span" className="boldText">
              Status:
            </Typography>
            <Typography component="span">
              {sim.completed ? "Editing" : "In-Progress"}
            </Typography>
          </div>
          <div style={{ marginBottom: "2%" }}>
            <Typography component="span" className="boldText">
              Currency:
            </Typography>
            <Typography component="span">
              {currency?.[sim.project?.currency_code]?.name}
            </Typography>
          </div>
          <div style={{ marginBottom: "2%" }}>
            <Typography component="span" className="boldText">
              Units:
            </Typography>
            <Typography component="span">
              {systems_of_measurement?.[sim.project?.unit]}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          {sim?.analysis_type_steps?.depot_energy_analysis
            ?.annual_load_profile && (
            <Button onClick={handleEditAnalysisInputsOpen}>
              Analysis Settings
            </Button>
          )}
          <Button onClick={() => setOpen(false)} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        component="form"
        open={isEditAnalysisInputsDialogOpen}
        onSubmit={handleAnalysisInputsSubmit}
        onClose={() => setIsEditAnalysisInputsDialogOpen(false)}
        sx={{ p: 2 }}
        maxWidth="md"
      >
        <DialogTitle>Edit Analysis Inputs</DialogTitle>
        <DialogContent>
          <EditAnalysisInputsForm
            handleClose={() => setIsEditAnalysisInputsDialogOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <>
      {/* Maybe Put Some Error Message Here, like "No Simulation Selected/Created" or Something */}
    </>
  );
}
