import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { DataContext } from "../../../contexts/dataContext";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import {
  simulationURL,
  tariffURL,
  tcoURL,
  vehicleURL,
} from "../../../static/constants/backendRoutes";
import { defaultFinancialParamInput } from "../../../static/constants/defaultInputs";
import stepInfo from "../../../static/constants/stepInfo";
import {
  unitGGEMap,
  unitVolumeMap,
} from "../../../static/constants/systems_of_measurement";
import UseAuth from "../../auth/useAuth";
import { AssessmentAnalysisStepper } from "../../secondary/steppers";
import {
  currencySymbol,
  getUnits,
  unitGGE,
  unitGallons,
  unitMoney,
  unitMoneyWithoutRounding,
  unitPerGGE,
  unitPerGallon,
  unitPerMoney,
} from "../../secondary/unitConversions";
import {
  errorHandler,
  getLocalData,
  roundNumber,
  storeLocalData,
  unitWrapper,
} from "../../utils";
import { NextPageButton } from "../commonComponents";
import DetailedEnergyInput from "../dialogs/detailedEnergyInput";
import { FinancialParamInputs } from "../dialogs/editAnalysisInputs";
import SimulationSubtitle from "../dialogs/simulationSubtitle";
import MemoizedFinancialAnalysisTable from "../tables/financialAnalysisTable";
import TariffImportTable from "../tables/tariffImportTable";

const STEP_NUMBER = 6;

const vehicleConstants = {
  constants: {
    BEVeff_Num: 1,
    ICEeff_Num: 0.2,
    oneGalEq_kWh: 40,
    ICEMpg: 4,
  },
};

// Array of month names
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const chipMap = [
  `Previous Step: ${stepInfo[STEP_NUMBER - 1].label}`,
  "Conventional Vehicle",
  "Battery Electric Vehicle",
  "Charger",
  "Infrastructure Upgrade",
  "Energy",
  "Grid Emissions",
  "Fuel",
  `Run ${stepInfo[STEP_NUMBER].label} Analysis`,
];

export { chipMap as financialChipMap };

export default function FinancialAnalysis() {
  const [open, setOpen] = useState(false);
  const [goBackDaialog, setGoBackDialog] = useState(false);
  //todo: consider combining vehicle, charger, and energyChip data into a single object
  const [vehicleChipData, setVehicleChipData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [chargerChipData, setChargerChipData] = useState([]);
  const [energyChipData, setEnergyChipData] = useState([]);
  const [dataFetchError, setDataFetchError] = useState(false);
  const [vehicles, setVehicles] = useState([]); // used for the vehicle model info card pop-up in battery Electric vehicle chip
  const [isSaveTariffDialog, setIsSaveTariffDialog] = useState(false); //used when exporting energy inputs to depot

  /** @type {[{ interest: Number, depreciation: Number, discount: Number, energySaved: string, fuelSaved: string, energyCosts: {energy: Number, demand: Number}[][], fuelCosts: {ICE: Number, PROP: Number, CNG: Number, GAS: Number}[]}]} */
  const [financialInputs, setFinancialInputs] = useState({
    ...defaultFinancialParamInput,
    energyCosts: Array(12)
      .fill()
      .map(() => Array(24).fill({ energy: 0.0, demand: 0.0 })),
    energySaved: 1,
    fuelCosts: Array(12).fill({ ICE: 4.17, PROP: 3.29, CNG: 2.85, GAS: 2.85 }),
    fuelSaved: 1,
  });

  /** @type {[1|2|3|"import"|undefined]} 1 for yearly, 2 for monthly, 3 for detailed, and "import" for import */
  const [isChargeInputDialogOpen, setIsChargeInputDialogOpen] = useState();

  const isChargeInputSavedLookup = {
    5: financialInputs.energySaved,
    7: financialInputs.fuelSaved,
  };
  const monthlyInputsDataLookup = {
    5: financialInputs.energyCosts.map((i) => i[0]),
    7: financialInputs.fuelCosts,
  };

  const [selectedChip, setSelectedChip] = useState(1); // 1 for Battery Electrict Vehicle, 2 for convenional vehicle, 3 for Charger, 5 for Energy

  const { accessRights } = useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);

  const navigate = useNavigate();

  const units = getUnits();

  useEffect(() => {
    async function fetchData() {
      try {
        if (!UseAuth("get")) {
          window.location.assign("/login");
        } else {
          const headers = {
            Authorization: `Token ${UseAuth("get")}`,
            "Content-Type": "application/json",
          };
          axios
            .get(vehicleURL, { headers })
            .then(({ data: { data } }) => {
              if (data?.length) setVehicles(data);
            })
            .catch((e) =>
              errorHandler(e, snackBarElement, "Failed to get vehicle data")
            );

          //gets the financial data from the indexdb
          const financialValues = await getLocalData("financial");
          const financialData = financialValues?.data;
          const financialParamInputs = financialValues?.input;
          //if financial param inputs ({interest, discount, depreciation}) were found previously stored
          /// then replace the defaults with the previously stored values

          if (financialParamInputs?.energyCosts) {
            const energySavedLookup = {
              //used to convert old steps from strings to numbers without needing to version entire file
              1: 1,
              2: 2,
              3: 3,
              yearly: 1,
              monthly: 2,
              detailed: 3,
            };
            const energySaved =
              energySavedLookup[financialParamInputs.energySaved];
            const fuelSaved = energySavedLookup[financialParamInputs.fuelSaved];
            const months = monthNames.map((m) => m.toLowerCase());
            const energy = months.map(
              (i) => financialParamInputs.energyCosts.energy[i]
            );
            const demand = months.map(
              (i) => financialParamInputs.energyCosts.demand[i]
            );
            const ICE = months.map(
              (i) => financialParamInputs.fuelCosts.ICE[i]
            );
            const PROP = months.map(
              (i) => financialParamInputs.fuelCosts.PROP[i]
            );
            const CNG = months.map(
              (i) => financialParamInputs.fuelCosts.CNG[i]
            );
            const GAS = months.map(
              (i) => financialParamInputs.fuelCosts.GAS[i]
            );

            setFinancialInputs({
              ...financialParamInputs,
              energySaved,
              energyCosts: Array(12)
                .fill()
                .map((_, row) =>
                  Array(24)
                    .fill()
                    .map((_, col) => ({
                      energy: energy[row][col],
                      demand: demand[row][col],
                    }))
                ),
              fuelSaved,
              fuelCosts: Array(12)
                .fill()
                .map((_, row) => ({
                  ICE: ICE[row],
                  PROP: PROP[row],
                  CNG: CNG[row],
                  GAS: GAS[row],
                })),
            });
          }
          //the ...financialParamInputs allows for easy overwriting of the interest, depreciation, and discount values, if they exist
          else
            setFinancialInputs({
              ...financialInputs,
              ...financialParamInputs,
              energyCosts: Array(12)
                .fill()
                .map((_, row) =>
                  Array(24)
                    .fill()
                    .map((_, col) => ({
                      energy: financialData?.energy?.[0]?.elecCostEnergy,
                      demand: financialData?.energy?.[0]?.elecCostDemand,
                    }))
                ),
            });

          const vehicleTableData = financialData?.vehicle || [];
          vehicleTableData.forEach(
            (row) => (row.tableData = { ...row?.tableData, uuid: uuidv4() })
          );
          const chargerTableData = financialData?.charger || [];

          chargerTableData.forEach(
            (row) => (row.tableData = { ...row?.tableData, uuid: uuidv4() })
          );
          //note: iucChipData and emissionData are both part of energyData
          const energyTableData = financialData?.energy || [];

          setVehicleChipData(vehicleTableData);
          setChargerChipData(chargerTableData);
          setEnergyChipData(energyTableData);
          setDataFetchError(true);
        }
      } catch (e) {
        console.log(e);
        setDataFetchError(true);
      }
    }

    fetchData();
  }, []);

  const monthlyColumnLookup = {
    5: [
      //energy
      {
        title: <>Energy Charges {unitWrapper(`(${currencySymbol()}/kWh)`)}</>,
        field: "energy",
        unitConversion: (v) => unitMoneyWithoutRounding(v),
        perUnitConversion: (v) => unitPerMoney(v),
      },
      {
        title: <>Demand Charges {unitWrapper(`(${currencySymbol()}/kW)`)}</>,
        field: "demand",
        unitConversion: (v) => unitMoneyWithoutRounding(v),
        perUnitConversion: (v) => unitPerMoney(v),
      },
    ],
    7: [
      //fuel
      {
        title: (
          <>
            Diesel{" "}
            {unitWrapper(`(${currencySymbol()}/${unitVolumeMap[units]})`)}
          </>
        ),
        field: "ICE",
        unitConversion: (v) => roundNumber(unitPerGallon(unitMoney(v))),
        perUnitConversion: (v) => unitGallons(unitPerMoney(v)),
      },
      {
        title: (
          <>
            Gasoline{" "}
            {unitWrapper(`(${currencySymbol()}/${unitVolumeMap[units]})`)}
          </>
        ),
        field: "GAS",
        unitConversion: (v) => roundNumber(unitPerGallon(unitMoney(v))),
        perUnitConversion: (v) => unitGallons(unitPerMoney(v)),
      },
      {
        title: (
          <>
            Propane{" "}
            {unitWrapper(`(${currencySymbol()}/${unitVolumeMap[units]})`)}
          </>
        ),
        field: "PROP",
        unitConversion: (v) => roundNumber(unitPerGallon(unitMoney(v))),
        perUnitConversion: (v) => unitGallons(unitPerMoney(v)),
      },
      {
        title: (
          <>CNG {unitWrapper(`(${currencySymbol()}/${unitGGEMap[units]})`)}</>
        ),
        field: "CNG",
        unitConversion: (v) => roundNumber(unitPerGGE(unitMoney(v))),
        perUnitConversion: (v) => unitGGE(unitPerMoney(v)),
      }, //compressed natural gas
      //hides the fuel types that were not included in the conventional vehicle chipview
    ].filter(({ field }) => vehicleChipData.find((veh) => veh.fuel == field)),
  };

  /**
   * note: not super efficient, but it'll work. maybe come back and improve efficiency later
   * function to help format the inputs before storing in indexDb & backend steps
   * @param {*} key
   * @param {*} data
   */
  function inputFormatHelper(key, data) {
    if (key === "energy" || key === "demand")
      return data.reduce(
        (obj, rowData, index) => ({
          ...obj,
          [monthNames[index].toLowerCase()]: rowData.map((i) => i[key]),
        }),
        {}
      );
    else
      return data.reduce(
        (obj, rowData, index) => ({
          ...obj,
          [monthNames[index].toLowerCase()]: rowData[key],
        }),
        {}
      );
  }

  //for some unknown reason dexie's .update isn't changing the response of dexie's
  // bulkGet, so I have to do the entire storage update as a .put
  function storeInput(data, type) {
    const formattedData = {
      ...financialInputs,
      energyCosts: {
        energy: inputFormatHelper("energy", financialInputs.energyCosts),
        demand: inputFormatHelper("demand", financialInputs.energyCosts),
      },
      fuelCosts: {
        ICE: inputFormatHelper("ICE", financialInputs.fuelCosts),
        PROP: inputFormatHelper("PROP", financialInputs.fuelCosts),
        CNG: inputFormatHelper("CNG", financialInputs.fuelCosts),
        GAS: inputFormatHelper("GAS", financialInputs.fuelCosts),
      },
    };
    switch (selectedChip) {
      case 5:
        storeLocalData("financial", {
          input: {
            ...formattedData,
            energyCosts: {
              energy: inputFormatHelper("energy", data),
              demand: inputFormatHelper("demand", data),
            },
            energySaved: type,
          },
        });
        break;
      case 7:
        storeLocalData("financial", {
          input: {
            ...formattedData,
            fuelCosts: {
              ICE: inputFormatHelper("ICE", data),
              PROP: inputFormatHelper("PROP", data),
              CNG: inputFormatHelper("CNG", data),
              GAS: inputFormatHelper("GAS", data),
            },
            fuelSaved: type,
          },
        });
        break;
    }
  }

  const runTCO = async () => {
    setButtonLoading(true);
    try {
      const [{ data: evAssessmentData }, { input: routeEnergyInputs }] =
        await Promise.all([
          getLocalData("evAssessment", "data"),
          getLocalData("routeEnergy", "input"),
        ]);

      if (
        !evAssessmentData ||
        (Object.keys(evAssessmentData).length === 0 &&
          evAssessmentData.constructor === Object)
      ) {
        //if the localDb retrieval failed to get the routeEnergyPageSelectedRows
        snackBarElement.current.displayToast(
          "Couldn't find local evAssessmentData, please go back a page and try again",
          "error",
          10000
        );
        console.log("couldn't find the previous page's selected rows");
        setButtonLoading(false);
      } else {
        const { data: sim } = await getLocalData("simulation", "data");

        const inputStorage = {
          ...financialInputs,
          energyCosts: {
            energy: inputFormatHelper("energy", financialInputs.energyCosts),
            demand: inputFormatHelper("demand", financialInputs.energyCosts),
          },
          fuelCosts: {
            ICE: inputFormatHelper("ICE", financialInputs.fuelCosts),
            PROP: inputFormatHelper("PROP", financialInputs.fuelCosts),
            CNG: inputFormatHelper("CNG", financialInputs.fuelCosts),
            GAS: inputFormatHelper("GAS", financialInputs.fuelCosts),
          },
        };

        let vehicleModelLookup = {}; //used to quickly determine if a vehicle model has been found yet, and if so, it's index, in the below vehicleChipData reduce
        //merge all duplicate vehicle models's fuel types into single vehicle models of all the model's fuel-specific data
        const mergedVehicleChipData = vehicleChipData.reduce(
          (mergedVehArr, currVeh, i) => {
            if (!isNaN(vehicleModelLookup[currVeh.model])) {
              const fuel = currVeh.fuel;
              const mergedVeh = mergedVehArr[vehicleModelLookup[currVeh.model]];
              mergedVeh.fuel = [...mergedVeh.fuel, currVeh.fuel];
              mergedVeh[`${fuel.toLowerCase()}_veh_cost`] =
                currVeh[`${fuel.toLowerCase()}_veh_cost`];
              mergedVeh[`${fuel.toLowerCase()}_veh_mpg`] =
                currVeh[`${fuel.toLowerCase()}_veh_mpg`];
              mergedVeh[`maintenanceVeh${fuel}`] =
                currVeh[`maintenanceVeh${fuel}`];
              return mergedVehArr;
            } else {
              vehicleModelLookup[currVeh.model] = i;
              return [...mergedVehArr, { ...currVeh, fuel: [currVeh.fuel] }];
            }
          },
          []
        );

        const body = {
          capitalCost: {
            years: [
              {
                year: 0,
                vehicles: mergedVehicleChipData.map((vehicle) => ({
                  model: vehicle.model,
                  ICE: { vehCost: vehicle.ice_veh_cost },
                  PROP: { vehCost: vehicle.prop_veh_cost },
                  CNG: { vehCost: vehicle.cng_veh_cost },
                  GAS: { vehCost: vehicle.gas_veh_cost },
                  BEV: {
                    vehCost: vehicle.bev_veh_cost,
                    BatteryCost: vehicle.batteryRepCost,
                    BatteryResale: vehicle.batteryResValue,
                    incentive: vehicle.incentiveVeh, // hard-coded to 0
                    subsidy: 0,
                  },
                })),
                chargers: chargerChipData.map((charger) => ({
                  model: charger.model,
                  cost: charger.cost,
                  subsidy: 0,
                  incentive: charger.incentiveCharger, // hard-coded to 0
                  installAndCommission: charger.incCost,
                })),
                other: {
                  infra: { cost: energyChipData[0].infraUpCost },
                },
              },
            ],
          },
          operatingCost: {
            years: [
              {
                year: 0,
                vehicles: [
                  {
                    // grab the last existing instance of the fuel type here, if it doesn't exist, send the default
                    type: "BEV",
                    maintenance: mergedVehicleChipData[0].maintenanceVehBEV,
                  },
                  ...Object.values(
                    //temporary fix, until backend updated to handle multiple models with different maintenance,
                    /// grabs the last valid instance of each fuel type's maintenance values
                    mergedVehicleChipData.reduce(
                      (rem, curr) => {
                        curr?.fuel?.forEach(
                          (fuel) =>
                            (rem[fuel].maintenance =
                              curr[`maintenanceVeh${fuel}`])
                        );
                        return rem;
                      },
                      {
                        //defaults
                        ICE: {
                          type: "ICE",
                          maintenance:
                            mergedVehicleChipData[0].maintenanceVehICE,
                        },
                        CNG: {
                          type: "CNG",
                          maintenance:
                            mergedVehicleChipData[0].maintenanceVehCNG,
                        },
                        GAS: {
                          type: "GAS",
                          maintenance:
                            mergedVehicleChipData[0].maintenanceVehGAS,
                        },
                        PROP: {
                          type: "PROP",
                          maintenance:
                            mergedVehicleChipData[0].maintenanceVehPROP,
                        },
                      }
                    )
                  ),
                ],
                chargers: {
                  chargerOM: chargerChipData[0].chargerOM,
                },
                other: {},
              },
            ],
          },
          fleetAndCharger: {
            general: {
              vehicles: evAssessmentData.daily_total_distance.map((ev) => {
                const vehicle = mergedVehicleChipData.find(
                  (v) => v.model == ev.vehicle_model
                );
                return {
                  model: ev.vehicle_model,
                  energyUsageperDay:
                    evAssessmentData.daily_energy_consumption.find(
                      (eConsump) => eConsump.vehicle_model == ev.vehicle_model
                    ).nec,
                  BEV: {
                    avgDistancePerDay: ev.distance,
                    operationDaysPerYear: evAssessmentData.operation_days,
                    vehLife: routeEnergyInputs.operating_year,
                    batLife: routeEnergyInputs.operating_year,
                  },
                  ICE: {
                    avgDistancePerDay: ev.distance,
                    operationDaysPerYear: evAssessmentData.operation_days,
                    vehLife: routeEnergyInputs.operating_year,
                    mpg: vehicle.ice_veh_mpg,
                  },
                  PROP: {
                    avgDistancePerDay: ev.distance,
                    operationDaysPerYear: evAssessmentData.operation_days,
                    vehLife: routeEnergyInputs.operating_year,
                    mpg: vehicle.prop_veh_mpg,
                  },
                  CNG: {
                    avgDistancePerDay: ev.distance,
                    operationDaysPerYear: evAssessmentData.operation_days,
                    vehLife: routeEnergyInputs.operating_year,
                    mpg: vehicle.cng_veh_mpg,
                  },
                  GAS: {
                    avgDistancePerDay: ev.distance,
                    operationDaysPerYear: evAssessmentData.operation_days,
                    vehLife: routeEnergyInputs.operating_year,
                    mpg: vehicle.gas_veh_mpg,
                  },
                };
              }),
              chargers: chargerChipData.map((charger) => ({
                model: charger.model,
                chargerLife: routeEnergyInputs.operating_year,
              })),
            },
            years: [
              {
                year: 0,
                vehicles: ["BEV", "ICE", "PROP", "CNG", "GAS"].reduce(
                  (prev, type) =>
                    prev.concat(
                      (sim.analysis_type_steps.fleet_and_charger_sizing
                        .depot_energy_analysis == "2.0.0"
                        ? evAssessmentData.fleet_size.feasible.model_breakdown
                        : evAssessmentData.fleet_size.breakdown
                      ).map((model) => ({
                        type,
                        model:
                          sim.analysis_type_steps.fleet_and_charger_sizing
                            .depot_energy_analysis == "2.0.0"
                            ? model.model
                            : model.vehicle_model,
                        count:
                          model[`${type == "BEV" ? "ev" : "ice"}_fleet_size`],
                      }))
                    ),
                  []
                ),
                chargers: [
                  //in the future, DEFINITELY MaYbE possibly (sure) allow more than one
                  {
                    model: evAssessmentData.selectedCharger,
                    count: evAssessmentData.num_chargers,
                  },
                ],
              },
            ],
          },
          finance: {
            interest: financialInputs.interest,
            discount: financialInputs.discount,
            depreciation: financialInputs.depreciation,
          },
          constants: vehicleConstants.constants,
          emissions: {
            BEV: {
              CO2: energyChipData[0].gridEmission,
              NOX: energyChipData[0].NOXEmission,
              SO2: energyChipData[0].SO2Emission,
              // NOX: NOT FOUND IN MASTERDATA
              // SO2 NOT FOUND IN MASTERDATA
            },
            ICE: energyChipData[0].ICE,
            GAS: energyChipData[0].GAS,
            PROP: energyChipData[0].PROP,
            CNG: energyChipData[0].CNG,
          },
          fuel_types: Array.from(
            new Set(
              mergedVehicleChipData.reduce(
                (fuel_types, { fuel }) => [...fuel_types, ...fuel],
                []
              )
            )
          ),
          power: [
            {
              type: "Uncontrolled",
              profile: evAssessmentData.load_profile["full_power"],
              maxPower: roundNumber(
                Math.max(...evAssessmentData.load_profile["full_power"])
              ),
            },
            {
              type: "low",
              profile: evAssessmentData.load_profile["low_power"],
              maxPower: roundNumber(
                Math.max(...evAssessmentData.load_profile["low_power"])
              ),
            },
            {
              type: "annual15",
              profile: evAssessmentData.load_profile["annual15"],
            },
          ],
          energy: [
            {
              type: "ICE",
              cost: inputStorage.fuelCosts.ICE,
              annualEsc: 0,
            },
            {
              type: "PROP",
              cost: inputStorage.fuelCosts.PROP,
              annualEsc: 0,
            },
            {
              type: "CNG",
              cost: inputStorage.fuelCosts.CNG,
              annualEsc: 0,
            },
            {
              type: "GAS",
              cost: inputStorage.fuelCosts.GAS,
              annualEsc: 0,
            },
            {
              type: "electricity",
              classification: { type: 1 },
              cost: inputStorage.energyCosts.energy,
              annualEsc: 0,
            },
            {
              type: "demand",
              classification: { type: 1 },
              cost: inputStorage.energyCosts.demand,
              annualEsc: 0,
            },
          ],
        };

        let headers = {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
          Accept: `application/json; version=${sim.analysis_type_steps.financial_and_emissions.tco}`,
        };

        await fetch(tcoURL, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        }).then((response) => {
          delete headers["Accept"];
          if (response.ok) {
            return response.json().then(async (tcoData) => {
              storeLocalData("tco", { data: tcoData });
              //update the financial collection in indexDb
              ///Note that this put doesn't do much other than handle any selection changes, as all other input/data changes are saved to localDb as they are made
              storeLocalData("financial", {
                data: {
                  vehicle: vehicleChipData,
                  charger: chargerChipData,
                  energy: energyChipData,
                },
                input: inputStorage,
              });

              const backendBody = {
                id: sim.id,
                completed: true,
                current_page: stepInfo[STEP_NUMBER + 1].route,
                steps: {
                  tco: tcoData,
                  financial: {
                    vehicle: vehicleChipData,
                    charger: chargerChipData,
                    energy: energyChipData,
                  },
                  input: {
                    financial: inputStorage,
                  },
                },
              };

              fetch(simulationURL, {
                method: "PATCH",
                headers: headers,
                body: JSON.stringify(backendBody),
              }).then((response) => {
                if (response.ok) {
                  snackBarElement.current.displayToast(
                    `${stepInfo[STEP_NUMBER].label} Analysis Complete`
                  );

                  navigate(stepInfo[STEP_NUMBER + 1].route);
                } else {
                  errorHandler(
                    response,
                    snackBarElement,
                    "Failed to Update Simulation backend"
                  );
                  setButtonLoading(false);
                }
              });
            });
          } else {
            errorHandler(
              response,
              snackBarElement,
              "Something went wrong, try again later"
            );
            setButtonLoading(false);
          }
        });
      }
    } catch (e) {
      snackBarElement.current.displayToast(
        "Something went wrong, try again later",
        "error"
      );
      console.log("unknown error", e);
      setButtonLoading(false);
    }
  };

  /** updates the financial params, and then closes the associated
   * financial params dialog box(s)
   * @param {React.FormEvent<HTMLDivElement>} event
   */
  const handleUpdateFinancialInputs = async (event) => {
    event.preventDefault();
    const newDataForm = new FormData(event.target);
    const newDataJSON = Object.fromEntries(newDataForm.entries());
    // const params = updateIndexDb("financial", event);
    const formattedInput = {
      interest: +newDataJSON.interestRate / 100,
      discount: +newDataJSON.discountRate / 100,
      depreciation: 0, //currently set as constant
    };

    const { input: originalParams } = await getLocalData("financial", "input");
    const newFinancialInput = { ...originalParams, ...formattedInput };
    storeLocalData("financial", { input: newFinancialInput });
    setFinancialInputs({ ...financialInputs, ...formattedInput });
    setOpen(false);
  };

  /**
   * Saves an energy input to the depot
   * @param {{utility: number, demand: number}[][]} tariff
   * @param {1|2|3} tariff_style //1="yearly", 2="monthly", 3="detailed"
   * @param {{name: string, utility_name: string}} formJSON
   */
  async function exportTariffToDepot(tariff, tariff_style, formJSON) {
    setButtonLoading(true);
    const { data: sim } = await getLocalData("simulation", "data");

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = {
      tariff_style,
      tariffs: {
        energy: inputFormatHelper("energy", tariff),
        demand: inputFormatHelper("demand", tariff),
      },
      name: formJSON.name,
      utility_name: formJSON.utility_name,
      depot_id: sim.depot_id,
      classification: { type: 1 },
    };

    fetch(tariffURL, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast("Saved to Depot");
          setIsSaveTariffDialog(false);
        } else
          errorHandler(response, snackBarElement, "Failed to save to depot.");
      })
      .catch((err) => {
        console.log(err);
        snackBarElement.current.displayToast(
          "Network Error: Something is not right, try again",
          "error"
        );
      })
      .finally(() => setButtonLoading(false));
  }

  /**
   * @param {React.FormEvent<HTMLDivElement>} event
   * @param {(1|2)} timeRange determines if the input came from the yearly (1) or monthly (2) input
   */
  const handleSaveYearlyInputs = (event, timeRange) => {
    event.preventDefault();
    const isYearly = timeRange == 1;
    const newDataForm = new FormData(event.target);
    const newDataJSON = Object.fromEntries(newDataForm.entries());
    let formattedData = Array(12)
      .fill()
      .map((_, i) => i);
    switch (selectedChip) {
      case 5:
        formattedData = formattedData.map((i) =>
          Array(24).fill({
            energy: monthlyColumnLookup[selectedChip]
              .find(({ field }) => field == "energy") //kinda overkill... same could be accomplished with a simple [0]
              .perUnitConversion(
                +newDataJSON[isYearly ? "energy" : `energy_${i}`]
              ),
            demand: monthlyColumnLookup[selectedChip]
              .find(({ field }) => field == "demand")
              .perUnitConversion(
                +newDataJSON[isYearly ? "demand" : `demand_${i}`]
              ),
          })
        );
        setFinancialInputs({
          ...financialInputs,
          energyCosts: formattedData,
          energySaved: timeRange,
        });
        break;
      case 7:
        //since not all the fuel types will always be included in the formJSON, overwrite ONLY the values that are in said JSON
        formattedData = formattedData.map((i) => {
          const combinedRow = financialInputs?.fuelCosts?.[i];
          monthlyColumnLookup[selectedChip].forEach(
            ({ field, perUnitConversion }) =>
              (combinedRow[field] = perUnitConversion(
                +newDataJSON[isYearly ? field : `${field}_${i}`]
              ))
          );
          return combinedRow;
        });
        setFinancialInputs({
          ...financialInputs,
          fuelCosts: formattedData,
          fuelSaved: timeRange,
        });
        break;
    }
    storeInput(formattedData, timeRange);

    //if saving tariff to backend
    if (isSaveTariffDialog)
      exportTariffToDepot(formattedData, timeRange, newDataJSON);
    else setIsChargeInputDialogOpen(undefined); //otherwise, simply close the dialog
  };

  /**
   * Formats and saves the detailed energy dialog inputs
   * @param {React.FormEvent<HTMLDivElement>} event
   */
  function handleSaveDetailedInputs(event) {
    event.preventDefault();
    const newDataForm = new FormData(event.target);
    const newDataJSON = Object.fromEntries(newDataForm.entries());

    const { periodData, dataMatrix } = JSON.parse(newDataJSON.detailedData);
    //generate a period lookup table, so that the outputValue computation is less expensive overall
    const periodLookup = { energy: [], demand: [] };
    periodData.forEach(({ period, energy, demand }) => {
      periodLookup.energy[period] = energy;
      periodLookup.demand[period] = demand;
    });

    //note: the conversion (see monthly/yearly input saves) is not performed here, as it is already performed in detaileEnergyInput.js
    const formattedData = dataMatrix.map((rowData) =>
      rowData.map((period) => ({
        energy: +periodLookup.energy[period],
        demand: +periodLookup.demand[period],
      }))
    );
    setFinancialInputs({
      ...financialInputs,
      energyCosts: formattedData,
      energySaved: 3,
    });
    storeInput(formattedData, 3);
    if (isSaveTariffDialog) exportTariffToDepot(formattedData, 3, newDataJSON);
    else setIsChargeInputDialogOpen(false);
  }

  const nextButtonClicked = () => {
    if (selectedChip === 7) {
      runTCO();
      return;
    }
    setSelectedChip((prev) => prev + 1);
  };

  const prevButtonClicked = () => {
    if (selectedChip === 1) {
      setGoBackDialog(true);
      return;
    }

    setSelectedChip((prev) => prev - 1);
  };

  return (
    <div>
      <br />
      <br /> <AssessmentAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      <br />
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          {/* replaces all spaces with non-breakling space equivalents */}
          {stepInfo[STEP_NUMBER].label.replaceAll(" ", "\xa0")}
        </Typography>
        <SimulationSubtitle />
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            minHeight: "280px",
            paddingX: "8px",
          }}
          elevation={3}
        >
          {selectedChip === 5 || selectedChip === 7 ? (
            <>
              <div>
                <Toolbar>
                  <Typography variant="h6">
                    {chipMap[selectedChip]} Inputs
                  </Typography>
                </Toolbar>
                <div style={{ margin: "4px 10px" }}>
                  <Stack direction="row" spacing={1}>
                    {chipMap.slice(1, 8).map((value, index) => (
                      <Chip
                        label={value}
                        key={`${value}_chip`}
                        variant={
                          index + 1 === selectedChip ? "filled" : "outlined"
                        }
                        onClick={() => setSelectedChip(index + 1)}
                      />
                    ))}
                  </Stack>
                </div>
              </div>
              <Grid container spacing={2} marginTop={2} marginLeft={2}>
                <Grid item xs={12} sm={selectedChip == 5 ? 3 : 4}>
                  <Button
                    variant={
                      isChargeInputSavedLookup[selectedChip] == 1
                        ? "contained"
                        : "outlined"
                    }
                    className="btn"
                    align="left"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => setIsChargeInputDialogOpen(1)}
                    startIcon={
                      isChargeInputSavedLookup[selectedChip] == 1 ? (
                        <EditIcon />
                      ) : (
                        <AddCircleOutlineIcon
                          fontSize="small"
                          style={{ marginRight: 4 }}
                        />
                      )
                    }
                  >
                    Yearly Inputs
                  </Button>
                </Grid>
                <Grid item xs={12} sm={selectedChip == 5 ? 3 : 4}>
                  <Button
                    variant={
                      isChargeInputSavedLookup[selectedChip] == 2
                        ? "contained"
                        : "outlined"
                    }
                    className="btn"
                    align="left"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => setIsChargeInputDialogOpen(2)}
                    startIcon={
                      isChargeInputSavedLookup[selectedChip] == 2 ? (
                        <EditIcon />
                      ) : (
                        <AddCircleOutlineIcon
                          fontSize="small"
                          style={{ marginRight: 4 }}
                        />
                      )
                    }
                  >
                    Monthly Inputs
                  </Button>
                </Grid>
                {selectedChip === 5 && (
                  <>
                    <Grid item xs={12} sm={3}>
                      <Button
                        variant={
                          isChargeInputSavedLookup[selectedChip] == 3
                            ? "contained"
                            : "outlined"
                        }
                        className="btn"
                        align="left"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => setIsChargeInputDialogOpen(3)}
                        startIcon={
                          isChargeInputSavedLookup[selectedChip] == 3 ? (
                            <EditIcon />
                          ) : (
                            <AddCircleOutlineIcon
                              fontSize="small"
                              style={{ marginRight: 4 }}
                            />
                          )
                        }
                      >
                        Detailed Inputs
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Button
                        variant="outlined"
                        className="btn"
                        align="left"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => setIsChargeInputDialogOpen("import")}
                        startIcon={
                          <AddCircleOutlineIcon
                            fontSize="small"
                            style={{ marginRight: 4 }}
                          />
                        }
                      >
                        Import Inputs
                      </Button>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} />
                <Grid item xs={4} />
                <Grid item sm={4}>
                  <Typography align="left" sx={{ fontStyle: "italic" }}>
                    {selectedChip === 5
                      ? "Select Yearly, Monthly, or Detailed Inputs"
                      : "Select Yearly or Monthly Inputs"}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <MemoizedFinancialAnalysisTable
              vehicles={vehicles}
              dataFetchError={dataFetchError}
              setVehicleChipData={setVehicleChipData}
              setChargerChipData={setChargerChipData}
              setEnergyChipData={setEnergyChipData}
              energyChipData={energyChipData}
              chargerChipData={chargerChipData}
              vehicleChipData={vehicleChipData}
              selectedChip={selectedChip}
              setSelectedChip={setSelectedChip}
            />
          )}
        </Paper>
      </Container>
      <br />
      <Container
        fixed
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Stack
          direction="column"
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={1}
          sx={{ width: "100%" }}
        >
          <Grid container justifyContent="flex-start">
            <Grid item xs={12} sm={6} md={6}>
              <Button
                className="btn"
                color="primary"
                sx={{ width: "95%" }}
                onClick={prevButtonClicked}
                variant="outlined"
                startIcon={<ArrowBackIosNew />}
              >
                {chipMap[selectedChip - 1]}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Button
                color="primary"
                variant="outlined"
                className="btn"
                sx={{ width: "95%" }}
                onClick={() => setOpen(true)}
              >
                Financial Parameters
              </Button>
            </Grid>
          </Grid>
          <NextPageButton
            onClick={nextButtonClicked}
            loading={buttonLoading}
            endIcon={<ArrowForwardIos />}
            disabled={
              !chargerChipData.length ||
              !vehicleChipData.length ||
              (selectedChip == 4 &&
                !accessRights.analysis.create_financial_and_emissions_analysis)
            }
          >
            {chipMap[selectedChip + 1]}
          </NextPageButton>
        </Stack>
      </Container>
      <br />
      <br />
      {/* Financial Parameters inputs form */}
      <Dialog
        component="form"
        onSubmit={handleUpdateFinancialInputs}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Financial Parameters</DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <FinancialParamInputs
              inputs={financialInputs}
              accessRights={accessRights}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={goBackDaialog} onClose={() => setGoBackDialog(false)}>
        <DialogContent>
          This will take you back to the previous step of{" "}
          {stepInfo[STEP_NUMBER - 1].label}. All your current changes will be
          undone if you go back.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGoBackDialog(false)}>Cancel</Button>
          <Button
            type="submit"
            component={Link}
            to={stepInfo[STEP_NUMBER - 1].route}
          >
            Yes, go back
          </Button>
        </DialogActions>
      </Dialog>
      {/* Energy/Fuel Yearly Inputs Dialog */}
      <Dialog
        id="energy_fuel_form"
        component="form"
        onSubmit={(e) => handleSaveYearlyInputs(e, 1)}
        open={isChargeInputDialogOpen == 1}
        onClose={() => setIsChargeInputDialogOpen(undefined)}
        fullWidth
      >
        <DialogTitle display="flex" justifyContent="space-between">
          Yearly Inputs
          {selectedChip == 5 && (
            <Button
              className="btn"
              variant="outlined"
              onClick={() => setIsSaveTariffDialog(true)}
            >
              Save to Depot
            </Button>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container mt={2} spacing={2}>
            {monthlyColumnLookup[selectedChip]?.map((entry) => (
              <Fragment key={entry.field}>
                <Grid item xs={12} sm={5}>
                  <Typography variant="body1" gutterBottom align="left">
                    Yearly {entry.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    required
                    name={entry.field}
                    defaultValue={entry.unitConversion(
                      monthlyInputsDataLookup[selectedChip]?.[0]?.[entry.field]
                    )}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: selectedChip === 7 ? 0.01 : 0.0001,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {currencySymbol()}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsChargeInputDialogOpen(undefined)}>
            Close
          </Button>
          <Button type="submit">Save and Proceed</Button>
        </DialogActions>
      </Dialog>
      {/* Energy/Fuel Monthly Inputs Dialog */}
      <Dialog
        id="energy_fuel_form"
        component="form"
        onSubmit={(e) => handleSaveYearlyInputs(e, 2)}
        open={isChargeInputDialogOpen == 2}
        onClose={() => setIsChargeInputDialogOpen(undefined)}
        maxWidth="md"
      >
        <DialogTitle display="flex" justifyContent="space-between">
          Monthly Inputs
          {selectedChip == 5 && (
            <Button
              className="btn"
              variant="outlined"
              onClick={() => setIsSaveTariffDialog(true)}
            >
              Save to Depot
            </Button>
          )}
        </DialogTitle>
        <DialogContent>
          {/* Monthly Charges Table */}
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Months</TableCell>
                {(monthlyColumnLookup[selectedChip] || []).map(({ title }) => (
                  <TableCell>{title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array(12)
                .fill()
                .map((_, monthIndex) => (
                  <TableRow key={monthNames[monthIndex]}>
                    <TableCell>{monthNames[monthIndex]}</TableCell>
                    {(monthlyColumnLookup[selectedChip] || []).map(
                      ({ field, unitConversion }) => (
                        <TableCell key={`${field}_${monthIndex}`}>
                          <TextField
                            required
                            defaultValue={unitConversion(
                              monthlyInputsDataLookup[selectedChip]?.[
                                monthIndex
                              ]?.[field]
                            )}
                            name={`${field}_${monthIndex}`}
                            variant="outlined"
                            size="small"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 0,
                                step: selectedChip === 7 ? 0.01 : 0.0001,
                              },
                              style: { height: "2rem" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  {currencySymbol()}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsChargeInputDialogOpen(undefined)}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </Dialog>
      {/* Detailed Inputs Dialog */}
      <Dialog
        id="energy_fuel_form"
        component="form"
        onSubmit={handleSaveDetailedInputs}
        open={isChargeInputDialogOpen == 3}
        onClose={() => setIsChargeInputDialogOpen(undefined)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle display="flex" justifyContent="space-between">
          Detailed Inputs
          {selectedChip == 5 && (
            <Button
              className="btn"
              variant="outlined"
              onClick={() => setIsSaveTariffDialog(true)}
            >
              Save to Depot
            </Button>
          )}
        </DialogTitle>
        <DialogContent>
          {/* Add content for Detailed Inputs here */}
          <DetailedEnergyInput initialData={financialInputs.energyCosts} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsChargeInputDialogOpen(undefined)}>
            Close
          </Button>
          <Button type="submit">Save and Proceed</Button>
        </DialogActions>
      </Dialog>
      {/* Import Tariff dialog */}
      <Dialog
        open={isChargeInputDialogOpen === "import"}
        onClose={() => setIsChargeInputDialogOpen(undefined)}
        maxWidth="lg"
        fullWidth
      >
        <TariffImportTable
          setFinancialInputs={setFinancialInputs}
          storeInput={storeInput}
          onClose={() => setIsChargeInputDialogOpen(undefined)}
        />
      </Dialog>
      {/* Export Tariff dialog */}
      <Dialog
        open={isSaveTariffDialog}
        onClose={() => setIsSaveTariffDialog(false)}
        fullWidth
      >
        <DialogTitle>Additional Inputs</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="space-evenly" pt={1}>
            <TextField
              inputProps={{ form: "energy_fuel_form" }}
              name="utility_name"
              required
              label="Utility Name"
              variant="outlined"
            />
            <TextField
              inputProps={{ form: "energy_fuel_form" }}
              name="name"
              required
              label="Tariff Name"
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSaveTariffDialog(false)}>Close</Button>
          <LoadingButton type="submit" form="energy_fuel_form">
            Save to Depot
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
