import { Stack } from "@mui/material";
import { latLngBounds } from "leaflet";
import { useContext, useMemo } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Tooltip as TooltipMap,
} from "react-leaflet";
import { DataContext } from "../../../contexts/dataContext";
import DepotResourceIcon from "../depotResourceIcon";

/**
 *
 * @param {Object} props
 * @param {Object[]} props.coordinates list of pins
 * @param {number} props.coordinates[].latitude
 * @param {number} props.coordinates[].longitude
 * @param {string} props.coordinates[].name for tooltip title
 * @returns
 */
function DepotMap(props) {
  const {
    coordinates, //coordinates of markers for map
    project_lookup, //lookup for project names associated with depots
    vehicleList,
    tariffList,
  } = props;

  const { organizationMemo } = useContext(DataContext);

  const coordinatesFiltered = useMemo(
    () =>
      coordinates.filter(
        (coordinate) =>
          !organizationMemo?.id ||
          coordinate.organization_id == organizationMemo.id
      ),
    [coordinates, organizationMemo]
  );

  const center = coordinatesFiltered
    .reduce(
      //calculates the average lat/long value, for the center of the map
      ([sum_latitude, sum_longitude], { latitude, longitude }) => [
        sum_latitude + latitude,
        sum_longitude + longitude,
      ],
      [0, 0]
    )
    .map((value) => value / coordinatesFiltered.length);

  let bounds = latLngBounds(); //init boundaries, so that map zooms to fit the coordinates
  coordinatesFiltered.forEach(
    ({ latitude, longitude }) => bounds.extend([latitude, longitude]) //calculate the north-eastern-most and south-western most boundaries
  );
  if (!Object.keys(bounds).length) {
    //if there were no points to focus/zoom in on, then default to the world map
    bounds.extend([-85, -175]);
    bounds.extend([85, 175]);
  }

  const pins = coordinatesFiltered.map((point, index) => {
    // Define the fields to display in the popup
    const fieldsToDisplay = [
      "address1", // Address line 1
      "address2", // Address line 2
    ];

    return (
      <Marker key={index} position={[point.latitude, point.longitude]}>
        <TooltipMap>
          <big>
            <b>{point.name}</b>
          </big>
        </TooltipMap>
        <Popup>
          <div style={{ fontSize: "1.5em" }}>
            <b>{point.name}</b>
          </div>
          {/* Display the name */}
          {fieldsToDisplay.map((key) => (
            <div style={{ fontSize: "1.25em" }} key={key}>
              {point?.[key]?.trim?.() || point?.[key]}
            </div>
          ))}
          <div>
            {point?.city?.trim?.()}, {point?.state?.trim?.()}
          </div>
          <div>{point?.country?.trim?.()}</div>
          <div>{point?.feeder_id && `Feeder: ${point.feeder_id.trim()}`}</div>
          <br />
          {/* Vehicle Resource Icon */}
          <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
            <DepotResourceIcon
              vehicleList={vehicleList}
              tariffList={tariffList}
              depot_id={point.id}
            />
          </Stack>
        </Popup>
      </Marker>
    );
  });

  return (
    //makes the map
    <MapContainer center={center} bounds={bounds}>
      <TileLayer
        // attribution text
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {pins}
    </MapContainer>
  );
}

export default DepotMap;
