import MaterialTable from "@material-table/core";
import AcUnit from "@mui/icons-material/AcUnit";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import EditIcon from "@mui/icons-material/Edit";
import WbSunny from "@mui/icons-material/WbSunny";
import { Chip, CircularProgress, Fade, Grid, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import introJs from "intro.js/intro";
import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";

import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import {
  depotURL,
  projectURL,
  resourceURL,
  tariffURL,
} from "../../static/constants/backendRoutes";
import { depotTourOptions } from "../../static/constants/tourOptions";
import UseAuth from "../auth/useAuth";
import Empty from "../secondary/empty";
import { ProjectStepper } from "../secondary/steppers";
import TourBeacon from "../secondary/tourBeacon";
import { unitTemperature } from "../secondary/unitConversions";
import { Icons, errorHandler, useQuery } from "../utils";
import DepotResourceIcon from "./depotResourceIcon";
import ClimateDetails from "./dialogs/climateDetails";
import DepotDialog from "./dialogs/depotDialog";
import DepotMap from "./maps/depotMap";

function Depot() {
  const projectId = useQuery().get("projectId");
  //todo: look into consolidating projectLookup and allProjects states
  const [projectLookup, setProjectLookup] = useState(undefined);
  const [allProjects, setAllProjects] = useState(undefined);
  const [depotData, setDepotData] = useState([]);

  const [vehicleList, setVehicleList] = useState([]);
  const [tariffList, setTariffList] = useState([]);

  const [showTable, setShowTable] = useState(true); //true for show table, false for show map
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [rowUpdateData, setRowUpdateData] = useState({});

  //displays error message in table if data failed to fetch/loading message if response not yet recieved
  const [dataFetchError, setDataFetchError] = useState(false);

  const [chartDialog, setChartDialog] = useState(false);
  const [chartData, setChartData] = useState(null);

  const { accessRights, organizationMemo } = useContext(DataContext);

  const { snackBarElement } = useContext(SnackBarContext);

  useEffect(() => {
    /**
     * checks if user is logged in,
     * retrieves created projects and associated depots
     * from the backend, and stores them both
     */
    function fetchData() {
      try {
        if (!UseAuth("get")) {
          window.location.assign("/login");
        } else {
          let headers = {
            Authorization: `Token ${UseAuth("get")}`,
            "Content-Type": "application/json",
          };
          //TODO: add in more error message states

          //retrieve all projects (Probably overkill to do this for individual projects)
          fetch(
            `${projectURL}?organization_id_list=-${
              Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
            }`,
            {
              method: "GET",
              headers: headers,
            }
          ).then((response) => {
            if (response.ok) {
              //fetches project-specific depots, or all depots if no ID is specified
              fetch(
                projectId
                  ? `${depotURL}?project_id_list=${projectId}`
                  : `${depotURL}?organization_id=-${
                      Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
                    }`,
                { method: "GET", headers: headers }
              ).then((res) => {
                if (res.ok) {
                  return res.json().then(({ data: depots }) => {
                    setDepotData(depots);
                    if (!depots.length) setDataFetchError(true);
                  });
                }
                //else
                errorHandler(res, snackBarElement);
                setDataFetchError(true);
              });

              return response.json().then(({ data: projects }) => {
                let project_lookup = {};

                projects.forEach((project) => {
                  //creates a dictionary of the project ID and their names
                  project_lookup[project.id] = project.name;
                });
                setProjectLookup(project_lookup);
                setAllProjects(projects);
              });
            }
            console.log(response);
            setDataFetchError(true);
          });

          //get all tariff resources associated with a project; or, if no project is specified, all tariffs associated with the user
          ///NOTE: Consider updating Depot backend to provide tariff count
          /// details with the "get all depots" queries, so that we can eleminate this query
          fetch(
            `${tariffURL}?type=2&project_id=${
              projectId ??
              `&organization_id=-${
                Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
              }`
            }`,
            { method: "GET", headers: headers }
          ).then((response) => {
            if (response.ok)
              response
                .json()
                .then(({ data: tariffs }) => setTariffList(tariffs));
            else {
              errorHandler(response, snackBarElement);
              setTariffList([]);
            }
          });

          //get all vehicle resources associated with a project; or, if no project is specified, all resources associated with the user
          ///NOTE: Consider updating Depot backend to provide ResourceInventory count
          /// details with the "get all depots" queries, so that we can eleminate this query
          fetch(
            `${resourceURL}?type=2&project_id=${
              projectId ??
              `&organization_id=-${
                Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
              }`
            }`,
            { method: "GET", headers: headers }
          ).then((response) => {
            if (response.ok)
              response
                .json()
                .then(({ data: vehicles }) => setVehicleList(vehicles));
            else {
              errorHandler(response, snackBarElement);
              setVehicleList([]);
            }
          });
        }
      } catch (e) {
        console.log(e);
        setDataFetchError(true);
      }
    }
    fetchData();
  }, [projectId, organizationMemo.lookup]);

  /**
   * deletes a depot
   * @param {Number[]} depot_id_list list of depots to delete
   * @returns {Number} 0 for success, -1 for failure
   */
  const handleDelete = useCallback(
    (depot_id_list) => {
      return fetch(depotURL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${UseAuth("get")}`,
        },
        body: JSON.stringify({ depot_id_list }),
      })
        .then((response) => {
          if (response.ok) {
            const updatedDepots = depotData.filter(
              (depot) => !depot_id_list.includes(depot.id)
            );
            setDepotData(updatedDepots);
            snackBarElement.current.displayToast("Depot Deleted!");
            if (!updatedDepots.length) setDataFetchError(true); //so that, if the final visible depot was deleted, the table doesn't display the loading icon endlessly
            return 0;
          }
          //else
          errorHandler(response, snackBarElement);
          return -1;
        })
        .catch((err) => {
          console.log(err);
          snackBarElement.current.displayToast(
            "Something is not right, try again",
            "error"
          );
          return -1;
        });
    },
    [depotData]
  );

  return (
    <div>
      <br />
      <br />
      <ProjectStepper stepNum={2} />
      <br />
      <br />
      {projectLookup &&
      // projectLookup !== undefined && // this line is unnecessary, as 'undefined' is falsy
      Object.keys(projectLookup).length > 0 ? (
        <div>
          <Container
            fixed
            maxWidth="xl"
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              align="left"
              className="page-title"
            >
              {projectId ? (
                <>
                  Current Project:{" "}
                  <span className="header-green">
                    {projectLookup[projectId]}
                  </span>
                </>
              ) : (
                <>All Depots Summary</>
              )}
            </Typography>{" "}
            <Stack direction="row" spacing={1}>
              <Chip
                label="Table"
                variant={showTable ? "filled" : "outlined"}
                disabled={showTable}
                onClick={() => setShowTable(true)}
              />
              <Chip
                label="Map"
                variant={showTable ? "outlined" : "filled"}
                disabled={!showTable}
                onClick={() => setShowTable(false)}
              />
            </Stack>
          </Container>
          <br />
          {showTable ? ( //show table vs show map
            <>
              <Container fixed maxWidth="xl">
                <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
                  {/* Render table in separate, memoized component in order to prevent table from re-rendering
                    NOTE: this memoization is no longer necessary here.
                  */}
                  <MemoizedDepotTable
                    projectId={projectId}
                    depotData={depotData}
                    projectLookup={projectLookup}
                    allProjects={allProjects}
                    vehicleList={vehicleList}
                    tariffList={tariffList}
                    dataFetchError={dataFetchError}
                    handleDelete={handleDelete}
                    setShowEditDialog={setShowEditDialog}
                    setRowUpdateData={setRowUpdateData}
                    accessRights={accessRights}
                    setChartDialog={setChartDialog}
                    setChartData={setChartData}
                  />
                </Paper>
              </Container>{" "}
              <br />
              <Container fixed>
                <Grid container spacing={1}>
                  <Grid className="grid-left" item xs={12} sm={6}>
                    <Button
                      variant="outlined"
                      className="btn"
                      component={Link}
                      to={"/project"}
                      startIcon={<ArrowBackIosNew />}
                    >
                      Return To Projects
                    </Button>
                  </Grid>
                  {projectId && (
                    <Grid className="grid-right" item xs={12} sm={6}>
                      <Button
                        id="viewProjectAnalysis"
                        variant="outlined"
                        className="btn"
                        component={Link}
                        to={`/simulations?projectId=${projectId}`}
                      >
                        View Project Analyses
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Container>
            </>
          ) : (
            <Container>
              <Paper elevation={3}>
                <DepotMap
                  tariffList={tariffList}
                  vehicleList={vehicleList}
                  coordinates={depotData}
                  project_lookup={projectLookup}
                />
              </Paper>
            </Container>
          )}

          <ClimateDetails
            chartData={chartData}
            setChartData={setChartData}
            setChartDialog={setChartDialog}
            chartDialog={chartDialog}
            depotData={depotData}
            setDepotData={setDepotData}
          />
          {/* add/edit depot dialog box */}
          <DepotDialog
            open={showEditDialog}
            setOpen={setShowEditDialog}
            presetProjectId={projectId}
            allProjects={allProjects}
            projectLookup={projectLookup}
            allDepots={depotData}
            setAllDepots={setDepotData}
            rowUpdateData={rowUpdateData}
            setRowUpdateData={setRowUpdateData}
          />
        </div>
      ) : dataFetchError ? (
        //if the project_lookup fetch succeeded, but another fetch failed, display this message
        <Empty
          text={
            <>
              No Projects Created. Create one <Link to="/project">here</Link>{" "}
              and come back
            </>
          }
        />
      ) : (
        // displayed when fetching projects for project_lookup table
        <Fade in={true}>
          <div className="centered">
            <CircularProgress />
          </div>
        </Fade>
      )}
    </div>
  );
}

/**
 * A memoized table that avoids re-rendering the table when disabling the
 * pagination buttons (i.e. you only need to click the edit button once because of this)
 */
const MemoizedDepotTable = memo(
  ({
    setChartDialog,
    setChartData,
    projectId,
    depotData,
    projectLookup,
    allProjects,
    tariffList,
    vehicleList,
    dataFetchError,
    handleDelete,
    setShowEditDialog,
    setRowUpdateData,
    accessRights,
  }) => {
    const [isTourActive, setIsTourActive] = useState(false);

    const { organizationMemo } = useContext(DataContext);

    const depotDataFiltered = useMemo(
      () =>
        depotData.filter(
          (depot) =>
            !organizationMemo?.id ||
            depot.organization_id == organizationMemo.id
        ),
      [depotData, organizationMemo]
    );

    /** @type {import("@material-table/core").Column<never>[]} */
    const columns = [
      //format for the table
      { title: "Name", field: "name" },
      {
        title: "Full Address",
        field: "address1",
        render: (rowData) => {
          const fullAddressArray = [
            rowData.address1,
            rowData.address2,
            rowData.city,
            rowData.state,
            rowData.country,
            rowData.zipcode,
          ];
          const fullAddressString = fullAddressArray.reduce(
            (acc, el, index) => {
              let addressComma = el?.length > 0 && index !== 0 ? "," : "";
              let adressSpacing =
                addressComma?.length > 0 && index !== 0 ? " " : "";
              return `${acc}${addressComma}${adressSpacing}${el}`;
            },
            ""
          );
          return (
            <div style={{ fontSize: "0.85rem" }}>{`${fullAddressString}`}</div>
          );
        },
        customFilterAndSearch: (term, rowData) => {
          const fullAddressArray = [
            rowData.address1,
            rowData.address2,
            rowData.city,
            rowData.state,
            rowData.country,
            rowData.zipcode,
          ];
          return fullAddressArray
            .reduce((acc, el, index) => {
              let addressComma = el?.length > 0 && index !== 0 ? "," : "";
              let adressSpacing =
                addressComma?.length > 0 && index !== 0 ? " " : "";
              return `${acc}${addressComma}${adressSpacing}${el}`;
            }, "")
            .toUpperCase()
            .includes(term.toUpperCase());
        },
      },
      {
        title: "Current Resources",
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
        render: (rowData) => (
          <DepotResourceIcon
            tariffList={tariffList}
            vehicleList={vehicleList}
            depot_id={rowData.id}
          />
        ),
      },
      {
        title: "Climate",
        field: "climate.annual_temperature",
        headerStyle: { textAlign: "center" },
        sorting: false,
        render: (rowData) => {
          const unit =
            allProjects?.find((project) => rowData.project_id == project.id)
              ?.unit || 2;
          return (
            <Button
              id="climateData"
              variant="outlined"
              className="btn"
              onClick={() => {
                setChartDialog(true);
                setChartData({ ...rowData, unit });
              }}
            >
              &nbsp;
              <WbSunny style={{ color: "gold" }} />
              &nbsp;
              {Math.round(
                unitTemperature(
                  rowData.climate.annual_temperature.maximum_temperature,
                  unit
                )
              )}
              {unit == 1 ? <>&#8451;</> : <>&#8457;</>}
              &nbsp;&nbsp;to&nbsp;&nbsp;
              {Math.round(
                unitTemperature(
                  rowData.climate.annual_temperature.minimum_temperature,
                  unit
                )
              )}
              {unit == 1 ? <>&#8451;</> : <>&#8457;</>}&nbsp;
              <AcUnit style={{ color: "skyblue" }} />
              &nbsp;
            </Button>
          );
        },
      },
      {
        title: "Resources",
        field: "id",
        render: (rowData) => (
          <Button
            variant="outlined"
            className="btn"
            component={Link}
            to={`/resource-inventory?depotId=${rowData.id}`}
          >
            Edit/Add
          </Button>
        ),
      },
      {
        title: "Project",
        field: "project_id",
        lookup: projectLookup,
        //hides project column when projectId param is not provided (i.e. when viewing all depots)
        hidden: projectId?.length > 0,
      },
    ];

    const handleTourStart = () => {
      setIsTourActive(true);
      let tour = introJs();
      setTimeout(
        () =>
          tour
            .setOptions(depotTourOptions())
            .onexit(() => setIsTourActive(false))
            .start(),
        [100]
      );
    };

    //allows add Icon to be overwritten to open depot dialog map
    const addIcon = Icons("Depot").Add.render();

    return (
      <MaterialTable
        title="Create / Manage Depots"
        columns={columns}
        data={depotDataFiltered}
        icons={Icons("depot")}
        localization={{
          body: {
            emptyDataSourceMessage:
              dataFetchError || depotData.length ? (
                // if an error occurs in depot fetch, display message in the table here
                "No records to display"
              ) : (
                // until the point that an error occurs or the data is retrieved, display a loading message
                <>
                  <CircularProgress />
                  <br />
                  Loading...
                </>
              ),
            editRow: {
              deleteText:
                "Are you sure you want to delete this depot? Any associated analyses will also be deleted.",
            },
          },
          toolbar: {
            searchPlaceholder: "Filter",
            searchTooltip: "Filter",
          },
        }}
        actions={[
          // {
          //   tooltip: "Remove Selected Rows",
          //   icon: () => <DeleteIcon />,
          //   onClick: (event, rowData) => {
          //     new Promise((resolve, reject) => {
          //       setTimeout(() => {
          //         let depot_list = [];
          //         rowData.forEach((rd) => {
          //           depot_list.push(rd.id);
          //         });
          //         handleDelete(depot_list);
          //         resolve();
          //        // setRefreshKey((oldKey) => oldKey + 1);
          //       }, 1000);
          //     });
          //   },
          // },
          {
            icon: () => <TourBeacon component="div" />,
            tooltip: "Tutorial",
            isFreeAction: true,
            onClick: handleTourStart,
            hidden: isTourActive || !depotDataFiltered.length, //|| !accessRights.projects.create_depot,
          },
          {
            icon: forwardRef((props, ref) => addIcon),
            tooltip: "Add Depot",
            isFreeAction: true,
            onClick: (event) => {
              setRowUpdateData({
                project_id: projectId || "",
                country_code: projectId
                  ? allProjects.find((project) => project.id == projectId)
                      ?.country_code
                  : "",
              });
              setShowEditDialog(true);
            },
            // hidden: !accessRights.projects.create_depot,
          },
          {
            icon: EditIcon,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              setRowUpdateData(rowData);
              setShowEditDialog(true);
            },
            // hidden: !accessRights.projects.update_depot,
          },
        ]}
        editable={{
          onRowDelete:
            // (accessRights.projects.delete_depot || undefined) &&
            (oldData) =>
              new Promise((resolve, reject) => {
                handleDelete([oldData.id]).then((status) => {
                  if (status === 0) resolve();
                  else reject();
                });
              }),
        }}
      />
    );
  }
);

export default Depot;
