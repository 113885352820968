import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import backgroundImage from "../../static/images/homepage-background.svg";
import overviewImage from "../../static/images/laptop-overview.png";

export default function HomePage() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "65vh",
        position: "relative",
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div style={{ flex: 1, textAlign: "center", marginLeft: "7%" }}>
        <p style={{ fontSize: "60px", color: "white" }}>
          Electric Fleet Planning Simplified!
        </p>
        <p style={{ fontSize: "25px", color: "white" }}>
          Create, Collaborate and Update Your Fleet Electrification Plans
        </p>
        <Button
          variant="contained"
          className="btn"
          sx={{ borderRadius: "16px" }}
          component={Link}
          to={"project"}
          endIcon={<ArrowForwardIcon />}
        >
          Get Started
        </Button>
      </div>
      <div
        style={{
          flex: 1,
          paddingTop: "60px",
          paddingRight: "20px",
          alignItems: "center",
        }}
      >
        <img
          src={overviewImage}
          alt="Overview Image"
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
}
