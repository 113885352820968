import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import introJs from "intro.js/intro";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataContext } from "../contexts/dataContext";
import { SnackBarContext } from "../contexts/snackBarContext";
import TYPE_STRINGS from "../static/constants/TYPE_STRINGS";
import {
  depotURL,
  projectURL,
  resourceURL,
  simulationURL,
} from "../static/constants/backendRoutes";
import {
  assessmentStepZero,
  comparisonStepInfo,
} from "../static/constants/stepInfo";
import { fleetInputTourOptions } from "../static/constants/tourOptions";
import UseAuth from "./auth/useAuth";
import Empty from "./secondary/empty";
import TourBeacon from "./secondary/tourBeacon";
import { setAnalysisCookies } from "./secondary/unitConversions";
import { clearLocalDb, errorHandler, storeLocalData, useQuery } from "./utils";

/** a lookup of the values used to reset the analysis inputs (stepsInputs) on analysis type change
 * - lookup is based on the analysis type value
 */
const DefaultAnalysisInputs = {
  1: { input_method: 0 },
  2: { simulation_ids: [] },
};

/** The "Create Analysis" React Component Page */
function FleetInput() {
  const projectId = useQuery().get("projectId");

  const [projectList, setProjectList] = useState([]); //array of existing projects associated with current user
  const [depotLookup, setDepotLookup] = useState({}); //object that maps arrays of depots to project ID's
  const [resourceMap, setResourceMap] = useState({}); //an object mapping all the project and Depot Id's to arrays of their resources
  const [simulationList, setSimulationList] = useState([]); // an array of all existing simulations, to use for the comparison dropdowns
  const [isNavDisabled, setIsNavDisabled] = useState(true); //boolean representing that the selected project (and depot) has both vehicle and charger resources,

  /** @type {[{name: string, description: string, project_id: number, depot_id: number, analysis_type: number}]} */
  const [inputs, setInputs] = useState({});
  /** @type {[{input_method: number}|{simulation_ids: number[]}]} */
  const [stepsInputs, setStepsInputs] = useState(undefined); //0 for "unselected", 1 for "Import GTFS", 2 for "Manual Input"

  const [buttonLoading, setButtonLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true); //displays a loading button until the page has finished loading
  const [isTourActive, setIsTourActive] = useState(false);

  const { accessRights, analysisStepsViewMemo, organizationMemo } =
    useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext); //for toast messages

  let navigate = useNavigate();

  const filteredProjectList = projectList.filter(
    (project) =>
      !organizationMemo.id || organizationMemo.id == project.organization_id
  );

  const filteredSimulationList = simulationList.filter(
    (sim) =>
      sim.analysis_type == 1 &&
      sim.depot_id == inputs?.depot_id &&
      sim.project_id == inputs?.project_id &&
      sim.completed
  );

  //if user changes the selected organization (to something other than "all organizations"), reset the inputs
  useEffect(() => {
    function resetInputs() {
      setInputs({});
    }

    if (
      organizationMemo.id &&
      projectList.find((project) => project.id == inputs?.project_id)
        ?.organization_id != organizationMemo.id
    )
      resetInputs();
  }, [organizationMemo.id]);

  useEffect(() => {
    //useEffect that fetches all projects and depots on page initialization
    async function fetchData() {
      setPageLoading(true);
      if (!UseAuth("get")) {
        window.location.assign("/login");
      } else {
        let headers = {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
        };

        //todo: update these fetch's error/catch statements
        const projectPromise = fetch(
          `${projectURL}?organization_id_list=-${
            Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
          }`,
          {
            method: "GET",
            headers: headers,
          }
        )
          .then((response) => {
            if (response.ok) {
              return response
                .json()
                .then(({ data }) => data.reverse() || [])
                .catch((e) => {
                  console.log(e);
                  return [];
                });
            } else {
              errorHandler(response, snackBarElement);
              return [];
            }
          })
          .catch((e) => {
            console.log(e);
            return [];
          });

        const depotPromise = fetch(
          `${depotURL}?organization_id=-${
            Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
          }`,
          {
            method: "GET",
            headers: headers,
          }
        )
          .then((response) => {
            if (response.ok) {
              return response
                .json()
                .then(({ data }) => data || [])
                .catch((e) => {
                  console.log(e);
                  return [];
                });
            } else {
              errorHandler(response, snackBarElement);
              return [];
            }
          })
          .catch((e) => {
            console.log(e);
            return [];
          });

        const resourcePromise = fetch(
          `${resourceURL}?type=2&organization_id=-${
            Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
          }`,
          { method: "GET", headers: headers }
        )
          .then((response) => {
            if (response.ok) {
              return response
                .json()
                .then(({ data }) => data || [])
                .catch((e) => {
                  console.log(e);
                  return [];
                });
            } else {
              errorHandler(response, snackBarElement);
              return [];
            }
          })
          .catch((e) => {
            console.log(e);
            return [];
          });

        const [projectList, depotList, allResources] = await Promise.all([
          projectPromise,
          depotPromise,
          resourcePromise,
        ]);

        //fetches all simulations, for viewing in the comparison dropdown
        fetch(
          `${simulationURL}?organization_id=-${
            Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
          }`,
          {
            method: "GET",
            headers,
          }
        ).then((res) => {
          if (res.ok) {
            res.json().then(({ data }) => setSimulationList(data));
          }
        });

        if (projectList.length) setProjectList(projectList);
        else {
          setPageLoading(false);
          snackBarElement.current.displayToast(
            "No Projects Found",
            "error",
            5000
          );
        }

        let depot_lookup = {};
        //map each project Id to an array of all depots that project contains
        projectList.forEach((project) => {
          depot_lookup[project.id] = depotList.filter(
            (depot) => depot.project_id == project.id
          );
        });
        setDepotLookup(depot_lookup);

        let resource_lookup = { project: {}, depot: {} };

        //map arrays of each project's depot's resources to a project
        projectList.forEach((project) => {
          //assign a resource array to each project ID
          const depotIdArray = depot_lookup[project.id].map(
            (depot) => depot.id
          ); //an array of a project's depot's id's

          //TODO: improve these mapping functions
          //depot map
          depotIdArray.forEach(
            (depotId) =>
              (resource_lookup.depot[depotId] = allResources.filter(
                (resource) => resource.depot_id == depotId
              ))
          );
          //project map
          resource_lookup.project[project.id] = allResources.filter(
            (resource) =>
              //creates an array of all the project's depot's resources
              depotIdArray.includes(resource.depot_id)
          );
        });

        //a check to ensure that at least one depot has resources
        if (Object.values(resource_lookup.depot)?.flatMap((i) => i).length) {
          setResourceMap(resource_lookup);
        } else {
          snackBarElement.current.displayToast(
            "No vehicle resources found in any depots, please create some",
            "error",
            5000
          );
        }

        if (projectId)
          handleProjectChange(
            { target: { value: projectId } },
            depot_lookup,
            resource_lookup
          );

        setPageLoading(false);
      }
    }

    fetchData();
  }, [organizationMemo.lookup]);

  /**
   * selects a project from dropdown, fetches the project's resources,
   * and displays an error message if no resources were found
   * @param {Object} event
   * @param {Number} event.target.value project Id
   * @param {Object} depotLookup predefined to be the value of the useState, but modifiable, so it can be used in useEffect
   * @param {Object} resourceMap predefined to be the value of the useState, but modifiable, so it can be used in useEffect
   */
  const handleProjectChange = (
    event,
    depotLookup = depotLookup,
    resourceMap = resourceMap
  ) => {
    if (!depotLookup[event.target.value]?.length) {
      //if no depots in project, display warning message and exit this function;
      setIsNavDisabled(true);
      setInputs({
        ...inputs,
        project_id: event.target.value,
        depot_id: undefined,
      });

      snackBarElement.current.displayToast(
        "Project has no depots assigned to it, please select a different project, or create depots",
        "warning",
        5000
      );
      return;
    }

    //get the first depot that has vehicle resources attached to it
    const autoSelectedDepot = depotLookup[event.target.value].find(
      (currDepot) => resourceMap?.depot[currDepot.id].length
    );

    if (!autoSelectedDepot) {
      //if none of the project's depots have any vehicle resources, display warning message and exit the function
      setIsNavDisabled(true);
      setInputs({
        ...inputs,
        project_id: event.target.value,
        depot_id: undefined,
      });
      snackBarElement.current.displayToast(
        "Project has no depots that contain vehicle resources, please select a different project, or assign vehicles to a depot",
        "warning",
        5000
      );
      return;
    }

    if (inputs?.analysis_type == 2) {
      // if the user is doing a "compare analysis", then clear out any selected simulation ids, as they are project&depot-specific
      setStepsInputs(DefaultAnalysisInputs[2]);
    }
    //auto-select the first depot containing vehicle resources of the project
    setInputs({
      ...inputs,
      project_id: event.target.value,
      depot_id: autoSelectedDepot.id,
    });
    setIsNavDisabled(false);
  };

  /**
   * selects a depot from dropdown,
   * and displays an error message if no resources were found
   * @param {Event} event
   * @param {Number} event.target.value depot Id
   */
  const handleDepotChange = (event) => {
    const depotId = event.target.value;

    //make a list of all depot vehicle resources, to ensure selected depot contains vehicle resources
    const depotResources = resourceMap.depot[depotId];

    if (inputs?.analysis_type == 2) {
      // if the user is doing a "compare analysis", then clear out any selected simulation ids, as they are project&depot-specific
      setStepsInputs(DefaultAnalysisInputs[2]);
    }

    if (depotResources.length) {
      setInputs({ ...inputs, depot_id: depotId });
      setIsNavDisabled(false);
    } else {
      //if selected depot has no vehicle resources
      setInputs({ ...inputs, depot_id: undefined });
      setIsNavDisabled(true);
      snackBarElement.current.displayToast(
        "Depot Missing Vehicle Resources",
        "error"
      );
    }
  };

  /**
   * sends the new analysis depot to backend, stores them on frontend
   * and then navigates to next page
   * @param {React.MouseEvent<HTMLButtonElement>} event
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonLoading(true);
    if (!UseAuth("get")) {
      window.location.assign("/login");
      return;
    }
    //else
    clearLocalDb(); //clear out DB previous entries

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = {
      ...inputs,
      completed: inputs?.analysis_type == 2, //comparison-type simulations are read-only, so make them automatically complete
      current_page:
        inputs?.analysis_type == 1
          ? assessmentStepZero[stepsInputs?.input_method].route
          : comparisonStepInfo[0].route,
      steps: {
        input: { simulation: stepsInputs },
      },
    };

    //gets the current project's data, for storage in cookie and indexdb
    const project = projectList.find(
      (project) => project.id == inputs?.project_id
    );

    fetch(simulationURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then(({ data }) => {
            setAnalysisCookies(
              data.analysis_type_vers,
              inputs.name,
              project?.id,
              depotLookup?.[inputs?.project_id]?.find(
                (depot) => depot.id == inputs?.depot_id
              )?.name,
              project.currency_code,
              project.unit
            );

            storeLocalData("project", { data: project }); //add current project to localDb

            storeLocalData("simulation", {
              data: { ...data, ...body },
              input: stepsInputs,
            }); //stores simulation response in indexDB (currently serves no purpose)

            // analysis steps view is part of a contextProvider, to provide detection of changes in the sideDrawer
            analysisStepsViewMemo.setState({
              analysis_type: inputs?.analysis_type,
              ...stepsInputs,
            });

            //redirect to specified page
            navigate(`..${body.current_page}`);
          });
        } else {
          errorHandler(response, snackBarElement, "Failed to Create Analysis");
          setButtonLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement?.current?.displayToast(
          "Network Error: Failed to Create Analysis",
          "error"
        );
        setButtonLoading(false);
      });
  };

  const handleTourStart = () => {
    setIsTourActive(true);
    introJs()
      .setOptions(fleetInputTourOptions())
      .onexit(() => setIsTourActive(false))
      .start();
  };

  return (
    <div>
      <br />
      {filteredProjectList &&
      filteredProjectList.length &&
      //checks that there are depots in the lookup
      Object.values(depotLookup).flatMap((i) => i).length &&
      resourceMap.depot ? (
        <>
          <br />
          <span id="fleet-input-step1">
            <Container
              fixed
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                align="left"
                className="page-title"
              >
                Step 1 - Select Project & Depot
              </Typography>
              <TourBeacon hidden={isTourActive} onClick={handleTourStart} />
            </Container>
            <hr className="fleetinput-divider" />
            <br />
            <Container
              fixed
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="sideByside">
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  noWrap
                  sx={{ marginBottom: 0 }}
                >
                  Select Project
                </Typography>
              </div>
              <FormControl
                style={{ minWidth: "20%" }}
                size="small"
                className="sideByside"
              >
                <InputLabel id="project-select-label">Project Name</InputLabel>
                <Select
                  labelId="project-select-label"
                  id="project-select"
                  defaultValue={projectId || ""}
                  label="PROJECT NAME"
                  onChange={(event) =>
                    handleProjectChange(event, depotLookup, resourceMap)
                  }
                  MenuProps={{ PaperProps: { sx: { maxHeight: "50%" } } }}
                  autoFocus //cursor moves here on edit
                >
                  {filteredProjectList.map((val) => (
                    <MenuItem key={`proj_${val.id}`} value={val.id}>
                      {val.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                noWrap
                sx={{
                  marginBottom: 0,
                  marginLeft: "5%",
                  marginRight: "1%",
                }}
              >
                Select Depot
              </Typography>
              <FormControl
                style={{ minWidth: "20%" }}
                size="small"
                className="sideByside"
              >
                <InputLabel id="depot-select-label">Depot Name</InputLabel>
                <Select
                  labelId="depot-select-label"
                  id="depot-select"
                  value={inputs?.depot_id || ""}
                  label="DEPOT NAME"
                  onChange={handleDepotChange}
                  MenuProps={{ PaperProps: { sx: { maxHeight: "50%" } } }}
                >
                  {(depotLookup[inputs?.project_id] || []).map((val) => (
                    <MenuItem key={val.name} value={val.id}>
                      {val.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Container>
          </span>
          <br />
          <br />
          <br />
          {/* Make Step 2 grey if step 1 is not yet completed */}
          <span
            id="fleet-input-step2"
            style={{ color: isNavDisabled ? "grey" : "black" }}
          >
            <Container
              fixed
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                align="left"
                className="page-title"
              >
                Step 2 - Analysis Details
              </Typography>
            </Container>

            <hr className="fleetinput-divider" />
            <br />
            <Container
              fixed
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TextField
                required
                sx={{ minWidth: "20%", marginRight: "5%" }}
                label="Analysis Type"
                variant="standard"
                value={inputs?.analysis_type ?? ""}
                onChange={(e) => {
                  setStepsInputs(DefaultAnalysisInputs[e.target.value]);
                  setInputs({ ...inputs, analysis_type: e.target.value });
                }}
                select
                disabled={isNavDisabled}
              >
                {Object.entries(TYPE_STRINGS.SIMULATION_TYPE).map(
                  ([key, value]) => (
                    <MenuItem key={value} value={key}>
                      {value}
                    </MenuItem>
                  )
                )}
              </TextField>
              <TextField
                sx={{ minWidth: "20%", marginRight: "5%" }}
                className="sideByside"
                label="Analysis Name"
                required
                value={inputs?.name ?? ""}
                onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
                disabled={isNavDisabled}
                error={!inputs?.name && !!stepsInputs?.input_method}
              />
              <TextField
                sx={{ minWidth: "25%" }}
                label="Analysis Description"
                value={inputs?.description ?? ""}
                onChange={(e) =>
                  setInputs({ ...inputs, description: e.target.value })
                }
                multiline
                maxRows={4}
                disabled={isNavDisabled}
              />
            </Container>
          </span>
          <br /> <br />
          {/* Step 3: Options */}
          <span
            id="fleet-input-step3"
            style={{
              color:
                isNavDisabled || !inputs?.name || !inputs?.analysis_type
                  ? "grey"
                  : "black",
            }}
          >
            <Container
              fixed
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                align="left"
                className="page-title"
              >
                Step 3 - Options
              </Typography>
            </Container>
            <hr className="fleetinput-divider" />
            <br />
            <br />
            {inputs?.analysis_type == 1 ? (
              <FormControl sx={{ display: "flex", alignItems: "center" }}>
                <FormLabel>Input Method</FormLabel>
                {/* input Method is 0 for "not selected", 1 for "gtfs Import", 2 for "manually", 3 is for "School bus import excel" */}
                <RadioGroup
                  onChange={(e) =>
                    setStepsInputs({
                      input_method: Number(e.target.value) || 0,
                    })
                  }
                  value={stepsInputs?.input_method}
                >
                  <FormControlLabel
                    control={<Radio />}
                    value={1}
                    disabled={
                      isNavDisabled ||
                      !inputs?.name ||
                      projectList.find((x) => x.id == inputs?.project_id)
                        .type != 1 //can only perform GTFS import if selected project is of type Transit
                    }
                    label="Import GTFS Fleet Data"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value={2}
                    disabled={isNavDisabled || !inputs?.name}
                    label="Enter Fleet Data Manually"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value={3}
                    disabled={
                      isNavDisabled ||
                      !inputs?.name ||
                      projectList.find((x) => x.id == inputs?.project_id)
                        .type != 4 //can only perform SchoolBus excel import if project is of type school bus
                    }
                    label="Import School Bus Excel File"
                  />
                </RadioGroup>
              </FormControl>
            ) : inputs?.analysis_type == 2 ? (
              <Container>
                <FormControl sx={{ minWidth: "30%" }}>
                  <FormLabel>Simulations to Compare</FormLabel>
                  <Select
                    required
                    variant="outlined"
                    disabled={isNavDisabled || !inputs?.name}
                    sx={{
                      backgroundColor:
                        isNavDisabled || !inputs?.name
                          ? (theme) =>
                              theme?.palette?.action?.disabledBackground
                          : undefined,
                    }}
                    MenuProps={{
                      //set an upper limit to the ˝hight of the dropdown
                      PaperProps: { sx: { maxHeight: "60%" } },
                      // has the popover appear above the input box, instead of below
                      anchorOrigin: { vertical: "top", horizontal: "center" },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                    }}
                    multiple={true}
                    value={stepsInputs?.simulation_ids}
                    onChange={(e) =>
                      setStepsInputs({ simulation_ids: e.target.value })
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((sim_id) => (
                          <Chip
                            key={`simulation_chip_${sim_id}`}
                            label={
                              filteredSimulationList.find(
                                (sim) => sim_id == sim.id
                              )?.name
                            }
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {filteredSimulationList.map((sim) => (
                      <MenuItem
                        key={`comparison_${sim.id}`}
                        value={sim.id}
                        title={sim.description}
                      >
                        {sim.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Container>
            ) : (
              ""
            )}
          </span>
          <br />
          <br />
          <Stack
            direction="column"
            spacing={2}
            sx={{ width: "50%", margin: "0 auto" }}
          >
            <LoadingButton
              id="new-analysis-button"
              sx={{ float: "right", minWidth: "20%" }}
              variant="contained"
              className="btn"
              disabled={
                !accessRights.analysis.create_analysis ||
                isNavDisabled ||
                !inputs?.name ||
                !inputs?.analysis_type ||
                (inputs?.analysis_type == 1 && !stepsInputs?.input_method) ||
                (inputs?.analysis_type == 2 &&
                  stepsInputs?.simulation_ids?.length !== 2)
              }
              loading={buttonLoading}
              onClick={handleSubmit}
            >
              Create New Analysis
            </LoadingButton>
          </Stack>
        </>
      ) : pageLoading ? (
        // displayed while fetching projects, depots, and resources
        <Fade in={true}>
          <div className="centered">
            <CircularProgress />
          </div>
        </Fade>
      ) : (
        //if failed to fetch projects, depots, or resources
        <Empty
          text={
            !filteredProjectList.length ? (
              <>
                No Projects Created. Create one <Link to="/project">here</Link>{" "}
                and come back
              </>
            ) : !Object.values(depotLookup).flatMap((i) => i).length ? (
              <>
                No Depots Created. Create one <Link to="/depot">here</Link> and
                come back
              </>
            ) : (
              <>
                No Resources Created. Create some{" "}
                <Link to="/resource-inventory">here</Link> and come back
              </>
            )
          }
        />
      )}
    </div>
  );
}

export default FleetInput;
