import { InputAdornment, TextField } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo } from "react";

import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import {
  unitLargeAbbr,
  unitSmallMap,
} from "../../../static/constants/systems_of_measurement";
import { getUnits, unitFeet } from "../../secondary/unitConversions";
import { roundNumber, unitWrapper } from "../../utils";

/**
 * @param {Object} props
 * @param {{vehicleModel: string, veh_type: number, size: string, detailed_energy:{updated_efficiency:number} }[]} props.data
 * @param {import("material-react-table").RowSelectionState} rowSelection
 */
export default function RouteEnergyGroupEdit({ data, rowSelection }) {
  //todo: this materialReactTable is very similar to updateBatterySizing's materialReactTable, consider combining them
  const units = getUnits();

  //used to remove duplicate vehicle models
  const consolidatedVehicleModels = useMemo(() => {
    //combines all duplicate vehicle models into one row, and calculates the model's average Efficiency
    const modelLookup = {};
    data.forEach((row) => {
      let combinedModel = modelLookup[row.vehicleModel];

      //sum up the number of each vehicle model, and their efficiency, to compute the average
      if (combinedModel) {
        combinedModel.avgEfficiency += row.detailed_energy.updated_efficiency;
        combinedModel.modelCount += 1;
        combinedModel.selectedCount += Boolean(rowSelection[row.id]);
      } else {
        //if model has not been seen yet, initialize it as a duplicate of the row
        combinedModel = { ...row };
        combinedModel.avgEfficiency = row.detailed_energy.updated_efficiency;
        combinedModel.modelCount = 1;
        combinedModel.selectedCount = +Boolean(rowSelection[row.id]);
        modelLookup[row.vehicleModel] = combinedModel;
      }
    });

    const output = Object.values(modelLookup);

    //compute the average efficiency of each model
    output.forEach(
      (row) => (row.avgEfficiency = row.avgEfficiency / row.modelCount)
    );

    return output;
  }, [data]);

  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */ () => {
      const vehTypeFilterOptionsSet = new Set(data.map((row) => +row.veh_type)); // create a set of all veh_types, for use in the filter of vehicle type dropdown
      return [
        {
          header: "Selected",
          accessorKey: "selectedCount",
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper("rows")}
            </>
          ),
        },
        {
          header: "Total",
          accessorKey: "modelCount",
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper("rows")}
            </>
          ),
        },
        {
          header: "Vehicle Type",
          accessorKey: "veh_type",
          Cell: ({ cell }) => TYPE_STRINGS.VEHICLE_TYPE[cell.getValue()],
          filterVariant: "autocomplete",
          filterSelectOptions: Object.entries(TYPE_STRINGS.VEHICLE_TYPE)
            .filter(([veh_type]) => vehTypeFilterOptionsSet.has(+veh_type))
            .map(([value, label]) => ({ value, label })),
          filterFn: "equals", // prevents fuzzy matches like "Box Truck" (2) = "Tractor Trailer" (12)
        },
        {
          header: "Size",
          accessorKey: "size",
          Cell: ({ row, cell }) =>
            row.getValue("veh_type") == 1 ? (
              <>
                {unitFeet(cell.getValue())} {unitWrapper(unitSmallMap[units])}
              </>
            ) : row.getValue("veh_type") == 4 ? ( //if selected vehicle type is not transit, hide the ft
              `Type ${cell.getValue()}`
            ) : (
              `Class ${cell.getValue()}`
            ),
          filterFn: (row, columnId, filterValue) =>
            (row.getValue("veh_type") == 1
              ? unitFeet(row.getValue(columnId))
              : row.getValue("veh_type") == 4
              ? `Type ${row.getValue(columnId)}`
              : `Class ${row.getValue(columnId)}`
            )
              .toString()
              .indexOf(filterValue) != -1,
        },
        {
          header: "Average Efficiency",
          accessorKey: "avgEfficiency",
          accessorFn: (row) => roundNumber(row.avgEfficiency),
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper(`kWh/${unitLargeAbbr[units]}`)}
            </>
          ),
        },
        {
          header: "Updated Efficiency",
          accessorKey: "vehicleModel",
          enableColumnActions: false,
          enableSorting: false,
          Cell: ({ cell }) => (
            <TextField
              name={cell.getValue()}
              placeholder="Unchanged"
              type="number"
              title="Edit all efficiency in group"
              sx={{ minWidth: "15em" }}
              InputProps={{
                inputProps: {
                  min: 0.01,
                  step: 0.01,
                  style: { minWidth: "6ch" }, //minWidth to make sure that the numbers aren't covered by the units of measurment/slider for up to 6 characters
                },
                endAdornment: (
                  <InputAdornment position="end">
                    kWh/{unitLargeAbbr[units]}
                  </InputAdornment>
                ),
              }}
            />
          ),
        },
      ];
    },
    []
  );

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    data: consolidatedVehicleModels,
    columns,
    enablePagination: false, //disabling pagination seems to remove the limit on number of rows displayed at once (tested for up to 14 rows)
    enableBottomToolbar: false,
    enableTopToolbar: false,
  });

  return <MaterialReactTable table={table} />;
}
