import { Box, Chip, Stack, TablePagination, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { DataContext } from "../../../contexts/dataContext";
import { MFM_to_AMPM, roundNumber } from "../../utils";
import { LegendChip } from "../commonComponents.js";
import {
  legendClickables,
  status_label_lookup,
} from "./vehicleActivityTimeTable.js";

const stepSize = 180; //the time, in minutes, between each step

//rgb values ranging from blue to orange to red
export const colorGradient = [
  "#f43434",
  "#f58433",
  "#f6d633",
  "#c5f832",
  "#72f931",
  "#31fa43",
  "#30fb98",
  "#2ffdee",
  "#2fb6fe",
  "#2e5fff",
];

/**
 *
 * @param {Object} param0
 * @param {{profile:[]}[]} param0.data
 * @param {2|3|4} param0.view 2= "Driving" 3="Charging" 4=Both
 * @param {} param0.setView
 */
export default function DrivingActivityTimeTable({
  data: allData,
  view,
  setView,
}) {
  const view_lookup = { 1: "driving", 2: "driving", 3: "charging", 4: "SoC" };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(allData?.length || 10);

  const { logo } = useContext(DataContext);
  const chartRef = useRef();
  const data = allData
    .filter((row) => row.profile.length > 1)
    .slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  useEffect(() => {
    //re-renders the chart so that the gradient appears onChange
    function updateChart() {
      chartRef?.current?.update();
    }

    updateChart();
  }, [page, rowsPerPage, view]);

  const dataKeys = {}; //used to filter out the unused legend labels

  //see https://mui.com/material-ui/react-pagination/#TablePagination.js for more info on pagination
  /** changes the page the user is on
   * @param {React.MouseEvent<HTMLButtonElement> | null} e event
   * @param {number} newPage the page to turn to
   */
  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  /** increases the number of rows per page shown
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e event
   * @param {number} newPage the page to turn to
   */
  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0); // reset the page number to 0
  };

  // plugin to create SoC color legend
  /**
   * @type {import("chart.js").Plugin<"bar", AnyObject>}
   */
  const rightSideLegend = {
    id: "rightSideLegend",
    //afterDatasetsDraw is used to keep plugin behind any tooltips
    afterDatasetsDraw(chart, _args, _pluginOptions) {
      const {
        ctx,
        chartArea: { left, right, top, bottom, width, height }, //get chart canvas' dimensional values
      } = chart;

      ctx.save(); //saves canvas styling state
      //create gradient of colors used to represent SoC
      const gradient = ctx.createLinearGradient(
        0,
        top + (chartRef.current?.legend?.height ?? 32),
        0,
        bottom - top + (chartRef.current?.legend?.height ?? 32)
      );
      colorGradient.forEach((color, index) => {
        gradient.addColorStop(
          (colorGradient.length - index) / colorGradient.length,
          color
        );
      });
      //draw a rectangle filled with the gradient colors on the right side of the chart
      ctx.fillStyle = gradient;
      ctx.fillRect(width + left + 10, top, 25, bottom - top);
      //draw another (unfilled) rectangle with the same dimensions, but no colors, to create an outline of the bar
      ctx.strokeRect(width + left + 10, top, 25, bottom - top);
      ctx.fillStyle = "#666"; // set text color scheme to match grey shade of chatjs text
      for (const i in Array(11).fill()) {
        ctx.beginPath();
        ctx.moveTo(width + left + 35, top + (i * (bottom - top)) / 10);
        ctx.lineTo(width + left + 45, top + (i * (bottom - top)) / 10);
        ctx.fillText(
          `${(10 - i) * 10}%`,
          width + left + 46,
          top + (i * (bottom - top)) / 10 + 4
        );
        ctx.stroke();
      }
      ctx.translate(0, 0); //set point to rotate around as top-left
      ctx.rotate((Math.PI / 180) * 270);
      ctx.font = "bolder 12px sans-serif";
      ctx.fillText(
        "State of Charge (SoC)",
        -((bottom - top) / 2) - 105,
        width + left + 85
      );
      ctx.restore(); //resets canvas styling state to what it was at start of functyion
    },
  };

  /** @type {import("chart.js").ChartOptions} */
  const chartOptions = {
    indexAxis: "y",
    interaction: { intersect: false, mode: "nearest" },
    animations: false,
    scales: {
      x: {
        ticks: {
          font: { size: 16 },
          callback: (val) => MFM_to_AMPM(val),
          stepSize: stepSize,
        },
        title: { display: true, text: "Time of Day", font: { size: 16 } }, //label for x-axis
        min: 2880,
        max: 4320,
        stacked: true,
      },
      y: {
        ticks: { maxTicksLimit: 20 },
        title: { display: true, text: "Vehicle ID", font: { size: 16 } },
        stacked: true,
      },
    },
    layout: { padding: { right: 100 } },
    plugins: {
      legend: {
        ...legendClickables(view, setView),
        display: true,
        labels: {
          //filters out labels not included in datasets
          filter: (item) =>
            [
              status_label_lookup.charging.label,
              status_label_lookup.driving.label,
              "All Other Statuses",
            ].includes(item.text),
          boxWidth: 13,
        },
      },
      tooltip: {
        intersect: true, // despite claiming that this is the default, tooltip doesn't work properly unless it's explicitly defined
        position: "mouse",
        callbacks: {
          title: (value) => `Vehicle Model: ${value[0].label}`,
          label: (value) => [
            `${MFM_to_AMPM(value.raw.x[0])} - ${MFM_to_AMPM(value.raw.x[1])}`,
            `Charge: ${roundNumber(value.raw.start_soc, 0)}% - ${roundNumber(
              value.raw.end_soc,
              0
            )}%`,
          ],
        },
        yAlign: "top",
      },
      zoom: {
        pan: { enabled: true, mode: "y", modifierKey: "ctrl" },
        zoom: { drag: { enabled: true }, mode: "y" },
      },
    },
  };

  const data_segments = [];

  //construct dataset
  for (const vehicleIndex in data) {
    const vehicle_data = data[vehicleIndex];
    const vehicle_id = vehicle_data.vehicle_id;
    for (const profileIndex in vehicle_data.profile) {
      const { status, start_time, end_time, start_soc, end_soc } =
        vehicle_data.profile[profileIndex];
      if (!dataKeys[status]) dataKeys[status] = true; //used to filter legend labels
      if (
        ((view == 2 || view == 3) && status == view_lookup[view]) ||
        (view == 4 && (status == view_lookup[2] || status == view_lookup[3]))
      ) {
        data_segments.push({
          x: [start_time, end_time],
          y: vehicle_id,
          status,
          start_soc,
          end_soc,
        });
      }
    }
  }

  /** @type {import("chart.js").ChartData} */
  const chartData = {
    labels: data?.map((block) => block.vehicle_id),
    datasets: [
      ...[
        status_label_lookup.driving,
        status_label_lookup.charging,
        { label: "All Other Statuses", color: "white", borderColor: "black" },
      ].map(({ label, color, borderColor }) => ({
        //generates the legend for the values with the proper colors
        label: label,
        data: [],
        borderWidth: 1,
        borderColor: borderColor ?? color,
        backgroundColor: color,
        hidden:
          ((view == 2 || view == 3) &&
            label != status_label_lookup[view_lookup[view]].label) ||
          (view == 4 &&
            label != status_label_lookup.driving.label &&
            label != status_label_lookup.charging.label),
      })),
      {
        label: "Time of Day",
        data: data_segments,
        borderRadius: 10, //curves the bars
        borderSkipped: false, //curve affects both sides
        backgroundColor: (context) => {
          const { ctx, chartArea } = context?.chart;

          const gradient = ctx?.createLinearGradient(
            context?.element?.base - // base is the offset from left side of canvas to leftmost point in a bar
              //shift gradient start point to left by 100 - percentage of start_soc, relative to canvas' units
              ((100 - context?.raw?.start_soc) * context?.element?.width) /
                (context?.raw?.start_soc - context?.raw?.end_soc) || 0,
            0,
            roundNumber(
              context?.element?.base -
                ((100 - context?.raw?.start_soc) * context?.element?.width) /
                  (context?.raw?.start_soc - context?.raw?.end_soc) +
                //add in the amount of canvas units that it will take for state of charge to go from 100% to 0%
                (100 * context?.element?.width) /
                  (context?.raw?.start_soc - context?.raw?.end_soc)
            ) || 2000,
            0
          );

          colorGradient.forEach((color, index) => {
            gradient.addColorStop(
              (colorGradient.length - index) / colorGradient.length,
              color
            );
          });
          return gradient || "blue";
        },
      },
    ],
  };

  return (
    <div className="chartdiv">
      <Stack
        sx={{ justifyContent: "space-between", margin: "1%" }}
        direction="row"
        spacing={1}
      >
        <Typography variant="h6">
          {view != 4
            ? status_label_lookup[view_lookup[view]].label
            : "State of Charge"}{" "}
          Status
        </Typography>
        <Stack direction="row" spacing={1}>
          <LegendChip data={allData} />
          <Chip
            label="Reset Zoom"
            onClick={() => {
              chartRef?.current?.resetZoom();
              chartRef?.current?.update();
            }}
          />
        </Stack>
      </Stack>
      <Bar
        ref={chartRef}
        data={chartData}
        options={chartOptions}
        plugins={[rightSideLegend]}
      />
      <div
        style={{ bottom: "100px", right: "100px" }}
        className="watermark-container"
      >
        <img src={logo} width="auto" height="50px" className="watermark-img" />
      </div>
      <Box display="flex" justifyContent="right">
        <TablePagination
          component="div" //avoids a warning message regarding <td>
          count={allData.filter((row) => row.profile.length > 1).length}
          page={page}
          onPageChange={handlePageChange}
          showFirstButton
          showLastButton
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[
            10,
            20,
            40,
            80,
            { label: "All", value: allData.length },
          ]}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </div>
  );
}
