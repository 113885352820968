const atlanticOcean = [
  [-24.6, 68.5],
  [25.3, 69.8],
  [5.7, 61.4],
  [4.6, 52.2],
  [-6.3, 48.4],
  [-9.45, 43.5],
  [-9.63, 37.6],
  [-6.3, 35.5],
  [-10.5, 31.1],
  [-10.5, 28.4],
  [-16.1, 24.5],
  [-17.2, 14.7],
  [-8.2, 4.1],
  [6.3, 3.6],
  [9.9, 3.4],
  [9, -1.7],
  [13.8, -12.6],
  [11.7, -16.5],
  [14.5, -22.3],
  [16.1, -28.67],
  [18.9, -34.5],
  [18.9, -55.7],
  [-66, -55.7],
  [-68.5, -50.4],
  [-58.6, -39.3],
  [-48.1, -28.2],
  [-48.1, -25.7],
  [-41.6, -22.7],
  [-38.7, -17.4],
  [-39.5, -13.7],
  [-36.9, -12.5],
  [-34.9, -10.4],
  [-35.0, -5.5],
  [-50, -0.1],
  [-53, 5.5],
  [-57.2, 6.1],
  [-62.8, 10.9],
  [-67.8, 10.9],
  [-74.2, 10.8],
  [-76.9, 8.5],
  [-81.6, 9.4],
  [-82.7, 14],
  [-87.4, 16.1],
  [-86.3, 21.6],
  [-90.2, 21.7],
  [-91.2, 19.2],
  [-95.7, 18.8],
  [-97.1, 25.5],
  [-91.0, 28.9],
  [-84, 29.7],
  [-82.9, 27.3],
  [-80.9, 24.9],
  [-79.3, 26.7],
  [-81.1, 31.3],
  [-75.4, 35.2],
  [-73.8, 40.3],
  [-69.6, 41.4],
  [-65.1, 43.5],
  [-60, 45.8],
  [-52.2, 47.1],
  [-54.9, 52.9],
  [-44.5, 60.1],
  [-38.8, 65.1],
];

const indianOcean = [
  [21.4, -34.15],
  [27.37, -33.71],
  [40.03, -15.61],
  [39.68, -3.5],
  [51.8, 10.16],
  [58.84, 22.26],
  [65.69, 25.18],
  [71.32, 19.83],
  [77.47, 6.86],
  [80.24, 12.53],
  [80.9, 15.85],
  [89.05, 22.12],
  [91.38, 22.08],
  [94.54, 17.74],
  [94.02, 16.02],
  [97.0, 16.82],
  [98.19, 8.33],
  [100.78, 3.18],
  [94.98, 6.29],
  [105.0, -6.52],
  [118.16, -9.26],
  [123.52, -11.25],
  [129.93, -11.08],
  [128.62, -14.51],
  [125.89, -3.57],
  [118.51, -20.37],
  [113.06, -22.18],
  [115.26, -34.44],
  [123.52, -34.88],
  [130.99, -32.09],
  [137.23, -36.59],
  [137.5, -66.47],
  [102.26, -65.79],
  [85.65, -66.22],
  [75.01, -69.5],
  [69.04, -67.67],
  [54.18, -65.76],
  [37.48, -68.65],
];

const pacificEast = [
  [149.9, -37.8],
  [153.9, -28.5],
  [143.2, -11.5],
  [152.1, -0.9],
  [127.9, 5.7],
  [122.9, 23.8],
  [123.4, 31],
  [128.9, 33.7],
  [129.8, 29.4],
  [141.6, 35],
  [142.8, 41],
  [148, 43.3],
  [144.6, 45.5],
  [146.2, 49.3],
  [144.9, 54.2],
  [136.8, 55.2],
  [143.1, 59.1],
  [153.7, 59.2],
  [159.4, 61.6],
  [160.3, 60.5],
  [161.4, 60.3],
  [155.4, 57],
  [156.6, 50.3],
  [160.8, 52.8],
  [164.1, 55.8],
  [163.8, 58.1],
  [167.3, 60.1],
  [170.7, 59.8],
  [179.9, -77.1],
  [166.4, -77.1],
  [173.8, -71.8],
  [142.9, -66.8],
  [146.9, -44.8],
];

const pacificWest = [
  [-179.9, 62.2],
  [-179.7, 64.7],
  [-177.3, 65.3],
  [-173.6, 63.4],
  [-166, 62.2],
  [-165.8, 60.9],
  [-168.4, 60.4],
  [-166.6, 58.9],
  [-158.5, 57.8],
  [-153.1, 57],
  [-144.8, 59.9],
  [-136.1, 56.9],
  [-131.7, 51.9],
  [-125.2, 48.4],
  [-124.5, 44.6],
  [-124.4, 40.7],
  [-124.47, 40.28],
  [-123.77, 38.9],
  [-120.95, 34.34],
  [-117.6, 32.7],
  [-110.7, 23.2],
  [-105.8, 19.7],
  [-96.1, 15.3],
  [-87.9, 12.4],
  [-83.7, 7.3],
  [-78.7, 6.1],
  [-80.2, 0.9],
  [-82.2, -0.6],
  [-81.2, -6.3],
  [-76.7, -14.4],
  [-70.4, -18.9],
  [-73.7, -36.7],
  [-76, -46.2],
  [-75.1, -53],
  [-73.4, -55.1],
  [-66.6, -56.3],
  [-64.6, -55],
  [-59.6, -63.4],
  [-68.4, -65.7],
  [-75.8, -72.2],
  [-98.6, -71.8],
  [-126.8, -73.2],
  [-146.8, -75.7],
  [-162.6, -78.4],
  [-179.9, -77.1],
];

// code based on: https://stackoverflow.com/a/29915728
function isInOcean([x, y], ocean) {
  var inside = false;

  for (let i = 0, j = ocean.length - 1; i < ocean.length; j = i++) {
    const x_i = ocean[i][0],
      y_i = ocean[i][1],
      x_j = ocean[j][0],
      y_j = ocean[j][1];

    if (y_i > y != y_j > y && x < ((x_j - x_i) * (y - y_i)) / (y_j - y_i) + x_i)
      inside = !inside;
  }

  return inside;
}

/**
 * given a latitude and longitude, determines if the point is on land or in ocean
 * @param {[Number, Number]} point [latitude, longitude]
 * @returns {Boolean} true if on land, false if in ocean
 */
export default function isOnLand([latitude, longitude]) {
  const ocean_list = [atlanticOcean, indianOcean, pacificEast, pacificWest];
  //if point is not in any ocean, it must be on land
  return !ocean_list.some((ocean) => isInOcean([longitude, latitude], ocean));
}
