const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    //see: https://web.dev/articles/vitals for more info
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry); // Cumulative Layout Shift: Measures visual stability
      getFID(onPerfEntry); // First Input delay: measures interactivity
      getFCP(onPerfEntry); //First Contentful Paint
      getLCP(onPerfEntry); // Largest Contentful Paint:  measures loading performace
      getTTFB(onPerfEntry); //Total Blocking Time
    });
  }
};

export default reportWebVitals;
