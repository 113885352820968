import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import { IconButton, InputAdornment } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import { loginURL } from "../../static/constants/backendRoutes";
import { errorHandler, getSubdomain } from "../utils";
import UseAuth from "./useAuth";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { logo } = useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);

  useEffect(() => {
    if (UseAuth("get")) UseAuth("remove");
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const user = {
      email: data.get("email"),
      password: data.get("password"),
      subdomain: getSubdomain(),
    };

    fetch(loginURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then(({ data }) => {
            if (data.token) {
              snackBarElement.current.displayToast(
                "Login Successful!",
                "success",
                2000
              );
              UseAuth("set", { key: data.token });
              setTimeout(() => window.location.assign("/project"), 250);
            } else {
              setLoading(false);
              window.location.reload();
            }
          });
        } else {
          errorHandler(response, snackBarElement);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        snackBarElement.current.displayToast(String(err), "error");
        setLoading(false);
      });
  };

  return (
    <Grid container component="main" className="login-center">
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component="div"
        square="true"
        className="login-center"
      >
        <Box className="login-center" sx={{ my: 15 }}>
          <Paper
            variant="outlined"
            className="login-center"
            sx={{ p: { xs: 2, md: 3 } }}
          >
            <Avatar sx={{ m: 1, bgcolor: "parimary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                type="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                className="textFieldBorder"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                className="textFieldBorder"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 2, mb: 2, borderRadius: "16px" }}
                loadingIndicator="Signing In..."
                loading={loading}
              >
                Sign In
              </LoadingButton>
              <Grid container>
                <Grid item xs sx={{ display: "flex" }}>
                  <Link
                    component={RouterLink}
                    to="/forgotpassword"
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className="login-center"
        sx={{ ml: "-1%" }}
      >
        <img
          style={{ maxWidth: "75%", maxHeight: "75%", padding: "0% 5% 0% 5%" }}
          src={logo}
          alt="logo"
        />
      </Grid>
    </Grid>
  );
}
