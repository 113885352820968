import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";
import { SnackBarContext } from "../../contexts/snackBarContext";
import { passwordResetConfirmURL } from "../../static/constants/backendRoutes";
import { errorHandler, getSubdomain, useQuery } from "../utils";

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);

  const uid = useQuery().get("uid");
  const token = useQuery().get("token");

  const { snackBarElement } = useContext(SnackBarContext);

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);

    const body = {
      uid: uid,
      token: token,
      new_password1: data.get("password1"),
      new_password2: data.get("password2"),
      subdomain: getSubdomain(),
    };

    if (body.password1 !== body.password2) {
      snackBarElement.current.displayToast("Passwords do not match", "warning");
      setLoading(false);
      return;
    }

    fetch(passwordResetConfirmURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast("Password successfully changed");
          setTimeout(() => {
            window.location.assign("/login");
          }, 3000);
        } else {
          errorHandler(response, snackBarElement, "Failed to reset password");
          setLoading(false);
        }
      })
      .catch((err) => {
        snackBarElement.current.displayToast(String(err), "error");
        console.log(err);
        setLoading(false);
      });
  }

  return (
    <>
      <Paper
        variant="outlined"
        sx={{ mb: { xs: 1, md: 3 }, mt: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Reset your password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, px: 64 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  name="password1"
                  label="New Password"
                  type="password"
                  id="password1"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  name="password2"
                  label="Confirm new password"
                  type="password"
                  id="password2"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={12}>
                <LoadingButton
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  loading={loading}
                  loadingIndicator="Changing Password..."
                >
                  Change Password
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </>
  );
}
